import { useQuery } from '@apollo/client';
import { QueryHookOptions } from '@apollo/client/react/types/types';
import { TicketObject, TicketSupportStatuses } from 'app-types/desk';

import { GET_SUPPORT_TICKETS_LIST_QUERY } from './queries';

type ResultData = { tickets: TicketObject[] };
type Vars = { status?: TicketSupportStatuses };

export function useSupportTicketsList(
  status?: TicketSupportStatuses,
  options?: QueryHookOptions<ResultData, Vars>,
) {
  return useQuery<ResultData, Vars>(GET_SUPPORT_TICKETS_LIST_QUERY, {
    variables: { status },
    ...options,
    context: { ...options?.context, clientName: 'desk' },
  });
}
