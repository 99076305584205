import request from '../utils/request';

export interface CourierMethodInterface {
  alias: string;
  name: string;
  type: string;
  _id: string;
}

export interface CourierInterface {
  methods: CourierMethodInterface[];
}

export type CouriersWithMethodsResponse = { couriers: CourierInterface[] };

export function getCouriersWithMethods() {
  return request<CouriersWithMethodsResponse>(
    `${process.env.OUTVIO_API_URL}/shipping-queue/courier`,
    {
      method: 'GET',
      secure: true,
    },
  );
}
