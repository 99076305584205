export function PadlockClosed({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g transform="translate(-24 -24)">
        <g transform="translate(24.5 24)">
          <path
            d="M6.187,20A1.634,1.634,0,0,1,5,19.51a1.6,1.6,0,0,1-.5-1.178V9.669A1.6,1.6,0,0,1,5,8.49,1.634,1.634,0,0,1,6.187,8H7.3V6.154A3.983,3.983,0,0,1,8.526,3.213,4.073,4.073,0,0,1,11.5,2a4.073,4.073,0,0,1,2.974,1.212A3.983,3.983,0,0,1,15.7,6.154V8h1.113A1.634,1.634,0,0,1,18,8.49a1.6,1.6,0,0,1,.5,1.178v8.663A1.6,1.6,0,0,1,18,19.51a1.634,1.634,0,0,1-1.192.49ZM11.5,15.615a1.584,1.584,0,0,0,1.157-.471,1.612,1.612,0,0,0,0-2.288,1.655,1.655,0,0,0-2.314,0,1.612,1.612,0,0,0,0,2.288A1.584,1.584,0,0,0,11.5,15.615ZM8.7,8h5.6V6.154a2.656,2.656,0,0,0-.817-1.962,2.839,2.839,0,0,0-3.967,0A2.656,2.656,0,0,0,8.7,6.154Z"
            transform="translate(0)"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}
