import { useQuery } from '@apollo/client';
import { Query } from 'app-types/returns';

import { BRAND_SETTINGS_QUERY } from '../gql/queries';

export function useGetBrandSettingsByBrandId(id: string) {
  const { data, loading, error } = useQuery<Query>(BRAND_SETTINGS_QUERY, {
    variables: { id },
    skip: !id,
  });
  return {
    data,
    loading,
    error,
  };
}
