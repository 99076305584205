import { useMemo } from 'react';

import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { IUser } from 'app-types';

import { GET_ALL_AGENT_QUERY } from '../../constants';
import { getSupportAgents } from '../../utils';

export function useGetActiveSupportAgentsList(
  options?: UseQueryOptions<Array<IUser> | undefined, unknown>,
) {
  const { data, isLoading, refetch } = useQuery<Array<IUser> | undefined, unknown>(
    [GET_ALL_AGENT_QUERY],
    () => getSupportAgents(),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      ...options,
    },
  );

  return useMemo(
    () => ({
      data: data?.filter(({ isActive }) => isActive),
      isLoading,
      refetch,
    }),
    [data, isLoading, refetch],
  );
}
