import React, { VFC, useContext, useMemo } from 'react';

import { Trans } from 'react-i18next';

import { useGetPaletteColors } from 'return-portal-ui';
import { twMerge } from 'tailwind-merge';

import TrackingPageContext from '../../../../Context/TrackingPageContext';
import { DisplayStep, OutvioStatusCodes, StepStatus } from '../../../../Interfaces';
import SubStepAlert from './Icons/SubStepAlert';

const HomeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g transform="translate(0 0.008)">
        <rect width="20" height="20" transform="translate(0 -0.008)" fill="none" />
        <path
          d="M122.31-787.5a.723.723,0,0,1-.526-.226.719.719,0,0,1-.227-.525.718.718,0,0,1,.227-.525.723.723,0,0,1,.526-.226h13.223a.723.723,0,0,1,.526.226.719.719,0,0,1,.227.525.718.718,0,0,1-.227.525.724.724,0,0,1-.526.226Zm.181,13.021a.864.864,0,0,1-.629-.267.85.85,0,0,1-.268-.62v-4.7h-.221a.9.9,0,0,1-.715-.327.818.818,0,0,1-.184-.747l.919-4.357a.882.882,0,0,1,.326-.515.916.916,0,0,1,.582-.2h13.242a.916.916,0,0,1,.582.2.882.882,0,0,1,.326.515l.919,4.357a.818.818,0,0,1-.184.747.9.9,0,0,1-.715.327h-.221v4.841a.71.71,0,0,1-.227.518.725.725,0,0,1-.527.226.724.724,0,0,1-.527-.226.711.711,0,0,1-.227-.518v-4.841h-4.123v4.7a.85.85,0,0,1-.268.62.864.864,0,0,1-.629.267Zm.61-1.5h6.008v-4.082H123.1Z"
          transform="translate(-119.24 791.411)"
          fill="#7a909e"
          stroke="rgba(0,0,0,0)"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};

const getStyle = (
  isLastDoneStep: boolean,
  status: StepStatus,
  isSubStep: boolean,
  isDelshop: boolean,
): string => {
  let text = 'text-sm py-[14px] flex items-start space-x-2';
  if (isLastDoneStep) {
    text += ' font-semibold';
  } else {
    text += status === StepStatus.PENDING ? ' opacity-20' : ' opacity-60';
  }

  if (isSubStep) {
    text += ' py-0';
    if (
      [StepStatus.BACKTRACK_INCIDENT, StepStatus.PAST_INCIDENT, StepStatus.INCIDENT].includes(
        status,
      )
    )
      text += ' italic';
    if ([StepStatus.BACKTRACK_INCIDENT, StepStatus.PAST_INCIDENT].includes(status))
      text += ' !opacity-30';
  }
  if (isDelshop) text += ' font-bold';
  return text;
};

const getTransitPlace = (transitPlace: string | undefined): string | undefined => {
  if (!transitPlace || transitPlace === 'XX') return undefined;
  const word = transitPlace.split('-')[0];
  const capital = word.charAt(0).toUpperCase();
  const lowercase = word.slice(1).toLowerCase();
  return capital + lowercase;
};

const StepText: VFC<{ step: DisplayStep }> = ({ step }) => {
  const { displayCode, description, location, dateTime, isLastDoneStep, status, isSubStep } = step;
  const {
    portalSettings: {
      regionalSettings: { orderStatusSteps },
    },
  } = useContext(TrackingPageContext);
  const { primaryText, alertColor } = useGetPaletteColors();

  const statusText = (): string => {
    const translatedStatus = orderStatusSteps[displayCode];
    const transitCity = getTransitPlace(location);

    if (isSubStep && transitCity && description) {
      return `${transitCity} - ${description}`;
    }
    if (isSubStep && transitCity && !description) {
      return `${transitCity} - ${translatedStatus}`;
    }
    if (isSubStep && !transitCity && description) {
      return description;
    }
    if (!isSubStep && transitCity) {
      return `${translatedStatus} (${transitCity})`;
    }
    return translatedStatus;
  };

  const { isDelshop, delShopDescription } = useMemo(() => {
    const isDelshop = displayCode === OutvioStatusCodes.DELIVERED_TO_SHOP;
    return {
      isDelshop,
      delShopDescription: description?.replace?.(/\s\s+/g, '<br/>') || description || '',
    };
  }, [displayCode, description]);

  const style = getStyle(isLastDoneStep, status, isSubStep, isDelshop);

  const textColor = status === StepStatus.INCIDENT ? alertColor : primaryText;

  return (
    <>
      <div
        style={{ color: textColor }}
        className={`${style} md:pr-8 pr-6 ${isSubStep ? 'md:pl-5' : 'md:pl-2'}`}
      >
        <SubStepAlert step={step} />
        <span className={isSubStep ? 'mt-[1px] pb-1' : ''}>
          <span>
            {`${statusText()}`}
            {isDelshop && (
              <>
                <br />
                <div className="p-4 w-full text-[14px] font-normal flex gap-x-2">
                  <div style={{ width: 70 }}>
                    <HomeIcon />
                  </div>
                  {delShopDescription ? <Trans>{delShopDescription}</Trans> : null}
                </div>
              </>
            )}
          </span>
          &nbsp;&nbsp;&nbsp;
          {isSubStep && dateTime}
        </span>
      </div>
      <span
        className={twMerge(style, !isLastDoneStep && status !== StepStatus.PENDING && 'opacity-40')}
        style={{ whiteSpace: 'nowrap', color: textColor }}
      >
        {!isSubStep && dateTime}
      </span>
    </>
  );
};

export default StepText;
