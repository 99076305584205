import { useQuery } from 'react-query';

import { StoreBranding } from 'return-portal-ui/old-ui/types/StoreBranding';
import { StoreSettings } from 'return-portal-ui/old-ui/types/StoreSettings';
import { request } from 'ui';

export function useStoreBrandings(optionalIncremental?: number, noCache?: boolean) {
  return useQuery<{ result: StoreSettings }, Error | undefined, StoreBranding[]>(
    !optionalIncremental ? 'getStoreBrandings' : ['getStoreBrandings', optionalIncremental],
    () => getStoreBrandings(),
    {
      select: (data: { result: StoreSettings }) => {
        return data.result.storeBrandings;
      },
      staleTime: noCache ? 0 : 5 * 60 * 1000,
      cacheTime: noCache ? 0 : 5 * 60 * 1000,
    },
  );
}

const getStoreBrandings = (): Promise<{ result: StoreSettings }> =>
  request(`${process.env.OUTVIO_API_URL}/store-branding`, {
    method: 'GET',
    secure: true,
  }).then((res) => {
    if (res?.result?.storeBrandings) {
      //HOTFIX: DS-5390
      for (const brand of res.result.storeBrandings) {
        if (brand.portalV2 && !brand.portalV2.colors) {
          brand.portalV2.colors = {
            mapPin: '#6493ea',
            mapText: '#101010',
            mapDivisor: '#889eaf',
            mapPath: '#414952',
            mapWater: '#cbdce3',
            mapBase: '#eff7f8',
            secondaryBg: '#FFFFFF',
            primaryBg: '#fafafa',
            btnText: '#FFFFFF',
            primaryText: '#13333e',
            alertAlt: '#fdf3e5',
            alertColor: '#f3b161',
            inputBg: '#eff3f5',
            border: '#dfe8eb',
            secondary: '#13333e',
            primaryAlt: '#e9f0fa',
            primary: '#5794f1',
            header: '#FFFFFF',
          };
        }
      }
    }
    return res;
  });
