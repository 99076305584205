import React, { FC } from 'react';

import OrderOverviewWithoutMap from './OrderOverviewWithoutMap';
import StatusHighlight from './StatusHighlight';

interface OrderOverview {
  isModalButton: boolean;
  toggleRatingModal(value: boolean): void;
}

const OrderOverview: FC<OrderOverview> = (props) => {
  const { isModalButton, toggleRatingModal } = props;

  return (
    <div className="flex flex-col">
      <StatusHighlight />
      <div className="w-full flex flex-col md:flex-row md:gap-x-2">
        <OrderOverviewWithoutMap
          toggleRatingModal={toggleRatingModal}
          isModalButton={!isModalButton}
        />
      </div>
    </div>
  );
};

export default OrderOverview;
