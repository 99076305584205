enum ThreePLKeys {
  user3PLId = 'user-3PL-id',
}

const setItem = (key: ThreePLKeys, role: string) => {
  try {
    window.localStorage.setItem(key, role);
  } catch (e) {
    console.error(e);
    return '';
  }
};

const removeItem = (key: ThreePLKeys) => {
  try {
    window.localStorage.removeItem(key);
  } catch (e) {
    console.error(e);
    return '';
  }
};

const getItem = (key: ThreePLKeys) => {
  try {
    return window.localStorage.getItem(key);
  } catch (e) {
    console.error(e);
    return '';
  }
};

export const setUser3plBrandId = (threePL_ID: string) => setItem(ThreePLKeys.user3PLId, threePL_ID);
export const getThreePLUserRole = () => getItem(ThreePLKeys.user3PLId);
export const removeThreePLUserRole = () => removeItem(ThreePLKeys.user3PLId);
