import { useMutation } from '@apollo/client';
import { type Mutation, SetStatusInput as SetStatusInputRaw } from 'app-types/desk';

import { TICKET_SET_STATUS_MUTATION } from '../../../gql/mutations';

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

type SetStatusInput = Optional<
  SetStatusInputRaw,
  'isSupport' | 'snoozeTimeUnit' | 'snoozeTimeValue'
>;

export function useSetTicketStatus() {
  const [mutate, { loading, error }] = useMutation<Mutation, { input: SetStatusInput }>(
    TICKET_SET_STATUS_MUTATION,
    {
      context: { clientName: 'desk' },
    },
  );

  return {
    setTicketStatus: async (statusInput: SetStatusInput) => {
      const { data } = await mutate({
        variables: {
          input: statusInput,
        },
      });
      return data?.Ticket_SetStatus;
    },
    loading,
    error,
  };
}
