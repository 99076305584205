import React from 'react';

interface IIconSquarespace {
  width?: number;
  height?: number;
}

const IconSquarespace = ({ width = 430, height = 430 }: IIconSquarespace) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 430.112 430.112"
  >
    <path d="M71.232 197.391l129.156-129.93c32.425-32.604 84.976-32.604 117.384 0 7.762 8.172 7.397 21.396-.588 29.428-8.111 8.163-21.226 8.163-29.355 0l-1.96-2.272c-16.298-13.899-40.753-13.11-56.136 2.373L97.675 229.817c-8.697 8.061-21.83 8.061-29.932-.089-8.109-8.144-8.109-21.37 0-29.519a21.351 21.351 0 0 1 3.489-2.818zm289.968 2.823c-8.111-8.149-21.23-8.149-29.342 0a21.887 21.887 0 0 0-2.828 3.523l-128.637 129.4c-16.211 16.297-42.491 16.297-58.698 0l-.588-.094c-8.095-8.148-21.228-8.148-29.342 0-8.097 8.135-8.097 21.375 0 29.505a22.194 22.194 0 0 0 3.307 2.74c32.593 29.935 83.142 29.066 114.661-2.637L361.2 229.733c8.097-8.143 8.097-21.37 0-29.519zm-201.935 85.729a21.813 21.813 0 0 0-3.479 2.829c-8.111 8.139-8.111 21.37 0 29.505 8.1 8.148 21.228 8.148 29.93.103l132.057-132.851c16.209-16.297 42.489-16.297 58.685 0 16.228 16.305 16.228 42.748 0 59.046L251.239 371.132c16.223 16.293 42.489 16.293 58.693 0l95.88-97.038c32.399-32.6 32.399-85.463 0-118.081-32.403-32.583-84.959-32.583-117.386 0l-129.161 129.93zm-17.575-11.849l131.47-132.916c8.106-8.158 8.106-21.382 0-29.515-8.097-8.158-21.244-8.158-29.342 0a19.906 19.906 0 0 0-2.819 3.512L112.351 244.58c-16.209 16.307-42.48 16.307-58.682 0-16.225-16.298-16.225-42.742 0-59.046L178.887 58.993c-16.202-16.312-42.491-16.312-58.698 0l-95.878 97.02c-32.415 32.618-32.415 85.481 0 118.081 32.418 32.614 84.988 32.614 117.379 0z" />
  </svg>
);

export default IconSquarespace;
