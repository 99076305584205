import { Dispatch, SetStateAction, useState } from 'react';

function useSingleLoading<T>(
  initialLoading = true,
): [
  isLoading: boolean,
  error: null | string,
  data: T | null,
  setLoading: Dispatch<SetStateAction<boolean>>,
  setError: Dispatch<SetStateAction<null | string>>,
  setData: Dispatch<SetStateAction<T | null>>,
] {
  const [isLoading, setLoading] = useState<boolean>(initialLoading);
  const [error, setError] = useState<null | string>(null);
  const [data, setData] = useState<T | null>(null);

  return [isLoading, error, data, setLoading, setError, setData];
}

export default useSingleLoading;
