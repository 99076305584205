import React, { FC, useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { Field } from 'formik';

import EditModalContext from '../../../Context/EditModalContext';
import { EDIT_MODE_TABS } from '../../../Interfaces/IEditModal';
import { ILanguageInputInfo, LanguageInputTypes } from '../../../Interfaces/ILanguageInputInfo';
import Toggle from '../../Common/Inputs/Toggle';
import LanguageInputCard from '../LanguageInputCard';
import Base from './Base';

const Rating: FC = () => {
  const {
    formData: { useRateOrder },
  } = useContext(EditModalContext);
  const { t } = useTranslation('trackingPage', {
    keyPrefix: 'editMode.modules.rating',
  });
  const { t: t2 } = useTranslation('trackingPage', {
    keyPrefix: 'editMode.inputs',
  });
  const ratingInputInfo: ILanguageInputInfo[] = [
    {
      title: t2('rating.body'),
      objectKey: 'rating.body',
      type: LanguageInputTypes.TEXT,
    },
    {
      title: t2('rating.askLater'),
      objectKey: 'rating.askLater',
      type: LanguageInputTypes.TEXT,
    },
    {
      title: t2('rating.rateButton'),
      objectKey: 'rating.rateButton',
      type: LanguageInputTypes.TEXT,
    },
    {
      title: t2('rating.thanks'),
      objectKey: 'rating.thanks',
      type: LanguageInputTypes.TEXT,
    },
  ];
  return (
    <Base id={EDIT_MODE_TABS.RATING} title={t('title')}>
      <div className="flex mb-8 space-x-6">
        <Field component={Toggle} name="useRateOrder" type="checkbox" />
        <div className="flex flex-col">
          <span className="text-cyan-800 text-sm font-semibold">{t('allowRate')}</span>
          <span className="text-sm font-light mb-6 text-slate-900 text-opacity-80 mt-2">
            {t('allowRateDescription')}
          </span>
        </div>
      </div>
      <div className={`flex flex-col ml-[60px] ${useRateOrder ? 'block' : 'hidden'}`}>
        <LanguageInputCard inputs={ratingInputInfo} />
      </div>
    </Base>
  );
};

export default Rating;
