import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { ILanguage } from '../../Interfaces';

interface LanguageSelector {
  selectedLanguage: ILanguage;
  setSelectedLanguage(lang: ILanguage): void;
  languages: ILanguage[];
}

const LanguageSelector: FC<LanguageSelector> = (props) => {
  const { selectedLanguage, setSelectedLanguage, languages } = props;
  const { t } = useTranslation('trackingPage');
  return (
    <div className="flex flex-col">
      <div className="flex overflow-x-scroll overflow-y-hidden flex-col md:flex-row pb-4">
        {languages.map((language, index) => (
          <button
            type="button"
            onClick={() => setSelectedLanguage(language)}
            className={`${
              selectedLanguage === language ? 'text-slate-700' : 'text-slate-300'
            } font-bold text-sm px-5 py-4 border-b border-b-1 border-slate-100 relative transition-colors`}
            key={`${language.name}${language.ISOCode}${index}`}
          >
            <span>{t(`general.lang.${language.ISOCode.substring(0, 2).toLowerCase()}`)}</span>
            <div
              className={`md:w-[25px] right-8 md:right-0 h-1 absolute bg-primary bottom-[-2px] left-8 rounded transition-opacity ${
                selectedLanguage === language ? 'opacity-100' : 'opacity-0'
              }`}
            />
          </button>
        ))}
      </div>
    </div>
  );
};

export default LanguageSelector;
