const MAP_TO_STATUS = {
  closed: 'CLOSED',
  created: 'CREATED',
  'shipment created': 'SHIPMENT_CREATED',
  exception: 'EXCEPTION',
  pickup: 'PICKUP',
  picked_up: 'PICKUP',
  'in transit': 'IN_TRANSIT',
  'partially delivered': 'PARTIALLY_DELIVERED.short',
  'out for delivery': 'OUT_FOR_DELIVERY',
  'delivered to pickup point': 'DELIVERED_TO_PICKUP_POINT',
  delivered: 'DELIVERED',
  deleted: 'DELETED',
  'in picking queue': 'PICKING_QUEUE',
  'in shipping queue': 'SHIPPING_QUEUE',
  refunded: 'REFUNDED',
  returned: 'RETURNED',
  completed: 'COMPLETED',
};

export { MAP_TO_STATUS };
