export function MergeOrders({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 22"
      width="1em"
      height="1em"
      fill="currentColor"
      {...props}
    >
      <path id="Path_22215" data-name="Path 22215" d="M0,0H22V22H0Z" fill="none" />
      <path
        id="cell_merge_FILL0_wght300_GRAD0_opsz24"
        d="M91.629-824.879a.675.675,0,0,1-.5-.2.676.676,0,0,1-.2-.5v-2.739a1.621,1.621,0,0,1,.494-1.189A1.621,1.621,0,0,1,92.614-830h2.739a.676.676,0,0,1,.5.2.676.676,0,0,1,.2.5.675.675,0,0,1-.2.5.676.676,0,0,1-.5.2H92.614a.279.279,0,0,0-.206.081.279.279,0,0,0-.081.206v2.739a.676.676,0,0,1-.2.5A.676.676,0,0,1,91.629-824.879Zm14.9,0a.675.675,0,0,1-.5-.2.676.676,0,0,1-.2-.5v-2.739a.279.279,0,0,0-.081-.206.279.279,0,0,0-.206-.081H102.8a.676.676,0,0,1-.5-.2.676.676,0,0,1-.2-.5.675.675,0,0,1,.2-.5.676.676,0,0,1,.5-.2h2.739a1.621,1.621,0,0,1,1.189.494,1.621,1.621,0,0,1,.494,1.189v2.739a.676.676,0,0,1-.2.5A.676.676,0,0,1,106.525-824.879Zm-3.446,5.774-2.15-2.159a.807.807,0,0,1-.252-.589.807.807,0,0,1,.252-.589l2.15-2.159a.674.674,0,0,1,.493-.208.7.7,0,0,1,.5.21.669.669,0,0,1,.217.5.663.663,0,0,1-.217.5l-1.069,1.055h4.451a.675.675,0,0,1,.5.2.676.676,0,0,1,.2.5.675.675,0,0,1-.2.5.676.676,0,0,1-.5.2h-4.451l1.069,1.055a.69.69,0,0,1,.212.493.673.673,0,0,1-.212.5.686.686,0,0,1-.5.208A.678.678,0,0,1,103.079-819.1Zm-8.98,0a.7.7,0,0,1-.219-.5.655.655,0,0,1,.2-.5l1.069-1.055H90.7a.675.675,0,0,1-.5-.2.676.676,0,0,1-.2-.5.675.675,0,0,1,.2-.5.676.676,0,0,1,.5-.2h4.451l-1.069-1.055a.649.649,0,0,1-.2-.5.722.722,0,0,1,.213-.5.713.713,0,0,1,.495-.212.638.638,0,0,1,.486.212l2.15,2.159a.807.807,0,0,1,.252.589.807.807,0,0,1-.252.589l-2.15,2.159a.668.668,0,0,1-.486.208.68.68,0,0,1-.49-.208Zm-1.485,5.4a1.621,1.621,0,0,1-1.189-.494,1.62,1.62,0,0,1-.494-1.189v-2.739a.675.675,0,0,1,.2-.5.676.676,0,0,1,.5-.2.675.675,0,0,1,.5.2.676.676,0,0,1,.2.5v2.739a.279.279,0,0,0,.081.206.279.279,0,0,0,.206.081h2.739a.676.676,0,0,1,.5.2.676.676,0,0,1,.2.5.675.675,0,0,1-.2.5.676.676,0,0,1-.5.2Zm10.187,0a.676.676,0,0,1-.5-.2.676.676,0,0,1-.2-.5.675.675,0,0,1,.2-.5.676.676,0,0,1,.5-.2h2.739a.279.279,0,0,0,.206-.081.279.279,0,0,0,.081-.206v-2.739a.675.675,0,0,1,.2-.5.676.676,0,0,1,.5-.2.675.675,0,0,1,.5.2.676.676,0,0,1,.2.5v2.739a1.621,1.621,0,0,1-.494,1.189,1.62,1.62,0,0,1-1.189.494Z"
        transform="translate(-88.077 832.853)"
        fill="currentColor"
      />
    </svg>
  );
}
