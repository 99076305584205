import React, { FC, useContext } from 'react';

import { OVCAlert, useGetPaletteColors } from 'return-portal-ui';

import TrackingPageContext from '../../Context/TrackingPageContext';

const NotFound: FC = () => {
  const {
    portalSettings: {
      regionalSettings: { shipmentNotFoundText },
    },
  } = useContext(TrackingPageContext);
  const { secondaryBg } = useGetPaletteColors();

  return (
    <div style={{ backgroundColor: secondaryBg }} className="md:rounded-[10px]">
      <OVCAlert>{shipmentNotFoundText}</OVCAlert>
    </div>
  );
};

export default NotFound;
