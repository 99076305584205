import { CouriersWithMethodsResponse } from '../../../queries/getCouriersWithMethods';
import { CouriersWithMethodsOptions, useCouriersWithMethods } from './useCouriersWithMethods';

export type CouriersMethodsListResponse = string[] | undefined;

function select(data: CouriersWithMethodsResponse | undefined): CouriersMethodsListResponse {
  return data?.couriers?.reduce<string[]>(
    (acc, courier) => [...acc, ...(courier.methods?.map((method) => method.type) || [])],
    [],
  );
}

export function useCouriersMethodsList(
  options?: CouriersWithMethodsOptions<CouriersMethodsListResponse>,
) {
  return useCouriersWithMethods<CouriersMethodsListResponse>({
    select,
    ...options,
  });
}
