import React from 'react';

import { useGetPaletteColors } from '../hooks';

const OVCLayoutHeader = ({ children }: { children: React.ReactNode }) => {
  const { header } = useGetPaletteColors();

  return (
    <div
      className="h-[84px] sticky w-full z-20 top-0"
      style={{ backgroundColor: header }}
      data-cy="ovc-layout-header"
    >
      <div className="h-full flex items-center md:justify-center justify-between max-w-[980px] px-8 relative mx-auto">
        {children}
      </div>
    </div>
  );
};

export default OVCLayoutHeader;
