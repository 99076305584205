import { StoreMetadata } from 'app-types';

import request from '../utils/request';

export const getStoreMetadata = () => {
  return request<StoreMetadata>(`${process.env.OUTVIO_API_URL}/store-settings/metadata`, {
    method: 'GET',
    secure: true,
  });
};
