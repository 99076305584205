export enum ButtonTypes {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  ALERT = 'alert',
  MAIN = 'main',
  GRAY = 'gray',
  OV_PRIMARY = 'ovPrimary',
  OV_PRIMARY_DARK = 'ovPrimaryDark',
  OV_DANGER = 'ovDanger',
}
