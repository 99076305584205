import { useQuery } from '@tanstack/react-query';
import { Subscription } from 'app-types';

import { hourCache } from '../../constants';
import { getSubscription } from '../../queries/apiSubscription';

export type SubscriptionInfoQuerryResult =
  | {
      subscription: Subscription;
    }
  | undefined;

export function useGetSubscriptionInfo<T = SubscriptionInfoQuerryResult>() {
  return useQuery<T>(['getSubscriptionInfo'], () => getSubscription() as T, {
    staleTime: hourCache,
    cacheTime: hourCache,
  });
}
