import React, { useContext } from 'react';

import { OVCButton, getCalculatedBrightnessFromHex } from 'return-portal-ui';
import { twMerge } from 'tailwind-merge';
import { Price } from 'ui';
import { Heading } from 'ui/atoms';

import TrackingPageContext from '../../../Context/TrackingPageContext';
import { EDIT_MODE_TABS } from '../../../Interfaces';
import Card from '../../Common/Card';
import EditButton from '../../Common/EditButton';
import Image from '../../Common/Image';

const SuggestedProductsBlock = () => {
  const {
    trackingData,
    appSettings: { isEdit },
    portalSettings: {
      colors: { secondaryBg, primaryText },
      regionalSettings,
    },
  } = useContext(TrackingPageContext);

  if (!trackingData?.recommendedProducts || !trackingData?.recommendedProducts?.length) return null;

  const productBg = getCalculatedBrightnessFromHex(secondaryBg || '', 1);
  const scrollBarColor = getCalculatedBrightnessFromHex(secondaryBg || '', 2);

  return (
    <Card className="md:p-6 flex-col w-full relative">
      <Heading style={{ color: primaryText }} className="ml-8 mt-8 md:mt-2 md:ml-2 mb-8">
        {regionalSettings?.suggestedProducts?.titleProductsForYou}
      </Heading>

      {isEdit && (
        <div className="absolute top-0 right-0">
          <EditButton tab={EDIT_MODE_TABS.SUGGESTED_PRODUCTS} />
        </div>
      )}

      <div className="w-full relative">
        <div
          style={{
            scrollbarColor: `${scrollBarColor} ${secondaryBg}`,
            scrollbarWidth: 'thin',
          }}
          className="snap-x scroll-smooth flex md:flex-wrap justify-start overflow-x-auto gap-[22px] pb-[8px] md:pb-0 mb-6 md:mb-0 px-6 md:px-0"
        >
          {trackingData?.recommendedProducts.map((product, index) => {
            const isEven = index % 2 === 0;

            return (
              <div
                key={`product-${index}`}
                className="w-[215px] snap-center rounded-[24px] p-4"
                style={{ backgroundColor: productBg }}
              >
                <div
                  className={twMerge(
                    'w-[183px] flex flex-col h-full',
                    isEven ? 'ml-auto' : 'mr-auto',
                  )}
                >
                  <div className="w-[183px] h-[183px] flex justify-center overflow-hidden rounded-[14px] bg-white">
                    <Image
                      customStyle="!object-cover !w-[183px]!h-[183px]"
                      src={product?.imageUrl || ''}
                      alt="product-image"
                    />
                  </div>
                  <div className="mt-4 px-2 mb-4">
                    <div
                      style={{ color: primaryText }}
                      className="text-[14px] leading-[16px] font-inter font-semibold"
                    >
                      {product.title}
                    </div>
                    <div
                      style={{ color: `${primaryText}B3` }}
                      className="mt-2 text-[14px] leading-[16px] font-inter font-medium"
                    >
                      <Price
                        value={product.originalPrice}
                        currency={product.currency}
                        discountPrice={product.discountedPrice}
                        styleDiscount={{
                          color: product.discountedPrice ? `${primaryText}66` : undefined,
                        }}
                      />
                    </div>
                  </div>

                  <OVCButton onClick={() => window.open(product.link, '_blank')}>
                    {regionalSettings?.suggestedProducts?.buttonShopNow}
                  </OVCButton>
                </div>
              </div>
            );
          })}
        </div>

        <div className="md:hidden !z-10 absolute w-[34px] h-full top-0 left-0 pointer-events-none">
          <div
            style={{
              background: `linear-gradient(to right, ${secondaryBg} 0%, transparent)`,
            }}
            className="h-full "
          />
        </div>

        <div className="md:hidden !z-10 absolute w-[34px] h-full top-0 right-0 pointer-events-none">
          <div
            style={{
              background: `linear-gradient(to left, ${secondaryBg} 0%, transparent)`,
            }}
            className="h-full "
          />
        </div>
      </div>
    </Card>
  );
};

export default SuggestedProductsBlock;
