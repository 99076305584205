import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { hourCache } from '../../../constants';
import { ThreePLFilterList, ThreePLFilterType, getThreePLFilterList } from '../../../queries/index';

export function useThreePLFilterList<T = ThreePLFilterList | undefined>(
  filterType: ThreePLFilterType,
  options?: UseQueryOptions<ThreePLFilterList | undefined, unknown, T, string[]>,
) {
  return useQuery(['useThreePLFilterList', filterType], () => getThreePLFilterList(filterType), {
    staleTime: hourCache,
    cacheTime: hourCache,
    ...options,
  });
}
