export enum DefaultLanguage {
  en = 'en',
  es = 'es',
  pt = 'pt',
  de = 'de',
  pl = 'pl',
}

export type GetLanguage = (
  locale: DefaultLanguage | string,
) => Promise<{ default: Record<string, string> }>;
