import * as React from 'react';

import textDE from 'localization/locales/de-DE/providerless.json';
import textEN from 'localization/locales/en-EN/providerless.json';
import textES from 'localization/locales/es-ES/providerless.json';
import textPL from 'localization/locales/pl-PL/providerless.json';
import textPT from 'localization/locales/pt-PT/providerless.json';

import { Language } from '../ts/interfaces';
import ErrorComp from './ErrorComp';

type TEXTS = Record<Language, Record<string, string>>;

const texts: TEXTS = {
  'en-EN': textEN,
  'es-ES': textES,
  'de-DE': textDE,
  'pl-PL': textPL,
  'pt-PT': textPT,
};

const containerStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 10000,

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  backgroundColor: 'rgba(255, 255, 255, 0.7)',
} as React.CSSProperties;

type PageLoadErrorBoundaryProps = { children: React.ReactElement<any, any> };
interface IState {
  hasError: boolean;
  lang: Language;
  texts: TEXTS;
}

class PageLoadErrorBoundary extends React.Component<PageLoadErrorBoundaryProps, IState> {
  constructor(props: PageLoadErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, lang: 'en-EN', texts };
  }

  static getDerivedStateFromError() {
    let lang = window.localStorage.getItem('userLanguage');
    if (lang) {
      lang = lang.replace(/"/gi, '');
    }
    return {
      hasError: true,
      lang: lang || 'en-EN',
      texts,
    };
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={containerStyle}>
          <ErrorComp isRefresh lang={this.state.lang} texts={this.state.texts}>
            {this.state.texts[this.state.lang]['providerless.general.refresh-page']}
          </ErrorComp>
        </div>
      );
    }

    return this.props.children;
  }
}

export default PageLoadErrorBoundary;
