import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { StoreMetadata } from 'app-types';

import { hourCache } from '../../../constants';
import { getStoreMetadata } from '../../../queries/index';

export function useStoreMetadata<T = StoreMetadata | undefined>(
  options?: UseQueryOptions<StoreMetadata | undefined, unknown, T, [string]>,
) {
  return useQuery(['getStoreMetadata'], () => getStoreMetadata(), {
    staleTime: hourCache,
    cacheTime: hourCache,
    ...options,
  });
}
