import React, { type HTMLProps, useMemo, useState } from 'react';

import {
  CustomDomainStatus,
  CustomDomainType,
  useGetBrandSettingsByBrandId,
  useGetCustomDomainsByStore,
  useValidateCustomDomainByStore,
} from 'data-layer';
import { useFormikContext } from 'formik';
import { PadlockClosed, Pending, Verified } from 'icons';
import { FormattedMessage } from 'localization';
import { twMerge } from 'tailwind-merge';
import {
  Button,
  ButtonVariant,
  Copy,
  Heading,
  PrefixSuffixTextInput,
  RadioButton,
  TextInput,
} from 'ui/atoms';

import { EDIT_MODE_TABS } from '../../../../Interfaces';
import Base from '../Base';
import PortalDomainsPopup from '../PortalDomainsPopup';
import {
  useClosePortalDomainPopup,
  useCustomDomainState,
  useDeleteCustomDomain,
  useGenerateNsRecords,
  useOutvioSubdomainResize,
} from './PortalDomainSection.hooks';

export interface _PortalDomainsSectionProps {
  customDomainType: CustomDomainType;
  storeBrandingId: string;
}

export type PortalDomainsSectionProps = HTMLProps<HTMLDivElement> & _PortalDomainsSectionProps;

export function PortalDomainsSection({
  customDomainType,
  storeBrandingId,
}: PortalDomainsSectionProps) {
  const { values, setFieldValue } = useFormikContext<{
    isTrackingCustomDomainEnabled: boolean;
    trackingSlug: string;
    trackingCustomDomain: string;
  }>();
  const { loading: isBrandSettingsDataLoading } = useGetBrandSettingsByBrandId(storeBrandingId);
  const { isLoading: isCustomDomainsDataLoading, refetch: refetchCustomDomains } =
    useGetCustomDomainsByStore(storeBrandingId);
  const [isPortalDomainsPopupVisible, setIsPortalDomainsPopupVisible] = useState<boolean>(false);
  const {
    mutateAsync: validateCustomDomain,
    isLoading: isCustomDomainValidationLoading,
    error: customDomainValidationError,
  } = useValidateCustomDomainByStore();
  const closePortalDomainPopup = useClosePortalDomainPopup(setIsPortalDomainsPopupVisible);
  const { deleteCustomDomain, isDeleteCustomDomainLoading, deleteCustomDomainError } =
    useDeleteCustomDomain(storeBrandingId);
  const { generateNsRecords, isCreateCustomDomainLoading, createCustomDomainError } =
    useGenerateNsRecords(storeBrandingId, setIsPortalDomainsPopupVisible);
  const { customDomainData, customDomainStatus } = useCustomDomainState(
    storeBrandingId,
    customDomainType,
  );
  useOutvioSubdomainResize();

  const isCustomDomainError = useMemo(
    () =>
      deleteCustomDomainError !== null ||
      createCustomDomainError !== null ||
      customDomainValidationError !== null ||
      customDomainStatus === CustomDomainStatus.Error,
    [
      customDomainStatus,
      deleteCustomDomainError,
      createCustomDomainError,
      customDomainValidationError,
    ],
  );

  const customDomainErrorMessage = useMemo(
    () =>
      createCustomDomainError !== null ? (
        (createCustomDomainError as Error).message
      ) : deleteCustomDomainError !== null ? (
        (deleteCustomDomainError as Error).message
      ) : customDomainValidationError !== null ? (
        <FormattedMessage id="settings.portal.verification-failed" />
      ) : (
        <FormattedMessage id="settings.portal.oops-please-retry-the-verification" />
      ),
    [createCustomDomainError, deleteCustomDomainError, customDomainValidationError],
  );

  return (
    <Base id={EDIT_MODE_TABS.PORTAL_DOMAINS}>
      <section className="text-cyan-800">
        <Heading className="mb-4 text-lg font-bold font-sans">
          <FormattedMessage id="settings.portal.portal-domains" />
        </Heading>

        <Copy className="mb-6" type="copy5">
          <FormattedMessage id="settings.portal.portal-domains-description" />:
        </Copy>

        <div className="ml-4">
          <RadioButton
            name="isTrackingCustomDomainEnabled"
            className="mb-4"
            fieldValue={values.isTrackingCustomDomainEnabled}
            label={<FormattedMessage id="settings.portal.use-outvio-subdomain" />}
            onClick={() => {
              setFieldValue('isTrackingCustomDomainEnabled', false);
            }}
            value={false}
          />
          <PrefixSuffixTextInput
            placeholderWidthClassName="w-[6.05em]"
            placeholderWidth="6.05em"
            className="ml-8 mb-6"
            name="trackingSlug"
            prefix="https://"
            suffix=".outvio.com"
            placeholder="brand-name"
            onChange={(event) => {
              setFieldValue('trackingSlug', event.target.value);
            }}
            value={values.trackingSlug}
            disabled={isBrandSettingsDataLoading || values.isTrackingCustomDomainEnabled}
          />

          <RadioButton
            className="mb-4"
            name="isTrackingCustomDomainEnabled"
            fieldValue={values.isTrackingCustomDomainEnabled}
            label={<FormattedMessage id="settings.portal.use-your-custom-domain" />}
            onClick={() => {
              setFieldValue('isTrackingCustomDomainEnabled', true);
            }}
            value={true}
          />

          <div className="flex flex-col md:flex-row">
            <TextInput
              isComplete={customDomainStatus === CustomDomainStatus.Ready}
              isLocked={customDomainStatus !== null}
              icon={
                customDomainStatus === CustomDomainStatus.Error ? (
                  <PadlockClosed width="24" height="24" />
                ) : customDomainStatus === CustomDomainStatus.Ready ? (
                  <Verified width="24" height="24" />
                ) : undefined
              }
              className="grow ml-8"
              name="trackingCustomDomain"
              placeholder="e.g., tracking.myshop.com"
              isError={isCustomDomainError}
              errorMessage={customDomainErrorMessage}
              value={values.trackingCustomDomain}
              onChange={(event) => {
                setFieldValue('trackingCustomDomain', event.target.value);
              }}
              disabled={
                isCustomDomainsDataLoading || isCreateCustomDomainLoading
                // || !values.isTrackingCustomDomainEnabled
              }
            />
            <div className={twMerge('flex', !customDomainData?.isLegacyClient && 'ml-2')}>
              {!customDomainData?.isLegacyClient &&
                (customDomainStatus === CustomDomainStatus.Created ||
                  customDomainStatus === CustomDomainStatus.Error ||
                  customDomainStatus === CustomDomainStatus.Ready) && (
                  <Button
                    className="[&:not(:last-of-type)]:mr-2"
                    theme={ButtonVariant.Secondary}
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      deleteCustomDomain(storeBrandingId, (customDomainData as any)._id);
                    }}
                    disabled={
                      !values.isTrackingCustomDomainEnabled || isCustomDomainValidationLoading
                    }
                    loading={isDeleteCustomDomainLoading}
                  >
                    <FormattedMessage id="settings.portal.reset" />
                  </Button>
                )}
              {(customDomainStatus === null ||
                customDomainStatus === CustomDomainStatus.Created) && (
                <Button
                  className="[&:not(:last-of-type)]:mr-2 ml-6 md:ml-0 mt-2 md:mt-0"
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    generateNsRecords(customDomainStatus, values.trackingCustomDomain);
                  }}
                  disabled={
                    isCustomDomainsDataLoading ||
                    !values.isTrackingCustomDomainEnabled ||
                    values.trackingCustomDomain.length === 0
                  }
                  loading={isCreateCustomDomainLoading}
                >
                  <FormattedMessage id="settings.portal.generate-ns-records" />
                </Button>
              )}
              {customDomainStatus === CustomDomainStatus.Pending && (
                <div className="flex items-center pl-2 pr-5 h-9">
                  <div className="flex items-center justify-center mr-1">
                    <Pending className="animate-spin" width="24" height="24" />
                  </div>
                  <FormattedMessage id="notifs.filters.exception-status.unread" />
                </div>
              )}
              {customDomainStatus === CustomDomainStatus.Error && (
                <Button
                  className="[&:not(:last-of-type)]:mr-2"
                  onClick={async (event) => {
                    event.preventDefault();
                    event.stopPropagation();

                    if (customDomainData) {
                      try {
                        await validateCustomDomain({
                          customDomainId: customDomainData._id,
                          storeBrandingId,
                        });
                        refetchCustomDomains();
                      } catch (error: any) {
                        console.log(error?.message || error.toString());
                      }
                    }
                  }}
                  disabled={!values.isTrackingCustomDomainEnabled}
                  loading={isCustomDomainValidationLoading}
                >
                  <FormattedMessage id="general.retry" />
                </Button>
              )}
            </div>
          </div>
        </div>
      </section>
      {isPortalDomainsPopupVisible && customDomainData && (
        <PortalDomainsPopup
          storeBrandingId={storeBrandingId}
          customDomainData={customDomainData}
          hostType="NS"
          host={customDomainData.domain}
          hostValues={customDomainData.nameServers}
          onClose={closePortalDomainPopup}
          validateCustomDomain={validateCustomDomain}
          isCustomDomainValidationLoading={isCustomDomainValidationLoading}
          customDomainValidationError={customDomainValidationError}
        />
      )}
    </Base>
  );
}

export default PortalDomainsSection;
