import { useMutation } from '@apollo/client';
import { MutationHookOptions } from '@apollo/client/react/types/types';
import { MessageReplyBySupportInput, Mutation } from 'app-types/desk';

import { CREATE_SUPPORT_MESSAGE_BY_RECIPIENT } from './queries';

type Response = { message: Mutation['Message_ReplyBySupport'] };

export function useCreateSupportMessageByRecipient(
  options?: MutationHookOptions<Response, { input: MessageReplyBySupportInput }>,
) {
  return useMutation<Response, { input: MessageReplyBySupportInput }>(
    CREATE_SUPPORT_MESSAGE_BY_RECIPIENT,
    {
      ...options,
      context: { ...options?.context, clientName: 'desk' },
    },
  );
}
