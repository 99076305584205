import { useQuery } from '@apollo/client';
import { DocumentNode, OperationVariables, TypedDocumentNode } from '@apollo/client/core';
import { QueryHookOptions, QueryResult } from '@apollo/client/react/types/types';
import { Query } from 'app-types/desk-analytics';

export function useQueryDeskAnalytics<
  TData = Query,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>,
): QueryResult<TData, TVariables> {
  return useQuery<TData, TVariables>(query, {
    ...options,
    context: { ...options?.context, clientName: 'deskAnalytics' },
  });
}
