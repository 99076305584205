import { useQuery } from 'react-query';

import getCouriersIcons from '../Utils/api/apiCustomCouriers';

const dayCache = 60 * 1000 * 60 * 24;

export default function useCourierIcon() {
  return useQuery(['getCouriersIcons'], getCouriersIcons, {
    cacheTime: dayCache,
    staleTime: dayCache,
  });
}
