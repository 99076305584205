export function Link({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="currentColor"
      {...props}
    >
      <g id="Group_4511" transform="translate(-1036 -194)">
        <rect width="20" height="20" transform="translate(1036 194)" fill="none" />
        <path
          d="M5.833,14.167a4.016,4.016,0,0,1-2.948-1.219A4.018,4.018,0,0,1,1.667,10,4.018,4.018,0,0,1,2.885,7.052,4.016,4.016,0,0,1,5.833,5.833H8.25a.875.875,0,0,1,0,1.75H5.833A2.351,2.351,0,0,0,3.417,10a2.351,2.351,0,0,0,2.416,2.417H8.25a.875.875,0,0,1,0,1.75Zm1.709-3.292a.875.875,0,0,1,0-1.75h4.916a.875.875,0,0,1,0,1.75Zm4.208,3.292a.875.875,0,0,1,0-1.75h2.417A2.351,2.351,0,0,0,16.583,10a2.351,2.351,0,0,0-2.416-2.417H11.75a.875.875,0,0,1,0-1.75h2.417A4.16,4.16,0,0,1,18.333,10a4.16,4.16,0,0,1-4.166,4.167Z"
          transform="translate(1031.858 204) rotate(-45)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
