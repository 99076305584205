// TODO: Move to TS
import { BaseEditor } from 'slate';
import { HistoryEditor } from 'slate-history';
import { ReactEditor } from 'slate-react';

export enum SlateElementType {
  PARAGRAPH = 'paragraph',
}

export enum SlateLeafType {
  TEXT = 'text',
  LINK = 'link',
}

export type CustomEditor = BaseEditor & ReactEditor & HistoryEditor;

export type CustomText = {
  type: SlateLeafType.TEXT;
  text: string;
};

export type CustomLink = {
  type: SlateLeafType.LINK;
  text?: string;
  href: string;
};

export type CustomLeaf = CustomText | CustomLink;

export type ParagraphElement = {
  type: SlateElementType.PARAGRAPH;
  children: CustomLeaf[];
};
//
// declare module 'slate' {
//   interface CustomTypes {
//     Editor: CustomEditor;
//     Element: ParagraphElement;
//     Leaf: CustomLeaf;
//     Text: Text | CustomLeaf;
//   }
// }
