import { useMutation } from '@apollo/client';
import { Mutation } from 'app-types/core';

import { BrandCreateCustomMutation } from '../gql/core/mutations';

export function useAddNewBrand() {
  const [mutate, { loading, error }] = useMutation<{
    Brand_createCustom: Mutation['Brand_createCustom'];
  }>(BrandCreateCustomMutation, { context: { clientName: 'core' } });

  return {
    createBrand: async (input: { slug: string }) => {
      const { data } = await mutate({
        variables: { input },
      });
      return data?.Brand_createCustom;
    },
    loading,
    error,
  };
}
