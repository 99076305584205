import { useEffect, useState } from 'react';

import { DefaultLanguage, GetLanguage } from '../types';

export function useDefaultMessages(language: DefaultLanguage | string, getLanguage: GetLanguage) {
  const [state, setState] = useState<Record<string, string> | null>(null);

  useEffect(() => {
    const q = [getLanguage('en')];
    if (language !== DefaultLanguage.en) {
      q.push(getLanguage(language));
    }
    let messages = {};
    Promise.allSettled(q).then((files) => {
      for (const file of files) {
        if (file.status !== 'fulfilled') continue;
        messages = Object.assign({}, messages, file.value.default);
      }
      setState(messages);
    });
  }, [language]);

  return state;
}
