import { FC, ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

type TabData = {
  value: string;
  label: string | ReactNode;
  disabled?: boolean;
};

const BigTabs: FC<{
  openTab: string;
  onChangeTab(newTab: string): void;
  data: TabData[];
  exact?: boolean;
}> = ({ openTab, onChangeTab, data, exact }) => {
  return (
    <div className="w-full flex min-h-[48px] items-center justify-start">
      {data.map((entry: TabData) => {
        const isSelected = exact ? openTab === entry.value : openTab.startsWith(entry.value);
        return (
          <a
            key={entry.value}
            className={twMerge(
              'relative px-8 h-full mr-1 flex items-center bg-white border-transparent border-b-blueGray-300 justify-center text-blueGray-910/90 font-semibold bg-inherit cursor-pointer border-t border-r border-l rounded-t-lg after:block',
              isSelected &&
                '[text-shadow:_0_0_0_black] bg-white border-blueGray-300 before:absolute before:-bottom-2 before:h-3 before:w-full before:bg-white first-of-type:before:border-l-blueGray-300 first-of-type:before:border-l first-of-type:before:-left-px first-of-type:before:w-[calc(100%+1px)]',
            )}
            onClick={() => {
              onChangeTab(entry.value);
            }}
          >
            {entry.label}
          </a>
        );
      })}
    </div>
  );
};

export { BigTabs };
