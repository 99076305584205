import React, { FC } from 'react';

import { Switch } from '@headlessui/react';
import { FieldProps } from 'formik';

const Toggle: FC<FieldProps & { disabled?: boolean }> = (props) => {
  const {
    field: { name, checked },
    form: { setFieldValue },
    // @ts-ignore next-line
    onCustomChange,
  } = props;

  const color = props?.disabled
    ? { bg: 'bg-grayTP-300 opacity-50', ball: 'bg-white/70' }
    : {
        bg: checked ? 'bg-cyan-100' : 'bg-grayTP-300',
        ball: checked ? 'bg-primary' : 'bg-white',
      };

  return (
    <div>
      <Switch
        disabled={props?.disabled}
        checked={checked || false}
        onChange={(value: boolean) => {
          setFieldValue(name, value);
          if (onCustomChange) onCustomChange(value);
        }}
        className={`${color.bg} disabled:cursor-not-allowed 
          relative inline-flex h-[18px] w-[36px] shrink-0 cursor-pointer rounded-full border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
      >
        <span className="sr-only" />
        <span
          aria-hidden="true"
          className={`${checked ? 'translate-x-[18px]' : 'translate-x-0'}
          ${color.ball}
            pointer-events-none inline-block h-[18px] w-[18px] transform rounded-full shadow-lg ring-0 transition duration-200 ease-in-out`}
        />
      </Switch>
    </div>
  );
};

export default Toggle;
