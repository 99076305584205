import { type HTMLProps } from 'react';

import { twMerge } from 'tailwind-merge';

export interface _OverlayPopupProps {
  overlayClassName?: string;
  containerClassName?: string;
  className?: string;
}

export type OverlayPopupProps = HTMLProps<HTMLDivElement> & _OverlayPopupProps;

export function OverlayPopup({
  overlayClassName,
  containerClassName,
  className,
  children,
  ...otherProps
}: OverlayPopupProps) {
  return (
    <div
      className={twMerge(
        'fixed top-0 left-0 flex items-center justify-center w-full h-[100vh] bg-[#233841] bg-opacity-30 z-[99999]',
        overlayClassName,
      )}
      {...otherProps}
    >
      <div
        className={twMerge(
          'flex max-w-[448px] max-h-[calc(100%-38px)] w-[calc(100%-38px)] bg-white rounded-[22px] overflow-hidden drop-shadow-[0_16px_32px_rgba(94,123,140,0.24)]',
          containerClassName,
        )}
      >
        <div className={twMerge('w-full p-[40px] overflow-y-auto', className)}>{children}</div>
      </div>
    </div>
  );
}

export default OverlayPopup;
