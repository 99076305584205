import { IUploadChatLogo, IUploadTicketAttachment } from 'app-types';

import request from '../../request';

export interface DeskConfigurationDto {
  supportEmail?: string;
  senderName?: string;
  useCustomDomain?: boolean;
  customEmail?: string;
}

export const uploadTicketAttachment = async (file: string | Blob) => {
  const formData = new FormData();
  formData.append('file', file);
  return request<IUploadTicketAttachment>(
    //@ts-ignore
    `${import.meta.env.VITE_OUTVIO_API_URL}/upload/ticket-attachment`,
    {
      method: 'POST',
      data: formData,
      secure: true,
      upload: true,
    },
  ).then((data) => data?.result);
};

export const uploadChatLogo = async (file: string | Blob) => {
  const formData = new FormData();
  formData.append('image', file);
  return request<IUploadChatLogo>(
    //@ts-ignore
    `${import.meta.env.VITE_OUTVIO_API_URL}/upload?folder=logos`,
    {
      method: 'POST',
      data: formData,
      secure: true,
      upload: true,
    },
  ).then((data) => data?.file);
};
