import React from 'react';

import { CheckMark } from 'icons';
import { useGetPaletteColors } from 'return-portal-ui';
import { twMerge } from 'tailwind-merge';

type CheckboxProps = { checked: boolean; label: JSX.Element; onChange: (checked: boolean) => void };

const Checkbox = ({ checked, label, onChange }: CheckboxProps) => {
  const { secondaryBg, btnBg } = useGetPaletteColors();

  return (
    <div className="flex w-full gap-4">
      <div
        onClick={() => onChange(!checked)}
        className="w-[18px] h-[18px] mt-[0.15em] block relative cursor-pointer select-none"
      >
        <input
          id="infoCheck"
          className="appearance-none"
          type="checkbox"
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
        />
        <span
          style={{
            backgroundColor: checked ? btnBg : '',
            borderColor: checked ? btnBg : '',
            color: checked ? secondaryBg : '',
          }}
          className={twMerge(
            'absolute inset-0 h-full w-full rounded-md border-2 pointer-events-none',
            checked
              ? 'drop-shadow-[0px_2px_4px_rgba(75,180,179,0.2)]'
              : 'bg-white border-v2blueGray-200',
          )}
        />
        {checked && (
          <CheckMark
            width="11"
            height="11"
            className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-60%] pointer-events-none text-white"
          />
        )}
      </div>

      <label htmlFor="infoCheck" className="flex-1">
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
