import { useMemo } from 'react';

import { type QueryHookOptions, useQuery } from '@apollo/client';
import { Query } from 'app-types/desk';

import { GET_TAGS_QUERY } from '../../gql/queries';

export function useGetTags(options?: QueryHookOptions) {
  const { data, loading, refetch } = useQuery<{ Store_MyStore: Query['Store_MyStore'] }>(
    GET_TAGS_QUERY,
    options,
  );
  return useMemo(() => {
    return {
      data: data?.Store_MyStore?.tags ?? [],
      loading,
      refetch,
    };
  }, [data, loading, refetch]);
}
