export function EditIcon({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em" {...props}>
      <rect id="Rectangle_8024" data-name="Rectangle 8024" width="24" height="24" fill="none" />
      <path
        id="Path_29171"
        data-name="Path 29171"
        d="M353,410.361l-.778.831a2.1,2.1,0,0,1-3.111,0,2.106,2.106,0,0,0-3.111,0"
        transform="translate(-334 -393.379)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.6"
      />
      <path
        id="Path_47077"
        data-name="Path 47077"
        d="M339,411.879h1.3a3.416,3.416,0,0,0,.75-.042,1.575,1.575,0,0,0,.449-.182,3.307,3.307,0,0,0,.56-.488l9.771-9.539a1.584,1.584,0,0,0,0-2.278,1.679,1.679,0,0,0-2.333,0l-9.771,9.539a3.292,3.292,0,0,0-.5.546,1.464,1.464,0,0,0-.187.44,3.164,3.164,0,0,0-.043.731Z"
        transform="translate(-334 -393.379)"
        fill="currentColor"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </svg>
  );
}
