import React, { VFC } from 'react';

import { DisplayStep } from '../../../../Interfaces';
import IconContainer from './IconContainer';
import StepText from './StepText';

interface StepRowProps {
  step: DisplayStep;
}

const StepRow: VFC<StepRowProps> = ({ step }) => {
  if (!step) return null;
  return (
    <>
      <IconContainer step={step} />
      <StepText step={step} />
    </>
  );
};

export default StepRow;
