export enum CustomDomainStatus {
  None = 'none',
  Created = 'created',
  Deleting = 'deleting',
  Pending = 'pending',
  Ready = 'ready',
  Error = 'error',
}

export enum CustomDomainType {
  Returns = 'returns',
  Tracking = 'tracking',
}

export type CustomDomain = {
  _id: string;
  domain: string;
  nameServers: Array<string>;
  storeBrandingId: string;
  storeId: string;
  type: CustomDomainType;
  status: CustomDomainStatus;
  error?: string;
  isLegacyClient?: boolean;
};
