import React, { useContext } from 'react';

import { getCalculatedBrightnessFromHex, useGetPaletteColors } from 'return-portal-ui';
import { Copy } from 'ui/atoms';
import { useCourierIcon } from 'ui/hooks';

import TrackingPageContext from '../../../../Context/TrackingPageContext';
import { OutvioStatusCodes } from '../../../../Interfaces';
import CourierIcon from './CourierIcon';
import TrackingNumberComponent from './TrackingNumber';

const Header = () => {
  const { data = [] } = useCourierIcon();
  const { secondaryBg, primaryText } = useGetPaletteColors();

  const {
    trackingData,
    portalSettings: { regionalSettings },
  } = useContext(TrackingPageContext);
  if (!trackingData) return null;
  const {
    deliveryInfo: { courier },
    orderInfo: { trackingNumber, secondaryTrackingNumber },
  } = trackingData;

  const courierName = data.find((item) => item.integration.toLowerCase() === courier.toLowerCase());

  const borderColor = getCalculatedBrightnessFromHex(secondaryBg, 2);

  return (
    <div className="flex flex-col justify-center items-center px-6">
      <div
        style={{ borderColor }}
        className="w-full grid grid-cols-2 gap-4 items-center border rounded-[14px] p-6"
      >
        <div style={{ borderColor }} className="flex items-center gap-x-4 border-r">
          {!trackingData?.orderStatusInfo?.steps?.some(
            (step) => step.displayCode === OutvioStatusCodes.DELIVERED_TO_SHOP,
          ) && <CourierIcon courier={courier} className="h-8" />}
          <div className="flex flex-col gap-1">
            <Copy as="div" type="copy3" style={{ color: primaryText }} className="opacity-50">
              {regionalSettings?.tracking?.titleCourier}
            </Copy>
            <Copy as="div" type="copy1" style={{ color: primaryText }} weight="medium">
              {courierName?.name}
            </Copy>
          </div>
        </div>

        <div className="flex flex-col gap-1">
          <Copy as="div" type="copy3" style={{ color: primaryText }} className="opacity-50">
            {regionalSettings?.tracking?.titleTrackingNumber}
          </Copy>
          <TrackingNumberComponent
            secondaryTrackingNumber={secondaryTrackingNumber}
            trackingNumber={trackingNumber}
            courier={courier}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
