import React, { useContext, useState } from 'react';

import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { AccountCircleFilled, Home } from 'icons';
import { OVCButton, useGetPaletteColors } from 'return-portal-ui';
import { Editable, Slate } from 'slate-react';

import TrackingPageContext from '../../../../../Context/TrackingPageContext';
import useInitEditor from '../../../../../Hooks/useInitEditor';
import Checkbox from '../../../../Common/Inputs/Checkbox';
import FormikTextInput from '../../../../Common/Inputs/FormikTextInput';

interface ChangeAddressData {
  name: string;
  phone?: string;
  postcode: string;
  email?: string;
  address: string;
  city: string;
  country?: string;
}

interface IFormComponent {
  toggleIsFormOngoing(value: boolean): void;
}

const useForm = ({ toggleIsFormOngoing }: IFormComponent) => {
  const { trackingData, handleChangeDeliveryAddress } = useContext(TrackingPageContext);
  const initialValues: ChangeAddressData | null = trackingData
    ? {
        name: trackingData.deliveryInfo.contact.fullName,
        address: trackingData.deliveryInfo.address.street,
        city: trackingData.deliveryInfo.address.city,
        email: trackingData.deliveryInfo.contact.email,
        phone: trackingData.deliveryInfo.contact.phone,
        postcode: trackingData.deliveryInfo.address.postcode,
        country: trackingData.deliveryInfo.address.country,
      }
    : null;

  const onSubmit = (values: ChangeAddressData, { setErrors }: FormikHelpers<ChangeAddressData>) => {
    if (values && handleChangeDeliveryAddress) {
      const noCountryValues = { ...values };
      delete noCountryValues.country;
      handleChangeDeliveryAddress(noCountryValues).then((val) => {
        if (!val.success) {
          setErrors({ name: 'There was an error processing your request' });
          return false;
        }
        toggleIsFormOngoing(false);
        return true;
      });
    }
  };
  return { initialValues, canUserEdit: trackingData?.deliveryInfo.canUserEdit || false, onSubmit };
};

function FormComponent({ toggleIsFormOngoing }: IFormComponent) {
  const {
    portalSettings: {
      regionalSettings: {
        deliveryInfo: { confirmChangeButton, checkboxText },
      },
    },
  } = useContext(TrackingPageContext);

  const { primaryText, btnBg } = useGetPaletteColors();

  const { initialValues, canUserEdit, onSubmit } = useForm({ toggleIsFormOngoing });
  const { editor } = useInitEditor();
  const [isChecked, setIsChecked] = useState<boolean>(false);

  if (!initialValues) return null;

  const slateData = { value: checkboxText, initialValue: checkboxText, editor } as any;

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ dirty, errors }) => (
        <Form>
          <div className="flex flex-col space-y-6">
            <div className="flex space-x-4">
              <Home style={{ color: btnBg }} className="text-[30px] mt-1.5" />
              <div className="flex flex-col space-y-2 w-full">
                <Field component={FormikTextInput} name="address" />
                <Field component={FormikTextInput} name="city" />
                <div className="flex space-x-2">
                  <Field component={FormikTextInput} name="postcode" />
                  <Field component={FormikTextInput} name="country" disabled />
                </div>
              </div>
            </div>
            <div className="flex space-x-4">
              <AccountCircleFilled style={{ color: btnBg }} className="text-[30px] mt-1.5" />
              <div className="flex flex-col space-y-2 w-full">
                <Field component={FormikTextInput} name="name" />
                <Field component={FormikTextInput} name="email" />
                <Field component={FormikTextInput} name="phone" />
              </div>
            </div>

            <div className="ml-1 mr-4">
              <Checkbox
                checked={isChecked}
                label={
                  <Slate {...slateData}>
                    <span style={{ color: primaryText }} className="text-sm">
                      <Editable readOnly />
                    </span>
                  </Slate>
                }
                onChange={setIsChecked}
              />
            </div>

            {!!errors.name && <span className="text-v2orange-200">{errors.name}</span>}
            <OVCButton
              className="ml-auto"
              disabled={!canUserEdit || !isChecked || !dirty}
              type="submit"
              icon={<CheckRoundedIcon />}
            >
              {confirmChangeButton}
            </OVCButton>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default FormComponent;
