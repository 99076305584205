import { useQuery } from '@tanstack/react-query';
import { PopulatedCourier } from 'app-types';
import { request } from 'ui';

import { hourCache } from '../constants';

export const requestShippingCouriersByOtn = (
  otn: string,
): Promise<{ couriers: PopulatedCourier[] }> =>
  request(`${process.env.OUTVIO_API_URL}/shipping-queue/couriers/order/${otn}`, {
    method: 'GET',
    secure: true,
  });

export function useGetShippingCouriersByOtn(otn: string, refetchOnMount?: boolean) {
  return useQuery(['getCouriersByOtn'], () => requestShippingCouriersByOtn(otn), {
    staleTime: hourCache,
    cacheTime: hourCache,
    enabled: !!otn,
    refetchOnMount: refetchOnMount ? 'always' : undefined,
  });
}
