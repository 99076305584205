import React, { useContext } from 'react';

import { FileRejection } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import EditModalContext from '../../../../Context/EditModalContext';
import ImageUploader from '../../ImageUploader';

const useFaviconUploader = () => {
  const {
    formData: { favicon },
    setFieldValue,
    uploadFileRequest,
  } = useContext(EditModalContext);

  const handleDeleteFavicon = (): void => {
    setFieldValue('favicon', null);
  };
  const handleUploadFavicon = async (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
  ): Promise<boolean> => {
    return fileRejections.length
      ? new Promise<boolean>((_, reject) => {
          reject(fileRejections[0].errors[0].message);
        }).catch(() => false)
      : uploadFileRequest({ file: acceptedFiles[0] })
          .then(({ file: { location } }) => setFieldValue('favicon', location))
          .then(() => true)
          .catch(() => false);
  };

  return { favicon, handleUploadFavicon, handleDeleteFavicon };
};

export const FaviconUploader = () => {
  const { favicon, handleUploadFavicon, handleDeleteFavicon } = useFaviconUploader();
  const { t } = useTranslation('trackingPage', {
    keyPrefix: 'editMode.modules.pageTitleAndFavicon',
  });
  return (
    <ImageUploader
      handleUploadImage={(acceptedFiles, fileRejections) =>
        handleUploadFavicon(acceptedFiles, fileRejections)
      }
      handleDeleteImage={() => handleDeleteFavicon()}
      uploadText={t('uploadTitle')}
      uploadDescription={t('uploadDescription')}
      imageSrc={favicon || undefined}
    />
  );
};
