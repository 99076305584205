import React, { FC, useState } from 'react';

import ContentLoader from 'react-content-loader';

const BASE_WIDTH = 'auto';
const BASE_HEIGHT = 'auto';
const BASE_MIN_WIDTH = 'auto';
const BASE_MIN_HEIGHT = 'auto';

interface Image {
  src: string;
  alt: string;
  width?: string;
  height?: string;
  minWidth?: string;
  minHeight?: string;
  customStyle?: string;
}

interface UseImage {
  customStyle?: string;
  width: string;
  height: string;
  minWidth: string;
  minHeight: string;
}

interface ImageLoader {
  width: string;
  height: string;
  customClassName?: string;
}

export const useImage = (props: UseImage) => {
  const { customStyle, width, height, minWidth, minHeight } = props;
  const [isLoading, setInternalIsLoading] = useState(true);
  const setIsLoading = (value: boolean) => setInternalIsLoading(value);
  const inlineStyle: React.CSSProperties = {
    width,
    height,
    minWidth,
    minHeight,
    display: isLoading ? 'none' : 'block',
    objectFit: 'cover',
  };

  return {
    classNameStyle: customStyle,
    inlineStyle,
    isLoading,
    setIsLoading,
  };
};

const ImageLoader: FC<ImageLoader> = (props) => {
  const { width, height, customClassName = '' } = props;

  return (
    <div style={{ width, height }} className={customClassName}>
      <ContentLoader
        width={width}
        height={height}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="0" y="0" rx="10" ry="10" width={width} height={height} />
      </ContentLoader>
    </div>
  );
};

const Image: FC<Image> = (props) => {
  const {
    src,
    customStyle,
    alt,
    width = BASE_WIDTH,
    height = BASE_HEIGHT,
    minHeight = BASE_MIN_HEIGHT,
    minWidth = BASE_MIN_WIDTH,
  } = props;
  const { inlineStyle, classNameStyle, isLoading, setIsLoading } = useImage({
    customStyle,
    width,
    height,
    minHeight,
    minWidth,
  });
  return (
    <>
      {isLoading && <ImageLoader width={width} height={height} customClassName={classNameStyle} />}
      <img
        src={src}
        alt={alt}
        onLoad={() => setIsLoading(false)}
        className={classNameStyle}
        style={inlineStyle}
      />
    </>
  );
};

export default Image;
