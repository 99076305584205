import { CustomDomain, CustomDomainType } from '../constants';
import request from '../utils/request';

export type CreateCustomDomainsResponseData = Pick<CustomDomain, 'domain' | 'nameServers'>;

type CustomDomainsResponse = {
  success: boolean;
  data: CreateCustomDomainsResponseData;
};

export type CreateCustomDomainByStoreData = {
  name: string;
  type: CustomDomainType;
};

export const createCustomDomainByStore = (
  storeBrandingId: string,
  data: CreateCustomDomainByStoreData,
): Promise<CreateCustomDomainsResponseData | undefined> =>
  request<CustomDomainsResponse | undefined>(
    `${process.env.OUTVIO_API_URL}/portal/${storeBrandingId}/custom-domain`,
    {
      method: 'POST',
      secure: true,
      data,
    },
  ).then((response) => {
    const { data } = response ?? {};
    return data;
  });
