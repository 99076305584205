import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { hourCache } from '../../../constants';
import { StoreBrandingResponse, getBrandingList } from '../../../queries/index';

export type BrandingListQueryResult = StoreBrandingResponse | undefined;

export function useBrandingList<T = BrandingListQueryResult>(
  options?: UseQueryOptions<BrandingListQueryResult, unknown, T, [string]>,
) {
  return useQuery(['getBrandingList'], () => getBrandingList(), {
    staleTime: hourCache,
    cacheTime: hourCache,
    ...options,
  });
}
