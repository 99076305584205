import React, { type HTMLProps } from 'react';

import { UseMutateAsyncFunction } from '@tanstack/react-query';
import {
  CustomDomain,
  type ValidateCustomDomainByStoreProps,
  useGetCustomDomainsByStore,
} from 'data-layer';
import { Close } from 'icons';
import { FormattedMessage } from 'localization';
import { Button, Copy, Error, Heading, IconButton } from 'ui/atoms';
import { OverlayPopup } from 'ui/templates';

export interface _PortalDomainsPopupProps {
  storeBrandingId: string;
  customDomainData: CustomDomain | null;
  hostType: string;
  host: string;
  hostValues: Array<string>;
  isCustomDomainValidationLoading: boolean;
  customDomainValidationError: unknown;
  validateCustomDomain: UseMutateAsyncFunction<
    boolean,
    unknown,
    ValidateCustomDomainByStoreProps,
    unknown
  >;
  onClose: () => void;
}

export type PortalDomainsPopupProps = HTMLProps<HTMLDivElement> & _PortalDomainsPopupProps;

export function PortalDomainsPopup({
  storeBrandingId,
  customDomainData,
  hostType,
  host,
  hostValues,
  isCustomDomainValidationLoading,
  customDomainValidationError,
  validateCustomDomain,
  onClose,
}: PortalDomainsPopupProps) {
  const { refetch: refetchCustomDomains } = useGetCustomDomainsByStore(storeBrandingId);

  return (
    <OverlayPopup overlayClassName="absolute top-0 left-0 flex items-center justify-center w-full h-full !mt-[0px] bg-[#233841] bg-opacity-30 z-[99999]">
      <div className="flex flex-col text-v2blueGray-800">
        <div className="relative pt-2 pb-8 pl-2">
          <Heading>
            <FormattedMessage id="settings.portal.translations.whatIsNext" />
          </Heading>
          <IconButton
            className="absolute top-0 right-0 flex items-center justify-center w-9 h-9"
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              onClose();
            }}
          >
            <Close width="24" height="24" />
          </IconButton>
        </div>
        <div className="flex mb-8">
          <div className="min-w-[40px]">
            <Copy className="my-0 ml-2 text-v2blueGray-500" type="copy4">
              1.
            </Copy>
          </div>
          <div className="grow">
            <Copy className="my-0" type="copy4" weight="semibold">
              <FormattedMessage id="settings.portal.hosting-provider" />
            </Copy>
            <Copy className="mb-0 mt-4" type="copy7">
              <FormattedMessage id="settings.portal.hosting-provider-description" />
            </Copy>
          </div>
        </div>
        <div className="p-8 mb-[41px] bg-v2blueGray-800 rounded-[14px]">
          <div className="relative flex w-full">
            <div className="absolute top-0 left-0 w-[1px] h-[calc(300%+16px)] bg-v2blueGray-610" />
            <Copy
              className="min-w-[44px] w-[18.75%] my-0 mr-4 font-inconsolata text-v2blueGray-100 text-right"
              type="copy8"
              weight="medium"
            >
              <FormattedMessage id="settings.claims-dns-modal.records.label.type" />
            </Copy>
            <Copy
              className="grow my-0 font-inconsolata text-[#50D6FF]"
              type="copy8"
              weight="semibold"
            >
              {hostType}
            </Copy>
          </div>
          <div className="flex w-full mt-2">
            <Copy
              className="min-w-[44px] w-[18.75%] my-0 mr-4 font-inconsolata text-v2blueGray-100 text-right"
              type="copy8"
              weight="medium"
            >
              <FormattedMessage id="settings.claims-dns-modal.records.label.host" />
            </Copy>
            <Copy className="grow my-0 font-inconsolata text-[#9778FF]" type="copy8">
              {host}
            </Copy>
          </div>
          <div className="flex w-full mt-2">
            <Copy
              className="min-w-[44px] w-[18.75%] my-0 mr-4 font-inconsolata text-v2blueGray-100 text-right"
              type="copy8"
              weight="medium"
            >
              <FormattedMessage id="settings.claims-dns-modal.records.label.value" />
            </Copy>
            <div className="grow">
              {hostValues.map((hostValue) => (
                <Copy key={hostValue} className="my-0 font-inconsolata text-[#3AD1B6]" type="copy8">
                  {hostValue}
                </Copy>
              ))}
            </div>
          </div>
        </div>
        <div className="flex">
          <div className="min-w-[40px]">
            <Copy className="my-0 ml-2 text-v2blueGray-500" type="copy4">
              2.
            </Copy>
          </div>
          <div className="grow">
            <Copy className="my-0" type="copy4" weight="semibold">
              <FormattedMessage id="settings.portal.verification" />
            </Copy>
            <Copy className="mb-0 mt-4" type="copy7">
              <FormattedMessage id="settings.portal.verification-description" />
            </Copy>
          </div>
        </div>

        <div>
          {customDomainValidationError ? (
            <>
              <Error
                className="[&:first-child]:mt-8 [&:not(last-child)]:mb-2"
                titleClassName="capitalize"
                title={<FormattedMessage id="settings.portal.verification-failed" />}
                message={(customDomainValidationError as any).message ?? ''}
              />
            </>
          ) : (
            <></>
          )}
        </div>

        <Button
          className="self-end mt-8 mr-2 mb-2"
          onClick={async (event) => {
            event.preventDefault();
            event.stopPropagation();
            if (customDomainData) {
              try {
                await validateCustomDomain({
                  customDomainId: customDomainData._id,
                  storeBrandingId,
                });
                refetchCustomDomains();
                onClose();
              } catch (error: any) {
                console.log(error?.message || error.toString());
              }
            }
          }}
          loading={isCustomDomainValidationLoading}
        >
          <FormattedMessage id="settings.claims-dns-modal.records.verify.cta" />
        </Button>
      </div>
    </OverlayPopup>
  );
}

export default PortalDomainsPopup;
