import { type Dispatch, type InputHTMLAttributes, ReactNode, type SetStateAction } from 'react';

import { InputBackgroundFragment, PrefixSuffixInputFragment } from './fragments';

export type PrefixSuffixTextInputProps = InputHTMLAttributes<HTMLInputElement> & {
  placeholderWidth: string;
  placeholderWidthClassName: string;
  name: string;
  isFocused?: boolean;
  setIsFocused?: Dispatch<SetStateAction<boolean>>;
  isError?: boolean;
  prefix: string;
  suffix: string;
  className?: string;
  backgroundClassName?: string;
  inputClassName?: string;
  icon?: ReactNode;
  IconTag?: 'button' | 'div';
  iconPosition?: 'left' | 'right';
  onIconCtaClick?: (event: React.MouseEvent<HTMLElement>) => void;
  errorMessage?: string;
  register?: (name: string) => any;
};

export function PrefixSuffixTextInput({
  className,
  inputClassName,
  backgroundClassName,
  placeholderWidthClassName,
  placeholderWidth,
  iconPosition = 'left',
  icon,
  name,
  onIconCtaClick,
  type = 'text',
  isFocused = false,
  setIsFocused,
  isError = false,
  prefix,
  suffix,
  errorMessage,
  IconTag = 'div',
  register,
  disabled,
  ...otherProps
}: PrefixSuffixTextInputProps) {
  return (
    <InputBackgroundFragment
      className={className}
      backgroundClassName={backgroundClassName}
      icon={icon}
      IconTag={IconTag}
      iconPosition={iconPosition}
      onIconCtaClick={onIconCtaClick}
      isFocused={isFocused}
      isError={isError}
      errorMessage={errorMessage}
      disabled={disabled}
    >
      <PrefixSuffixInputFragment
        placeholderWidthClassName={placeholderWidthClassName}
        placeholderWidth={placeholderWidth}
        inputClassName={inputClassName}
        iconPosition={iconPosition}
        icon={icon}
        name={name}
        type={type}
        prefix={prefix}
        suffix={suffix}
        setIsFocused={setIsFocused}
        isError={isError}
        register={register}
        disabled={disabled}
        {...otherProps}
      />
    </InputBackgroundFragment>
  );
}

export default PrefixSuffixTextInput;
