import React, { useContext } from 'react';

import TrackingPageContext from '../../../../../Context/TrackingPageContext';
import BaseModal from '../../../../Common/BaseModal';
import InfoComponent from '../Info';

interface IModalComponent {
  isVisible: boolean;
  handleClose(): void;
}

function ModalComponent(props: IModalComponent) {
  const { isVisible, handleClose } = props;
  const {
    portalSettings: {
      regionalSettings: {
        cancelOrder: {
          infoStep: { title: infoTitle },
        },
      },
    },
  } = useContext(TrackingPageContext);

  return (
    <BaseModal
      title={infoTitle}
      isVisible={isVisible}
      handleClose={handleClose}
      iframeHeight="2000px"
    >
      <div className="w-full md:w-[400px]">
        <InfoComponent onClose={handleClose} />
      </div>
    </BaseModal>
  );
}

export default ModalComponent;
