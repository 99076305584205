import { QueryHookOptions } from '@apollo/client/react/types/types';
import { CountTicketsObject, QueryDailyTickets_CountTicketsArgs } from 'app-types/desk-analytics';

import { DAILY_TICKETS_COUNT_TICKETS } from '../../../gql/desk-analytics/queries';
import { useQueryDeskAnalytics } from '../useQueryDeskAnalytics';

export function useGetDailyTicketCount(
  { from, to, tags, channels }: Partial<QueryDailyTickets_CountTicketsArgs>,
  options?: QueryHookOptions,
) {
  const { data, loading, error } = useQueryDeskAnalytics<{
    DailyTickets_countTickets: CountTicketsObject;
  }>(DAILY_TICKETS_COUNT_TICKETS, {
    skip: !from || !to,
    variables: { from, to, ...(tags && { tags }), channels },
    ...options,
  });
  return {
    data: data?.DailyTickets_countTickets,
    loading,
    error,
  };
}
