import React from 'react';

const DestinationIcon = ({ color = '#4bb4b3', size = 41.83 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 41.83 41.83">
    <g fill={color}>
      <path d="M39.506 0H20.915a2.331 2.331 0 0 0-2.324 2.324v3.741l.023.023 11.62 10.458a6.952 6.952 0 0 1 2.3 5.182v1.511h4.648v4.648h-4.648v4.648h4.648v4.648h-4.648v4.647h6.972a2.331 2.331 0 0 0 2.324-2.324V2.324A2.331 2.331 0 0 0 39.506 0zM27.891 9.3h-4.652V4.648h4.652zm9.3 9.3h-4.657v-4.657h4.648zm0-9.3h-4.657V4.648h4.648z" />
      <path d="M27.887 39.506V21.728a2.326 2.326 0 0 0-.767-1.72L15.5 9.551a2.233 2.233 0 0 0-1.557-.6 2.342 2.342 0 0 0-1.557.6L.767 20.009A2.252 2.252 0 0 0 0 21.728v17.778a2.331 2.331 0 0 0 2.324 2.324h4.648A2.331 2.331 0 0 0 9.3 39.506v-9.3h9.3v9.3a2.331 2.331 0 0 0 2.324 2.324h4.648a2.331 2.331 0 0 0 2.315-2.324z" />
    </g>
  </svg>
);

export default DestinationIcon;
