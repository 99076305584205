import React, { useContext, useMemo, useState } from 'react';

import { InfoRounded, ReportProblemRounded } from '@mui/icons-material';
import { EditIcon } from 'icons';
import { OVCButton, getCalculatedBrightnessFromHex, useGetPaletteColors } from 'return-portal-ui';
import { twMerge } from 'tailwind-merge';
import { getColorContrast } from 'ui';
import { Heading } from 'ui/atoms';
import { useCourierIcon } from 'ui/hooks';

import TrackingPageContext from '../../../../Context/TrackingPageContext';
import { EDIT_MODE_TABS } from '../../../../Interfaces';
import { OutvioStatusCodes } from '../../../../Interfaces';
import { StepStatus } from '../../../../Interfaces/ITrackingData';
import Card from '../../../Common/Card';
import EditButton from '../../../Common/EditButton';
import ModalComponent from '../../Modals/DeliveryInfoModal/Modal';
import StatusStepsModal from '../../Modals/StatusStepsModal';
import CourierIcon from '../../Modals/StatusStepsModal/CourierIcon';
import StatusSteps from '../StatusSteps';

const OrderStatusV2 = ({ fullHeight }: { fullHeight?: boolean }) => {
  const {
    trackingData,
    handleChangeDeliveryAddress,
    portalSettings: {
      regionalSettings: {
        orderStatusInfo: { manageIncidentText },
        deliveryInfo: { changeInformationText },
        tracking,
      },
      isDeliveryInfoChangeable,
    },
    appSettings: { isEdit },
  } = useContext(TrackingPageContext);

  const { secondaryBg, primaryText, btnBg } = useGetPaletteColors();

  const { data = [] } = useCourierIcon();

  const isIncident = trackingData?.orderStatusInfo?.isIncident;
  const steps = trackingData?.orderStatusInfo?.steps;
  const courier = trackingData?.deliveryInfo?.courier;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeliveryInfoModalVisible, setIsDeliveryInfoModalVisible] = useState(false);

  const openModal = () => setIsModalVisible(true);
  const closeModal = () => setIsModalVisible(false);
  const openDeliveryInfoModal = () => setIsDeliveryInfoModalVisible(true);
  const closeDeliveryInfoModal = () => setIsDeliveryInfoModalVisible(false);

  const isButtonDisabled = useMemo(() => {
    if (isIncident) return false;
    return (
      steps?.findIndex(
        ({ displayCode, status }) =>
          displayCode === OutvioStatusCodes.PICKED_UP && status === StepStatus.PENDING,
      ) !== -1 || !courier
    );
  }, [isIncident, steps, courier]);
  if (!trackingData) return null;

  const courierName = data.find(
    (item) =>
      item?.integration?.toLowerCase() === trackingData?.deliveryInfo?.courier?.toLowerCase(),
  );

  const scrollBarColor = getCalculatedBrightnessFromHex(secondaryBg, 2);
  const buttonIconColor = getColorContrast(btnBg, true);

  return (
    <>
      <Card
        wrapperClassName={twMerge(fullHeight && 'md:h-full')}
        className={twMerge(
          'overflow-hidden flex flex-col w-full h-[377px] relative order-1',
          fullHeight ? 'md:h-full' : 'md:h-[329px]',
        )}
      >
        <div className="px-6 pt-6 flex items-center">
          {trackingData?.deliveryInfo?.courier && (
            <CourierIcon
              courier={trackingData.deliveryInfo.courier}
              className="ml-2 h-9 w-9 mr-4"
            />
          )}

          <Heading style={{ color: primaryText }} className={twMerge(!courierName?.name && 'ml-2')}>
            {courierName?.name || tracking?.titleTracking}
          </Heading>

          <div className="flex-1 flex items-center justify-end">
            {isDeliveryInfoChangeable && (handleChangeDeliveryAddress || isEdit) && (
              <OVCButton
                onClick={openDeliveryInfoModal}
                className="hidden md:flex mr-2"
                icon={<EditIcon width={24} height={24} className={buttonIconColor} />}
              >
                {changeInformationText}
              </OVCButton>
            )}

            {!isButtonDisabled && (
              <OVCButton
                theme={isIncident ? 'alert' : 'secondary'}
                disabled={isButtonDisabled}
                icon={
                  isIncident ? (
                    <ReportProblemRounded style={{ fontSize: 22 }} />
                  ) : (
                    <InfoRounded className="mx-1" style={{ fontSize: 18 }} />
                  )
                }
                onClick={() => openModal()}
              >
                {isIncident ? manageIncidentText : ''}
              </OVCButton>
            )}
          </div>
        </div>

        <div className="mt-6 mb-10 relative flex-1 overflow-hidden">
          <div
            style={{
              scrollbarColor: `${scrollBarColor} ${secondaryBg}`,
              scrollbarWidth: 'thin',
            }}
            className="snap-y snap-mandatory relative overflow-y-auto h-full pl-[34px] pr-10 pb-4"
          >
            <StatusSteps type="incidents" />
          </div>

          <div className="!z-10 absolute h-[24px] w-full top-0 right-2 pointer-events-none">
            <div
              style={{
                background: `linear-gradient(to bottom, ${secondaryBg} 0%, transparent)`,
              }}
              className="h-full "
            />
          </div>

          <div className="!z-10 absolute h-[24px] w-full bottom-0 right-2 pointer-events-none">
            <div
              style={{
                background: `linear-gradient(to top, ${secondaryBg} 0%, transparent)`,
              }}
              className="h-full "
            />
          </div>
        </div>
        {isDeliveryInfoChangeable && (handleChangeDeliveryAddress || isEdit) && (
          <div className="md:hidden p-6 w-full">
            <OVCButton
              onClick={openDeliveryInfoModal}
              className="w-full"
              icon={<EditIcon width={24} height={24} className={buttonIconColor} />}
            >
              {changeInformationText}
            </OVCButton>
          </div>
        )}

        {isEdit && <EditButton tab={EDIT_MODE_TABS.TRACKING} />}
      </Card>

      <StatusStepsModal isVisible={isModalVisible} handleClose={closeModal} />
      <ModalComponent handleClose={closeDeliveryInfoModal} isVisible={isDeliveryInfoModalVisible} />
    </>
  );
};

export default OrderStatusV2;
