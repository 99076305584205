export function formatFloat(input: string | number) {
  const inputCopy = input.toString();

  if (inputCopy.length < 2) {
    return inputCopy.replace(/[^0-9]/g, '');
  }

  let processed = inputCopy
    .replace(/[^0-9.]/g, '')
    .replace(/^(\d*\.)(.*)$/, (_, p1, p2) => p1 + p2.replace(/\./g, ''));

  if (!processed.includes('.')) {
    processed = processed.replace(/^0(0+)/, '0');

    if (processed[0] === '0') {
      processed = '0';
    }
  }

  const inputFirstCharacter = processed[0].replace(/[^0-9]/g, '');
  const inputRest = processed
    .slice(1)
    .replace(
      /^(\d*\.)(.*)$/,
      (_, p1, p2) => (inputFirstCharacter === '0' ? p1.replace(/[^.]/g, '') : p1) + p2,
    );

  return inputFirstCharacter + inputRest;
}

export function formatInteger(input: string) {
  let inputCopy = input.toString();
  inputCopy = inputCopy.replace(/[^0-9]/g, '');
  return inputCopy.replace(/^0+/, '');
}
