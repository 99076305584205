import { useQuery } from '@tanstack/react-query';
import { FIVE_MINUTE_CACHE } from 'ui/constants';

import { getOrdersPerWarehouse } from '../../../queries/getOrdersPerWarehouse';

export function useGetOrdersPerWarehouse(isEnabled = false) {
  return useQuery(['getOrdersPerWarehouse'], () => getOrdersPerWarehouse(), {
    cacheTime: FIVE_MINUTE_CACHE,
    staleTime: FIVE_MINUTE_CACHE,
    enabled: isEnabled,
  });
}
