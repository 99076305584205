export function NotAllowed({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em" {...props}>
      <g id="Icon" transform="translate(-785 -395)">
        <rect
          id="Rectangle_8024"
          data-name="Rectangle 8024"
          width="24"
          height="24"
          transform="translate(785 395)"
          fill="none"
        />
        <path
          id="Path_47060"
          data-name="Path 47060"
          d="M884.051,280.051l9.9,9.9M896,285a7,7,0,1,1-7-7A7,7,0,0,1,896,285Z"
          transform="translate(-92 122)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.6"
        />
      </g>
    </svg>
  );
}
