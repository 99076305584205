import convert from 'color-convert';
import { HSV } from 'color-convert/conversions';

import { ColorTypes } from '../../Interfaces/IGoogleMaps';

export const DEFAULT_DESATURATION = 30;
export const DEFAULT_BRIGHTEN = 30;

export const isBrightColor = (color: HSV): boolean => color[2] >= 50;

export const desaturate = (hex: string, desaturation?: number) => {
  const hsv = convert.hex.hsv(hex);
  const s = hsv[1] - (desaturation || DEFAULT_DESATURATION);
  hsv[1] = s >= 0 ? s : 0;
  return `#${convert.hsv.hex(hsv)}`;
};

export const brighten = (hex: string, bright?: number): string => {
  const hsv = convert.hex.hsv(hex);
  const v = hsv[2] + (bright || DEFAULT_BRIGHTEN);
  hsv[1] = v <= 100 ? v : 100;
  return `#${convert.hsv.hex(hsv)}`;
};

export const getColor = (type: ColorTypes, isCustom: boolean, color?: string): string => {
  if (type === ColorTypes.STROKE)
    return isBrightColor(convert.hex.hsv(color || ColorTypes.TEXT))
      ? ColorTypes.DARK_STROKE
      : ColorTypes.BRIGHT_STROKE;
  if (isCustom && color) return color;
  return type;
};
