import * as React from 'react';

import { Theme, makeStyles } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/RefreshRounded';
import WarningIcon from '@material-ui/icons/WarningRounded';
import { Language } from 'apptypes/interfaces';
import cn from 'classnames';

import OVButton from '../modules/CmsModule/components/Common/OVButton';

interface IProps {
  children: React.ReactNode;
  isRefresh?: boolean;
  isBoxView?: boolean;
  lang: Language;
  texts: any;
}

interface IStyleProps {
  isBoxView?: boolean;
}

interface IAllProps extends IProps, IStyleProps {}

const useStyles = makeStyles<Theme, IStyleProps>({
  container: {
    position: 'relative',
    width: '90%',
    maxWidth: '500px',
    padding: '40px 20px',
    backgroundColor: ({ isBoxView }) => (isBoxView ? '#e0f2f1' : ''),
    boxShadow: ({ isBoxView }) =>
      isBoxView ? '0 20px 25px -5px rgba(0,0,0,.1),0 10px 10px -5px rgba(0,0,0,.04)!important' : '',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',

    '& > h1': {
      fontSize: '30px',
      lineHeight: '30px',
      color: '#00363d',
      textTransform: 'uppercase',
      fontWeight: 700,
      textAlign: 'center',
    },
  },
  iconHolder: {
    color: '#cc0044',
    fontSize: '50px',
  },
  iconHolderWarning: {
    color: '#00363d',
  },
  content: {
    padding: '0 20px',
    textAlign: 'center',
    color: '#00363d',
    fontSize: '13px',
  },
});

const ErrorComp: React.FC<IAllProps> = ({
  children,
  isRefresh = false,
  lang,
  texts,
  isBoxView = true,
}) => {
  const classes = useStyles({ isBoxView });
  return (
    <div className={classes.container}>
      <div className={cn(classes.iconHolder, { [classes.iconHolderWarning]: isRefresh })}>
        {isRefresh ? (
          <RefreshIcon color="inherit" fontSize="inherit" />
        ) : (
          <WarningIcon color="inherit" fontSize="inherit" />
        )}
      </div>
      {isRefresh ? (
        <h1>{texts[lang]['providerless.header.refresh']}</h1>
      ) : (
        <h1>{texts[lang]['providerless.header.general']}</h1>
      )}

      <div className={classes.content}>{children}</div>
      <OVButton onClick={() => window.location.reload()} style={{ marginTop: '20px' }}>
        {texts[lang]['providerless.cta.refresh']}
      </OVButton>
    </div>
  );
};

export default ErrorComp;
