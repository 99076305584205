import { type SetStateAction, useCallback, useEffect, useState } from 'react';

import {
  type CustomDomain,
  CustomDomainStatus,
  CustomDomainType,
  useCreateCustomDomainByStore,
  useDeleteCustomDomainByStore,
  useGetCustomDomainsByStore,
} from 'data-layer';
import { useFormikContext } from 'formik';

export const useClosePortalDomainPopup = (
  setIsPortalDomainsPopupVisible: (value: SetStateAction<boolean>) => void,
) =>
  useCallback(() => {
    setIsPortalDomainsPopupVisible(false);
  }, []);

export const useDeleteCustomDomain = (storeBrandingId: string) => {
  const {
    mutateAsync: _deleteCustomDomain,
    isLoading: isDeleteCustomDomainLoading,
    error: deleteCustomDomainError,
  } = useDeleteCustomDomainByStore();
  const { refetch: refetchCustomDomains } = useGetCustomDomainsByStore(storeBrandingId);

  const deleteCustomDomain = useCallback(
    async (storeBrandingId: string, customDomainId: string) => {
      try {
        await _deleteCustomDomain({
          storeBrandingId,
          customDomainId,
        });
        refetchCustomDomains();
      } catch (error: any) {
        console.log(error?.message ?? error.toString());
      }
    },
    [_deleteCustomDomain, refetchCustomDomains],
  );

  return {
    deleteCustomDomain,
    isDeleteCustomDomainLoading,
    deleteCustomDomainError,
  };
};

export const useGenerateNsRecords = (
  storeBrandingId: string,
  setIsPortalDomainsPopupVisible: (value: SetStateAction<boolean>) => void,
) => {
  const { refetch: refetchCustomDomains } = useGetCustomDomainsByStore(storeBrandingId);
  const {
    mutateAsync: createCustomDomain,
    isLoading: isCreateCustomDomainLoading,
    error: createCustomDomainError,
  } = useCreateCustomDomainByStore();

  const generateNsRecords = useCallback(
    async (customDomainStatus: CustomDomainStatus | null, customDomain: string) => {
      if (customDomainStatus === null) {
        try {
          await createCustomDomain({
            storeBrandingId,
            data: {
              name: customDomain,
              type: CustomDomainType.Tracking,
            },
          });
          refetchCustomDomains();
          setIsPortalDomainsPopupVisible(true);
        } catch (error: any) {
          console.log(error?.message ?? error.toString());
        }
      } else if (customDomainStatus === CustomDomainStatus.Created) {
        setIsPortalDomainsPopupVisible(true);
      }
    },
    [createCustomDomain, refetchCustomDomains],
  );

  return {
    generateNsRecords,
    isCreateCustomDomainLoading,
    createCustomDomainError,
  };
};

export const useCustomDomainState = (
  storeBrandingId: string,
  customDomainType: CustomDomainType,
) => {
  const { data: customDomainsData } = useGetCustomDomainsByStore(storeBrandingId);
  const [customDomainData, setCustomDomainData] = useState<CustomDomain | null>(null);
  const [customDomainStatus, setCustomDomainStatus] = useState<CustomDomainStatus | null>(null);

  useEffect(() => {
    const customDomainData = (customDomainsData?.filter(
      ({ type }) => type === customDomainType,
    )?.[0] ?? null) as CustomDomain | null;
    const customDomainStatus = customDomainData?.status ?? null;
    setCustomDomainData(customDomainData);
    setCustomDomainStatus(customDomainStatus);
  }, [customDomainsData, customDomainType]);

  return {
    customDomainData,
    customDomainStatus,
  };
};

export const useOutvioSubdomainResize = () => {
  const { values, dirty } = useFormikContext<{
    trackingSlug: string;
  }>();

  useEffect(() => {
    if (!dirty && values.trackingSlug.length > 0) {
      const element = document.querySelector('input[name="trackingSlug"]');
      element?.dispatchEvent(new Event('input', { bubbles: true }));
    }
  }, [values.trackingSlug, dirty]);
};
