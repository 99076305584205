import { InputHTMLAttributes, type ReactNode, useEffect } from 'react';

import { type FieldValues, type Path, useFormContext, useWatch } from 'react-hook-form';

import { twMerge } from 'tailwind-merge';
import { Copy, ErrorMessage } from 'ui/atoms';

export interface _RadioButtonProps {
  className?: string;
  copyClassName?: string;
  name: string;
  label: ReactNode;
  value: string | number | boolean;
  fieldValue: string | number | boolean;
  isError?: boolean;
  errorMessage?: string;
  isDisabled?: boolean;
  register?: (name: string) => any;
}

export type RadioButtonProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'value'> &
  _RadioButtonProps;

export function RadioButton({
  className,
  copyClassName,
  name,
  label,
  value,
  fieldValue,
  isError = false,
  errorMessage,
  register,
  isDisabled = false,
  ...otherProps
}: RadioButtonProps) {
  return (
    <div className={twMerge(className, isDisabled && 'pointer-events-none')}>
      <div className="flex items-center">
        <label data-testid="outvioui--radiobutton">
          <div
            className={twMerge(
              'w-5 h-5 block relative cursor-pointer select-none',
              otherProps.disabled && 'cursor-not-allowed',
            )}
          >
            <input
              data-testid="outvioui--radiobutton-input"
              className="absolute opacity-0 cursor-pointer h-0 w-0 pointer-events-none"
              type="radio"
              {...otherProps}
              {...(register ? register(name) : {})}
              value={value}
            />
            <div
              className={twMerge(
                'absolute inset-0 h-full w-full rounded-full pointer-events-none',
                fieldValue === value ? 'bg-primary bg-opacity-20' : 'bg-v2blueGray-100',
              )}
            />
            {fieldValue === value && (
              <div className="block absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-2 h-2 bg-primary rounded-[50%]" />
            )}
          </div>
        </label>

        <Copy
          as="span"
          className={twMerge(
            'inline-block ml-2 text-v2blueGray-700',
            copyClassName,
            otherProps.disabled && 'text-v2blueGray-400',
          )}
        >
          {label}
        </Copy>
      </div>
      {isError && <ErrorMessage className="mt-2">{errorMessage}</ErrorMessage>}
    </div>
  );
}

export type _RadioButtonRhfProps<T> = {
  copyClassName?: string;
  name: Path<T>;
  label: ReactNode;
  value: string;
  isDisabled?: boolean;
};

export type RadioButtonRhfProps<T> = InputHTMLAttributes<HTMLInputElement> &
  _RadioButtonRhfProps<T>;

export function RadioButtonRhf<T extends FieldValues>({
  name,
  className,
  copyClassName,
  label,
  value,
  ...otherProps
}: RadioButtonRhfProps<T>) {
  const fieldValue = useWatch({ name });
  const {
    register,
    unregister,
    getFieldState,
    formState: { errors },
  } = useFormContext<T>();

  useEffect(() => {
    return () => {
      const { isDirty } = getFieldState(name as Path<T>);
      unregister(name as Path<T>, { keepDirty: true, keepDefaultValue: true, keepValue: isDirty });
    };
  }, [name, unregister, getFieldState]);

  return (
    <RadioButton
      className={className}
      copyClassName={copyClassName}
      register={register as (name: string) => void}
      label={label}
      value={value}
      fieldValue={fieldValue}
      name={name}
      isError={!!errors[name]}
      errorMessage={errors[name]?.message as string}
      {...otherProps}
    />
  );
}

export default RadioButtonRhf;
