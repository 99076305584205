import React, { FC, useContext, useEffect, useState } from 'react';

import { ReportProblemRounded } from '@mui/icons-material';
import { OVCButton } from 'return-portal-ui';

import TrackingPageContext from '../../../../Context/TrackingPageContext';
import { OutvioStatusCodes } from '../../../../Interfaces';

interface IncidentButton {
  type: 'header' | 'bottom';
  handleToggleModals(val: boolean): void;
}

const IncidentButton = ({
  link,
  showClassName,
  manageIncidentText,
}: {
  link: Promise<string | null>;
  showClassName: string;
  manageIncidentText: string;
}) => {
  const [trackingLink, setTrackingLink] = useState<string | null>(null);
  useEffect(() => {
    const getLink = async () => {
      setTrackingLink(await link);
    };
    if (!trackingLink) {
      getLink();
    }
  }, []);

  const handleOnClick = () => {
    if (trackingLink) {
      window.open(trackingLink, '_blank');
    }
  };

  if (!trackingLink) return null;

  return (
    <div className={`${showClassName} mt-8 px-6`}>
      <OVCButton
        disabled={!trackingLink}
        className="w-full"
        icon={<ReportProblemRounded style={{ fontSize: 20 }} />}
        onClick={handleOnClick}
        theme="alert"
      >
        {manageIncidentText}
      </OVCButton>
    </div>
  );
};

const IncidentButtonContainer: FC<IncidentButton> = (props) => {
  const { type, handleToggleModals } = props;
  const showClassName = type === 'header' ? 'hidden md:block' : 'md:hidden';
  const {
    portalSettings: {
      regionalSettings: {
        orderStatusInfo: { manageIncidentText },
        deliveryInfo: { changeInformationText },
      },
      isDeliveryInfoChangeable,
    },
    trackingData,
  } = useContext(TrackingPageContext);
  if (!trackingData) return null;

  const {
    orderStatusInfo: { isIncident, currentStep },
    deliveryInfo: { canUserEdit },
    trackingLink,
  } = trackingData;

  const isDeliveryChange =
    currentStep.code === OutvioStatusCodes.BAD_ADDRESS && isDeliveryInfoChangeable && canUserEdit;

  if (!isIncident) return null;

  if (isDeliveryChange) {
    return (
      <div className={`${showClassName} mt-8 px-6 w-full`}>
        <OVCButton
          className="w-full"
          icon={<ReportProblemRounded style={{ fontSize: 20 }} />}
          onClick={() => handleToggleModals(true)}
          theme="alert"
        >
          {changeInformationText}
        </OVCButton>
      </div>
    );
  }

  return (
    <IncidentButton
      manageIncidentText={manageIncidentText}
      showClassName={showClassName}
      link={trackingLink}
    />
  );
};

export default IncidentButtonContainer;
