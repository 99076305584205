import { useQuery } from '@apollo/client';
import { QueryHookOptions } from '@apollo/client/react/types/types';
import { Query } from 'app-types/desk';

import { GET_SUPPORT_TICKET_MESSAGES_QUERY } from './queries';

type ResultData = { messages: Query['Message_FindAllBySupportTicketId'] };

export function useSupportTicketMessages(
  ticketId?: string,
  options?: QueryHookOptions<ResultData>,
) {
  return useQuery<ResultData>(GET_SUPPORT_TICKET_MESSAGES_QUERY, {
    variables: { ticketId },
    skip: !ticketId,
    pollInterval: 120000, // each 2 mins
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'network-only',
    ...options,
    context: { ...options?.context, clientName: 'desk' },
  });
}
