import React, { FC } from 'react';

import { RenderLeafProps } from 'slate-react';

import { CustomLeaf, SlateLeafType } from '../../../Interfaces/IRichTextInput';

const SlateLeaf: FC<RenderLeafProps> = (props) => {
  const { attributes, children, leaf } = props;
  const { type } = leaf as CustomLeaf;
  /**
   * We need to do prop-spreading for this.
   * TODO: LINKS
   */

  /* eslint-disable react/jsx-props-no-spreading */
  switch (type) {
    case SlateLeafType.LINK:
    case SlateLeafType.TEXT:
    default:
      return <span {...attributes}>{children}</span>;
  }
  /* eslint-enable */
};

export default SlateLeaf;
