import React, { useContext, useMemo } from 'react';

import { IntlProvider } from 'localization';

import messages from '../i18n-customer';
import { LocalePage } from '../types/CustomerGeneral';
import OVCLangSwitcherContext from './OVCLangSwitcherContext';

const PAGES: LocalePage[] = [
  'customerLanding',
  'account',
  'customerOrder',
  'countries',
  'general',
  'ovc',
];

interface IOVCI18NProvider {
  children: React.ReactNode;
}

const OVCI18NProvider = ({ children }: IOVCI18NProvider): JSX.Element => {
  const context = useContext(OVCLangSwitcherContext);

  const compiledMessages = useMemo(
    () =>
      PAGES.reduce(
        (acc, page) =>
          Object.assign({}, acc, messages[page]['en-EN'], messages[page][context.appLang]),
        {},
      ),
    [context.appLang],
  );

  return (
    // @ts-ignore
    <IntlProvider locale={context.appLang} messages={compiledMessages} textComponent="span">
      {children}
    </IntlProvider>
  );
};

export default OVCI18NProvider;
