import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { IUser } from 'app-types';

import { GET_ALL_AGENT_QUERY } from '../../constants';
import { getSupportAgents } from '../../utils';

export function useGetSupportAgentsList(
  options?: UseQueryOptions<Array<IUser> | undefined, unknown>,
) {
  return useQuery<Array<IUser> | undefined, unknown>(
    [GET_ALL_AGENT_QUERY],
    () => getSupportAgents(),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      ...options,
    },
  );
}
