export function Cart({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 -960 960 960"
      fill="currentColor"
      {...props}
    >
      <path d="M286.154-97.694q-29.153 0-49.576-20.422-20.423-20.423-20.423-49.577 0-29.153 20.423-49.576 20.423-20.423 49.576-20.423 29.154 0 49.577 20.423t20.423 49.576q0 29.154-20.423 49.577-20.423 20.422-49.577 20.422Zm387.692 0q-29.154 0-49.577-20.422-20.423-20.423-20.423-49.577 0-29.153 20.423-49.576 20.423-20.423 49.577-20.423 29.153 0 49.576 20.423 20.423 20.423 20.423 49.576 0 29.154-20.423 49.577-20.423 20.422-49.576 20.422Zm-387.692-200q-39.999 0-60.115-34.499-20.115-34.5-1.423-68.884l57.078-102.616-145.539-306.308H89.001q-12.385 0-20.692-8.615T60.002-840q0-12.769 8.615-21.384T90-869.999h61.153q10.231 0 19.077 5.423 8.846 5.423 13.462 15.269l28.153 59.308h555.383q27.769 0 39.115 20.384 11.346 20.385-.808 43.154L677.384-494.309q-9.847 17.693-25.731 27.154-15.884 9.461-35.807 9.461H324l-46.308 84.616q-3.077 4.616-.192 10.001t8.654 5.385h428.692q12.384 0 20.691 8.615 8.308 8.615 8.308 21.384t-8.615 21.384q-8.615 8.615-21.384 8.615H286.154Z" />
    </svg>
  );
}
