import { Product } from '../types/Product';

export const groupProducts = (
  products: Product[],
  groupProductsBy: 'sku' | 'barcode' = 'sku',
): Product[] => {
  const bundled: Product[] = [];

  const PRIMARY_KEY = groupProductsBy;

  products.forEach((product) => {
    const indexGroupedByKey = bundled.findIndex((b) => {
      if (typeof b[PRIMARY_KEY] === 'string' && (b[PRIMARY_KEY] || '').length > 0) {
        return b[PRIMARY_KEY] === product[PRIMARY_KEY];
      }
      return false;
    });
    const indexGroupedByName = bundled.findIndex(
      (b) => b.name === product.name && b.variant === product.variant,
    );

    if (indexGroupedByKey > -1) {
      bundled[indexGroupedByKey].quantity += parseInt(String(product.quantity));
    } else if (
      typeof product[PRIMARY_KEY] !== 'string' &&
      (product[PRIMARY_KEY] || '').length === 0 &&
      indexGroupedByName > -1
    ) {
      bundled[indexGroupedByName].quantity += parseInt(String(product.quantity));
    } else {
      // Add new product for bundle
      bundled.push({
        ...product,
        quantity: parseInt(String(product.quantity)),
        price: parseFloat(String(product.price)),
        discountPrice:
          typeof product.discountPrice === 'number' &&
          !Number.isNaN(product.discountPrice) &&
          product.discountPrice > 0
            ? parseFloat(String(product.discountPrice))
            : undefined,
      });
    }
  });

  return bundled;
};

export default groupProducts;
