import React, { FC, useState } from 'react';

import { DropEvent, ErrorCode, FileError, FileRejection } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { DeleteRounded } from '@mui/icons-material';

import FileUpload from '../Common/Inputs/FileUpload';
import Loader from '../Common/Loader';

const IMAGE_SIZE_CAP = 5000000;

const sizeValidator = <T extends File>(file: T): FileError | FileError[] | null =>
  file.size >= IMAGE_SIZE_CAP
    ? { message: 'Image is too large', code: ErrorCode.FileTooLarge }
    : null;

interface ImageUploader {
  imageSrc?: string;
  uploadText?: string;
  uploadDescription?: string;
  handleDeleteImage(): void;
  handleUploadImage<T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent,
  ): Promise<boolean>;
  className?: string;
  style?: React.CSSProperties;
}

const ImageUploader: FC<ImageUploader> = (props) => {
  const {
    imageSrc,
    handleUploadImage,
    handleDeleteImage,
    uploadDescription,
    uploadText,
    className,
    style,
  } = props;
  const [isUploading, setIsUploading] = useState(false);
  const [errors, setErrors] = useState<FileError[]>([]);
  const internalHandleUpload = (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
    event: DropEvent,
  ) => {
    setIsUploading(true);
    const err: FileError[] = [];
    fileRejections.map((rejection) => rejection.errors.map((error) => err.push(error)));
    setErrors(err);
    handleUploadImage(acceptedFiles, fileRejections, event).finally(() => setIsUploading(false));
  };
  const { t } = useTranslation('trackingPage', { keyPrefix: 'general' });
  if (isUploading) {
    return (
      <div className="w-full h-[100px] flex items-center justify-center">
        <Loader className="w-40" />
      </div>
    );
  }
  if (imageSrc) {
    return (
      <button
        type="button"
        aria-label={t('deleteImage')}
        onClick={() => handleDeleteImage()}
        className={className || 'h-[100px] bg-center bg-contain bg-no-repeat w-full rounded'}
        style={{ backgroundImage: `url(${imageSrc})`, ...style }}
      >
        <div className="opacity-0 hover:opacity-100 transition-opacity duration-100 w-full h-full flex items-center justify-center bg-redTP-200 bg-opacity-25 rounded border-redTP-400 border-2 border-dotted">
          <div className="bg-red text-white flex items-center py-1 px-3 rounded space-x-3">
            <DeleteRounded className="text-white" fontSize="large" />
            <span className="font-semibold">{t('deleteImage')}</span>
          </div>
        </div>
      </button>
    );
  }

  return (
    <FileUpload
      text={uploadText}
      description={uploadDescription}
      handleUpload={(acceptedFiles, fileRejections, event) =>
        internalHandleUpload(acceptedFiles, fileRejections, event)
      }
      html={{ height: '100px' }}
      accept={{
        'image/png': ['.png', '.jpg'],
        'image/jpeg': ['.jpg', '.jpeg'],
        'image/gif': ['.gif'],
      }}
      validator={sizeValidator}
      errors={errors}
    />
  );
};

export default ImageUploader;
