import React from 'react';

interface IIconLocationCheck {
  width?: number;
  height?: number;
  color?: string;
}

const IconLocationCheck = ({ color = '#00363d', width = 20, height = 20 }: IIconLocationCheck) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.833 17.847" width={width} height={height}>
    <g transform="translate(0 0)" fill={color}>
      <path
        d="M11.417,2A6.424,6.424,0,0,0,5,8.417c0,3.823,4.052,9.093,5.711,11.1a.91.91,0,0,0,1.4,0c1.668-2.007,5.72-7.278,5.72-11.1A6.424,6.424,0,0,0,11.417,2Zm3.951,5.647-4.7,4.7a.913.913,0,0,1-1.292,0l-1.9-1.9a.924.924,0,0,1,0-1.3h0a.924.924,0,0,1,1.3,0L10.023,10.4l4.052-4.052a.917.917,0,1,1,1.293,1.3Z"
        transform="translate(-5 -2)"
      />
    </g>
  </svg>
);

export default IconLocationCheck;
