import React, { FC } from 'react';

import _get from 'lodash.get';

import { FieldProps } from 'formik';

import { ILanguage } from '../../../Interfaces';
import { getFieldError } from '../../../Utils/getFieldErrors';

interface LanguageInput extends FieldProps {
  lang: ILanguage;
}

const LanguageInput: FC<LanguageInput> = (props) => {
  const {
    field: { name, value, onChange, onBlur },
    lang: { ISOCode },
    form: { initialValues, errors },
  } = props;
  const placeholder = _get(initialValues, name);
  const error = getFieldError(errors, name);

  return (
    <div className="flex bg-slate-100 rounded-md text-sm">
      <label htmlFor={name} className="p-4 font-bold text-slate-800 text-opacity-80 min-w-[50px]">
        <span>{ISOCode.substring(0, 2).toUpperCase()}</span>
      </label>
      <div className="h-full w-[1px] bg-slate-200 py-4 my-auto" />
      <input
        type="text"
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        className={`block font-semibold w-full py-3 px-4 text-slate-900 text-opacity-80 bg-opacity-10 bg-slate-100 rounded-md ${error}`}
        id={name}
        placeholder={placeholder}
      />
    </div>
  );
};

export default LanguageInput;
