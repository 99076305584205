import { CustomDomain } from '../constants';
import request from '../utils/request';

type CustomDomainsResponse = {
  success: boolean;
  data: Array<CustomDomain>;
};

export const getCustomDomainsByStore = (
  storeBrandId: string,
): Promise<Array<CustomDomain> | undefined> =>
  request<CustomDomainsResponse | undefined>(
    `${process.env.OUTVIO_API_URL}/portal/${storeBrandId}/custom-domains`,
    {
      method: 'GET',
      secure: true,
    },
  ).then((response) => {
    const { data } = (response as CustomDomainsResponse | undefined) ?? {};
    return data ?? [];
  });
