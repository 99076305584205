import React, { HTMLAttributes } from 'react';

import { Collapse } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/WarningRounded';
import { FormattedMessage } from 'localization';
import { Copy } from 'ui/atoms';

interface IOVCAlert extends HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  type?: 'danger' | 'info' | 'warning';
  isOpen?: boolean;
  style?: any;
  textMessage?: string;
}

const OVCAlert = ({ children, textMessage, isOpen = true }: IOVCAlert) => {
  return (
    <Collapse in={isOpen} style={{ width: '100%' }}>
      <div className="px-2 py-[6px] rounded-[10px] bg-v2orange-200/20 text-v2orange-200">
        <div className="flex items-center justify-center gap-2 text-v2orange">
          <WarningIcon color="inherit" fontSize="medium" />
          <Copy noColor>{textMessage ? <FormattedMessage id={textMessage} /> : children}</Copy>
        </div>
      </div>
    </Collapse>
  );
};

export default OVCAlert;
