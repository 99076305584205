export function EditNoFill({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 -960 960 960"
      fill="currentColor"
      {...props}
    >
      <path d="M206.154-200h49.461l370.387-370.386-49.461-49.462-370.387 370.387V-200Zm548.152-413.77L619.309-747.537l52.538-52.538q17.231-17.23 42.461-17.23 25.23 0 42.46 17.23l49.461 49.461q17.231 17.23 17.846 41.845.615 24.615-16.615 41.845l-53.154 53.154ZM182.309-140.001q-15.461 0-25.807-10.347-10.346-10.346-10.346-25.807v-83.767q0-7.231 2.615-13.769 2.615-6.539 8.231-12.154l418.923-418.924 134.998 134.998-418.924 418.923q-5.615 5.616-12.153 8.231-6.539 2.616-13.77 2.616h-83.767Zm418.77-455.308-24.538-24.539 49.461 49.462-24.923-24.923Z" />
    </svg>
  );
}
