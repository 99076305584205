import React, { FC, ReactNode, useContext, useEffect, useRef } from 'react';

import EditModalContext from '../../../Context/EditModalContext';
import { EDIT_MODE_TABS } from '../../../Interfaces/IEditModal';
import { Features } from '../../../Interfaces/IFeatures';
import ShouldUpgradeContainer from '../ShouldUpgradeContainer';
import CustomTooltip from './Tooltip';

interface Base {
  title?: string | ReactNode;
  id: EDIT_MODE_TABS;
  children?: React.ReactNode;
  helpDescription?: string;
}

interface UseBase {
  id: EDIT_MODE_TABS;
  features: Features;
}

/**
 * TODO: Implement a mapping of restrictions for all features (looking at map here).
 * @param id
 * @param features
 */
const useBase = ({ id, features }: UseBase) => {
  const shouldUpgrade = () => {
    if (id === EDIT_MODE_TABS.CUSTOM_URL) {
      return !features.customDomains;
    }
    return false;
  };
  return { shouldUpgrade };
};

const Base: FC<Base> = (props) => {
  const { title, children, id, helpDescription } = props;
  const { addModuleTopValue, upgradeButton, features } = useContext(EditModalContext);
  const { shouldUpgrade } = useBase({ id, features });
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => addModuleTopValue({ id, top: ref.current?.offsetTop || 0 }), []);

  return (
    <div className="flex flex-col pt-8 first:pt-0" ref={ref}>
      {title && (
        <div className="flex items-center mb-8 scroll-mt-[200px]">
          <h2 className="text-cyan-800 text-lg font-bold">{title}</h2>
          {helpDescription && <CustomTooltip text={helpDescription} />}
        </div>
      )}
      {!shouldUpgrade() && children}
      {shouldUpgrade() && <ShouldUpgradeContainer>{upgradeButton}</ShouldUpgradeContainer>}
    </div>
  );
};

export default Base;
