import { useMutation } from '@apollo/client';
import { BrandObject, Query } from 'app-types/returns';

import { BRAND_SETTINGS_MUTATION } from '../gql/mutations';

export function useUpdatePortalBrandSettings() {
  const [mutate, { loading, error }] = useMutation<Query>(BRAND_SETTINGS_MUTATION);

  return {
    updateBrandingSettings: async (id: string, updateBrandInput: Partial<BrandObject>) => {
      const { data } = await mutate({
        variables: { id, updateBrandInput },
      });
      return data;
    },
    loading,
    error,
  };
}
