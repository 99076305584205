import React, { FC, useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Field } from 'formik';

import EditModalContext from '../../../Context/EditModalContext';
import { BannerModes } from '../../../Interfaces';
import { EDIT_MODE_TABS } from '../../../Interfaces/IEditModal';
import Toggle from '../../Common/Inputs/Toggle';
import BannerUploader from '../BannerUploader';
import LanguageSelector from '../LanguageSelector';
import Base from './Base';

const Banner: FC = () => {
  const {
    formData: { isBannerDisplay, regionalSettings },
  } = useContext(EditModalContext);
  const { t } = useTranslation('trackingPage', { keyPrefix: 'editMode.modules.banner' });
  const languages = regionalSettings.map((setting) => setting.language);
  const [selectedLanguage, setSelectedLanguage] = useState(languages[0]);

  return (
    <Base id={EDIT_MODE_TABS.BANNER} title={t('title')}>
      <div className="flex">
        <Field component={Toggle} name="isBannerDisplay" type="checkbox" />
        <span className="text-cyan-800 text-sm font-semibold ml-6">{t('uploadToOrdersPage')}</span>
      </div>
      <div className={`${isBannerDisplay ? '' : 'hidden'} ml-[60px]`}>
        <span className="text-sm font-light text-slate-900 text-opacity-80 mb-6 block">
          {t('whereToDisplayBanner')}
        </span>

        <div className="mb-4">
          <LanguageSelector
            languages={languages}
            selectedLanguage={selectedLanguage}
            setSelectedLanguage={setSelectedLanguage}
          />
        </div>
        <BannerUploader selectedLanguage={selectedLanguage} selectedMode={BannerModes.HORIZONTAL} />
      </div>
    </Base>
  );
};

export default Banner;
