/** @type {'€' | '£' | 'zł' | '$'} */
export type CurrencyValid = '€' | '£' | 'zł' | '$';

/** @type {'EUR' | 'PLN' | 'GBP' | 'USD'} */
export type CurrencyValidText = 'EUR' | 'PLN' | 'GBP' | 'USD';

/**
 * convert text currency to the symbol
 * @param text
 */
function convertTextCurrencyToSymbol(text?: CurrencyValidText): CurrencyValid | undefined {
  switch (text?.toUpperCase()) {
    case 'PLN':
      return 'zł';
    case 'GBP':
      return '£';
    case 'EUR':
      return '€';
    case 'USD':
      return '$';
  }
}

/**
 * convert symbol currency to the text
 * @param text
 */
function convertTextSymbolToText(text?: CurrencyValid): CurrencyValidText | undefined {
  switch (text) {
    case 'zł':
      return 'PLN';
    case '£':
      return 'GBP';
    case '€':
      return 'EUR';
    case '$':
      return 'USD';
  }
}

export { convertTextCurrencyToSymbol, convertTextSymbolToText };
