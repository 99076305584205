export function Download({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 -960 960 960"
      fill="currentColor"
      {...props}
    >
      <path d="M480-328.462 309.233-499.229l42.153-43.384 98.615 98.615v-336.001h59.998v336.001l98.615-98.615 42.153 43.384L480-328.462ZM252.309-180.001q-30.308 0-51.308-21t-21-51.308v-108.46H240v108.46q0 4.616 3.846 8.463 3.847 3.846 8.463 3.846h455.382q4.616 0 8.463-3.846 3.846-3.847 3.846-8.463v-108.46h59.999v108.46q0 30.308-21 51.308t-51.308 21H252.309Z" />
    </svg>
  );
}
