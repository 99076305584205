export const WeightIcon = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
    fill="currentColor"
    {...props}
  >
    <path d="M480-659.999q17 0 28.5-11.5t11.5-28.5q0-17-11.5-28.5t-28.5-11.5q-17 0-28.5 11.5t-11.5 28.5q0 17 11.5 28.5t28.5 11.5Zm90.691 0h81.924q27.307 0 47.192 17.885 19.884 17.884 24.115 44.577l53.538 375.382q4.615 32.539-16.769 57.346-21.385 24.808-54.923 24.808H254.232q-33.538 0-54.923-24.808-21.384-24.807-16.769-57.346l53.538-375.382q4.231-26.693 24.115-44.577 19.885-17.885 47.192-17.885h81.924q-4.154-9.23-6.731-19.115-2.577-9.885-2.577-20.885 0-41.922 29.038-70.96 29.039-29.038 70.961-29.038t70.961 29.038q29.038 29.038 29.038 70.96 0 11-2.577 20.885-2.577 9.885-6.731 19.115Z" />
  </svg>
);

export default WeightIcon;
