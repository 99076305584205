import tn from 'tinycolor2';

const opacityToSolidColor = (hex: string, desiredOpacity: number, backgroundHex: string) => {
  // validate input
  if (hex.length !== 7 || backgroundHex.length !== 7 || desiredOpacity < 0 || desiredOpacity > 1) {
    throw new Error('Invalid inputs to color conversion');
  }

  const baseColor = tn(hex).toRgb();
  const bgColor = tn(backgroundHex).toRgb();

  const finalColor = tn({
    r: Math.floor((1 - desiredOpacity) * bgColor.r + desiredOpacity * baseColor.r),
    g: Math.floor((1 - desiredOpacity) * bgColor.g + desiredOpacity * baseColor.g),
    b: Math.floor((1 - desiredOpacity) * bgColor.b + desiredOpacity * baseColor.b),
  });

  return finalColor.toHexString();
};

export default opacityToSolidColor;
