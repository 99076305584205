import { useMutation } from '@apollo/client';
import { type Mutation, TicketSetReviewInput } from 'app-types/desk';

import { TICKET_SET_SUPPORT_REVIEW } from './mutations';

export function useTicketSetSupportReview() {
  return useMutation<Mutation, { input: TicketSetReviewInput }>(TICKET_SET_SUPPORT_REVIEW, {
    context: { clientName: 'desk' },
  });
}
