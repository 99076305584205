import { useMutation } from '@apollo/client';
import { Mutation } from 'app-types/core';

import { BrandUpdateSlugMutation } from '../gql/core/mutations';

export function useUpdateBrandSlug() {
  const [mutate, { loading, error }] = useMutation<{
    Brand_updateSlug: Mutation['Brand_updateSlug'];
  }>(BrandUpdateSlugMutation, { context: { clientName: 'core' } });

  return {
    updateSlug: async (input: { id: string; slug: string }) => {
      const { data } = await mutate({
        variables: { input },
      });
      return data;
    },
    loading,
    error,
  };
}
