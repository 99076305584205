import { useEffect } from 'react';

export function useReloadTabsOnImpersonate() {
  useEffect(() => {
    function checkTokenChange(event: StorageEvent) {
      if (event.key !== 'impersonateToken') return;
      if (event.oldValue !== event.newValue) {
        window.location.reload();
      }
    }

    window.addEventListener('storage', checkTokenChange);

    return () => {
      window.removeEventListener('storage', checkTokenChange);
    };
  }, []);
}
