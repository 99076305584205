export function PendingPayment({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <g id="Group_6896" transform="translate(-991 -130)">
        <g id="Group_5333" transform="translate(-145 -119)">
          <rect
            id="Rectangle_6034"
            width="24"
            height="24"
            transform="translate(1136 249)"
            fill="none"
          />
          <g id="credit_score_FILL0_wght300_GRAD0_opsz24" transform="translate(1135.2 249.5)">
            <path
              id="Path_23171"
              d="M4,8.4H20V6.3c0-.1,0-.1-.1-.2s-.1-.1-.2-.1H4.3c-.1,0-.1,0-.2.1s-.1.1-.1.2ZM2.5,6.3A2.106,2.106,0,0,1,3,5a2.106,2.106,0,0,1,1.3-.5H19.7A2.106,2.106,0,0,1,21,5a1.656,1.656,0,0,1,.5,1.3v5.3H4v6.1c0,.1,0,.1.1.2s.1.1.2.1h7.6a.7.7,0,1,1,0,1.4H4.3A2.106,2.106,0,0,1,3,18.9a2.106,2.106,0,0,1-.5-1.3C2.5,17.7,2.5,6.3,2.5,6.3ZM4,6.3v0Z"
            />
            <path
              id="Path_23172"
              d="M21.8,13.8a4.224,4.224,0,0,0-3.2-1.3A4.44,4.44,0,0,0,14.1,17a4.44,4.44,0,0,0,4.5,4.5A4.44,4.44,0,0,0,23.1,17,4.224,4.224,0,0,0,21.8,13.8ZM20.6,19a.5.5,0,0,1-.6,0l-1.5-1.5c-.1-.1-.2-.2-.2-.3s-.1-.2-.1-.3V14.6a.4.4,0,1,1,.8,0v2.3l1.5,1.5a.367.367,0,0,1,.1.3A.187.187,0,0,1,20.6,19Z"
            />
          </g>
        </g>
      </g>{' '}
    </svg>
  );
}
