export function Warning({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <g transform="translate(-422 -232)">
        <path
          d="M3.425,20.5a.855.855,0,0,1-.457-.124.878.878,0,0,1-.315-.328.955.955,0,0,1-.129-.437.816.816,0,0,1,.127-.467L11.213,4.356a.9.9,0,0,1,.347-.342.928.928,0,0,1,.879,0,.9.9,0,0,1,.347.342l8.561,14.788a.815.815,0,0,1,.127.467.955.955,0,0,1-.129.438.878.878,0,0,1-.315.328.855.855,0,0,1-.457.124ZM12,17.808a.793.793,0,1,0-.575-.232A.782.782,0,0,0,12,17.808Zm0-2.615a.736.736,0,0,0,.75-.75v-3.5a.75.75,0,1,0-1.5,0v3.5a.737.737,0,0,0,.75.75Z"
          transform="translate(422 231.096)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
