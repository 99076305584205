import React, { FC, useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { Field } from 'formik';

import EditModalContext from '../../../Context/EditModalContext';
import { OutvioStatusCodes } from '../../../Interfaces';
import { EDIT_MODE_TABS } from '../../../Interfaces/IEditModal';
import { ILanguageInputInfo, LanguageInputTypes } from '../../../Interfaces/ILanguageInputInfo';
import Toggle from '../../Common/Inputs/Toggle';
import LanguageInputCard from '../LanguageInputCard';
import LanguageInputInfo from '../LanguageInputInfo';
import Base from './Base';

export const useTracking = () => {
  const { t } = useTranslation('trackingPage', { keyPrefix: 'editMode.inputs' });
  const trackingInputs: ILanguageInputInfo[] = [
    {
      title: t('tracking.titleTracking'),
      objectKey: 'tracking.titleTracking',
      type: LanguageInputTypes.TEXT,
    },
    {
      title: t('orderStatusInfo.moreInfoText'),
      objectKey: 'orderStatusInfo.moreInfoText',
      type: LanguageInputTypes.TEXT,
    },
    {
      title: t('tracking.titleCourier'),
      objectKey: 'tracking.titleCourier',
      type: LanguageInputTypes.TEXT,
    },
    {
      title: t('tracking.titleTrackingNumber'),
      objectKey: 'tracking.titleTrackingNumber',
      type: LanguageInputTypes.TEXT,
    },

    {
      title: t('orderStatusInfo.manageIncidentText'),
      objectKey: 'orderStatusInfo.manageIncidentText',
      type: LanguageInputTypes.TEXT,
    },
    {
      title: t('tracking.buttonPackage'),
      objectKey: 'tracking.buttonPackage',
      type: LanguageInputTypes.TEXT,
    },
    {
      title: t('tracking.titleMultiShipments'),
      objectKey: 'tracking.titleMultiShipments',
      type: LanguageInputTypes.TEXT,
    },
    {
      title: t('tracking.buttonShipment'),
      objectKey: 'tracking.buttonShipment',
      type: LanguageInputTypes.TEXT,
    },
  ];

  const deliveryInfoModalStart: ILanguageInputInfo[] = [
    {
      title: t('deliveryInfo.changeInformationText'),
      objectKey: 'deliveryInfo.changeInformationText',
      type: LanguageInputTypes.TEXT,
    },
    {
      title: t('deliveryInfo.confirmChangeButton'),
      objectKey: 'deliveryInfo.confirmChangeButton',
      type: LanguageInputTypes.TEXT,
    },
  ];

  const deliveryInfoCheckbox: ILanguageInputInfo[] = [
    {
      title: t('deliveryInfo.checkboxText'),
      objectKey: 'deliveryInfo.checkboxText',
      type: LanguageInputTypes.RICH_TEXT,
    },
  ];

  const deliveryInfoModalThank: ILanguageInputInfo[] = [
    {
      title: t('deliveryInfo.thanksTitle'),
      objectKey: 'deliveryInfo.thanksTitle',
      type: LanguageInputTypes.TEXT,
    },
    {
      title: t('deliveryInfo.thanksText'),
      objectKey: 'deliveryInfo.thanksText',
      type: LanguageInputTypes.TEXT,
    },
    {
      title: t('deliveryInfo.thanksButton'),
      objectKey: 'deliveryInfo.thanksButton',
      type: LanguageInputTypes.TEXT,
    },
  ];

  const trackingStatuses: ILanguageInputInfo[] = [
    {
      title: t(`orderStatusSteps.${OutvioStatusCodes.RECEIVED}`),
      objectKey: `orderStatusSteps.${OutvioStatusCodes.RECEIVED}`,
      type: LanguageInputTypes.TEXT,
    },
    {
      title: t(`orderStatusSteps.${OutvioStatusCodes.PROCESSING}`),
      objectKey: `orderStatusSteps.${OutvioStatusCodes.PROCESSING}`,
      type: LanguageInputTypes.TEXT,
    },
    {
      title: t(`orderStatusSteps.${OutvioStatusCodes.CREATED}`),
      objectKey: `orderStatusSteps.${OutvioStatusCodes.CREATED}`,
      type: LanguageInputTypes.TEXT,
    },
    {
      title: t(`orderStatusSteps.${OutvioStatusCodes.PICKED_UP}`),
      objectKey: `orderStatusSteps.${OutvioStatusCodes.PICKED_UP}`,
      type: LanguageInputTypes.TEXT,
    },
    {
      title: t(`orderStatusSteps.${OutvioStatusCodes.IN_TRANSIT}`),
      objectKey: `orderStatusSteps.${OutvioStatusCodes.IN_TRANSIT}`,
      type: LanguageInputTypes.TEXT,
    },
    {
      title: t(`orderStatusSteps.${OutvioStatusCodes.OUT_FOR_DELIVERY}`),
      objectKey: `orderStatusSteps.${OutvioStatusCodes.OUT_FOR_DELIVERY}`,
      type: LanguageInputTypes.TEXT,
    },
    {
      title: t(`orderStatusSteps.${OutvioStatusCodes.DELIVERED}`),
      objectKey: `orderStatusSteps.${OutvioStatusCodes.DELIVERED}`,
      type: LanguageInputTypes.TEXT,
    },
    {
      title: t(`orderStatusSteps.${OutvioStatusCodes.HAND_OVER}`),
      objectKey: `orderStatusSteps.${OutvioStatusCodes.HAND_OVER}`,
      type: LanguageInputTypes.TEXT,
    },
    {
      title: t(`orderStatusSteps.${OutvioStatusCodes.DELIVERED_TO_PICKUP_POINT}`),
      objectKey: `orderStatusSteps.${OutvioStatusCodes.DELIVERED_TO_PICKUP_POINT}`,
      type: LanguageInputTypes.TEXT,
    },
    {
      title: t(`orderStatusSteps.${OutvioStatusCodes.DELIVERED_TO_SHOP}`),
      objectKey: `orderStatusSteps.${OutvioStatusCodes.DELIVERED_TO_SHOP}`,
      type: LanguageInputTypes.TEXT,
    },
    {
      title: t(`orderStatusSteps.${OutvioStatusCodes.DELETED_SHIPMENT}`),
      objectKey: `orderStatusSteps.${OutvioStatusCodes.DELETED_SHIPMENT}`,
      type: LanguageInputTypes.TEXT,
    },
  ];

  return {
    trackingInputs,
    deliveryInfoModalStart,
    deliveryInfoCheckbox,
    deliveryInfoModalThank,
    trackingStatuses,
  };
};

const Tracking: FC = () => {
  const {
    formData: { isDeliveryInfoChangeable },
  } = useContext(EditModalContext);

  const { t } = useTranslation('trackingPage', { keyPrefix: 'editMode.modules' });
  const {
    trackingInputs,
    trackingStatuses,
    deliveryInfoModalStart,
    deliveryInfoCheckbox,
    deliveryInfoModalThank,
  } = useTracking();

  return (
    <Base title={t('tracking.title')} id={EDIT_MODE_TABS.TRACKING}>
      <h3 className="text-cyan-800 text-sm font-semibold mb-2">
        {t('orderStatus.titleAndButtons')}
      </h3>
      <span className="text-sm font-light mb-6 text-slate-900 text-opacity-80">
        {t('orderStatus.titleAndButtonsDescription')}
      </span>
      <LanguageInputInfo inputs={trackingInputs} />

      <h3 className="text-cyan-800 text-sm font-semibold mt-10">
        {t('orderStatus.standardTrackingStatus')}
      </h3>
      <span className="text-sm font-light mb-6 text-slate-900 text-opacity-80">
        {t('orderStatus.editStandardTrackingStatus')}
      </span>
      <LanguageInputInfo inputs={trackingStatuses} />

      <div className="flex mt-10 mb-8">
        <Field component={Toggle} name="isDeliveryInfoChangeable" type="checkbox" />
        <span className="text-sm font-light mb-6 text-slate-900 text-opacity-80 font-semibold ml-6">
          {t('deliveryInformation.allowClientsToChange')}
        </span>
      </div>
      <div className={`flex flex-col ml-[60px] ${isDeliveryInfoChangeable ? 'block' : 'hidden'}`}>
        <span className="text-cyan-800 text-sm font-semibold">
          {t('deliveryInformation.titleAndButtons')}
        </span>
        <span className="text-sm font-light mb-6 text-slate-900 text-opacity-80 mt-2">
          {t('deliveryInformation.titleAndButtonsDescription')}
        </span>
        <LanguageInputInfo inputs={deliveryInfoModalStart} />
        <span className="text-cyan-800 text-sm font-semibold mt-10">
          {t('deliveryInformation.checkbox')}
        </span>
        <span className="text-sm font-light mb-6 text-slate-900 text-opacity-80 mt-2 mb-10">
          {t('deliveryInformation.checkboxDescription')}
        </span>
        <div className="flex flex-col space-y-10">
          <LanguageInputCard
            title={t('deliveryInformation.checkboxTitle')}
            inputs={deliveryInfoCheckbox}
          />
          <LanguageInputCard
            title={t('deliveryInformation.confirmationTitle')}
            inputs={deliveryInfoModalThank}
          />
        </div>
      </div>
    </Base>
  );
};

export default Tracking;
