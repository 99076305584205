interface IGetGoogleAnalyticsData {
  trackingId: string;
  adsCookieName?: string;
}

type UTMData = typeof defaultUTM;

export interface IGetGoogleAnalyticsDataReturns extends UTMData {
  analyticsClientId: string;
  adsClickId?: string;
  adsExpiryDate?: string;
}

export const GA_ADS_COOKIE_NAME = '_gcl_aw';

/**
 * Static value if GA is blocked
 */
export const GA_CLIENT_STATIC_VALUE = '1111111111.2222222222';

export const getGclId = (cookieName: string): string | undefined => {
  try {
    const cookie = document.cookie.match(new RegExp('(^| )' + cookieName + '=([^;]+)'));
    /**
     * Google Ads creates cookies in the following format: {version}.{timestamp_in_seconds}.{google_click_id}
     */
    if (cookie) return cookie[2].split('.').pop();
  } catch (err) {
    console.log(err);
  }
  return undefined;
};

export const getExp = (cookieName: string) => {
  try {
    const cookie = document.cookie.match(new RegExp('(^| )' + cookieName + '=([^;]+)'));
    /**
     * Google Ads creates cookies in the following format: {version}.{timestamp_in_seconds}.{google_click_id}
     */
    if (cookie) {
      const cookieTime = Number(cookie[2].split('.')[1]);
      /**
       * 90 day expiration window
       */
      return new Date(cookieTime * 1000 + 864e5 * 90).toISOString() || undefined;
    }
  } catch (err) {
    console.log(err);
  }
  return undefined;
};

export const getClientId = (trackingId: string): string => {
  try {
    /**
     * ga must be available globally.
     */
    // @ts-ignore
    const trackers = ga.getAll();
    for (let i = 0; i < trackers.length; i++) {
      if (trackers[i].get('trackingId') === trackingId) {
        return trackers[i].get('clientId');
      }
    }
    throw 'err';
  } catch (e) {
    try {
      const cookie = document.cookie.match(new RegExp('(^| )_ga=([^;]+)'));
      if (cookie && cookie[2].split('.')) {
        return cookie[2] + '.' + cookie[3];
      }
      throw 'err';
    } catch (e) {
      return GA_CLIENT_STATIC_VALUE;
    }
  }
};

const defaultUTM = {
  utmSource: '',
  utmMedium: '',
  utmCampaign: '',
  utmAdgroup: '',
  utmCreative: '',
  utmKeyword: '',
  utmMatch: '',
  utmDevice: '',
  utmLocation: '',
  utmContent: '',
  utmTerm: '',
};

function getAllUTM(): UTMData {
  try {
    const data = { ...defaultUTM };

    decodeURIComponent(document.cookie)
      .split(';')
      .find((el) => el.includes('all_utm='))
      ?.split('all_utm=')?.[1]
      ?.split('&')
      ?.forEach((el) => {
        const [key, value] = el.split('=');

        switch (key) {
          case 'utm_source':
          case 'source':
            data.utmSource = value;
            break;
          case 'utm_medium':
          case 'medium':
            data.utmMedium = value;
            break;
          case 'utm_campaign':
          case 'campaign':
            data.utmCampaign = value;
            break;
          case 'utm_content':
          case 'content':
            data.utmContent = value;
            break;
          case 'utm_term':
          case 'term':
            data.utmTerm = value;
            break;
          case 'utm_adgroup':
          case 'adgroup':
            data.utmAdgroup = value;
            break;
          case 'utm_creative':
          case 'creative':
            data.utmCreative = value;
            break;
          case 'utm_keyword':
          case 'keyword':
            data.utmKeyword = value;
            break;
          case 'utm_match':
          case 'match':
            data.utmMatch = value;
            break;
          case 'utm_device':
          case 'device':
            data.utmDevice = value;
            break;
          case 'utm_location':
          case 'location':
            data.utmLocation = value;
            break;
        }
      });

    return data;
  } catch (err) {
    return defaultUTM;
  }
}

const getGoogleAnalyticsData = (props: IGetGoogleAnalyticsData): IGetGoogleAnalyticsDataReturns => {
  const { trackingId, adsCookieName } = props;

  return {
    analyticsClientId: getClientId(trackingId),
    adsClickId: getGclId(adsCookieName || GA_ADS_COOKIE_NAME),
    adsExpiryDate: getExp(adsCookieName || GA_ADS_COOKIE_NAME),
    ...getAllUTM(),
  };
};

export default getGoogleAnalyticsData;
