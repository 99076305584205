import { Languages } from 'app-types';

import { DefaultLanguage } from '../../types';
import { useDefaultMessages } from '../useDefaultMessages';

const getLanguage = async (
  locale: Languages | string,
): Promise<{ default: Record<string, string> }> => {
  switch (locale) {
    case DefaultLanguage.en:
    case Languages.en:
      return import('./en-EN');
    case Languages.es:
      return import('./es-ES');
    case Languages.pt:
      return import('./pt-PT');
    case Languages.de:
      return import('./de-DE');
    case Languages.pl:
      return import('./pl-PL');
    default:
      return { default: {} };
  }
};

export function useAppMessages(language: Languages | string) {
  return useDefaultMessages(language, getLanguage);
}
