import React, { useContext, useState } from 'react';

import { useGetPaletteColors } from 'return-portal-ui';
import { Heading } from 'ui/atoms';

import TrackingPageContext from '../../../../../Context/TrackingPageContext';
import BaseModal from '../../../../Common/BaseModal';
import FormComponent from '../Form';
import ThanksComponent from '../Thanks';

interface IDeliveryInfoModalComponent {
  isVisible: boolean;
  handleClose(): void;
}

function ModalComponent(props: IDeliveryInfoModalComponent) {
  const { isVisible, handleClose } = props;
  const {
    portalSettings: {
      regionalSettings: {
        deliveryInfo: { changeInformationText, thanksTitle },
      },
    },
    trackingData,
    appSettings: { isEdit },
  } = useContext(TrackingPageContext);

  const { primaryText } = useGetPaletteColors();

  const [isOngoing, setIsOngoing] = useState(true);

  if (!trackingData) return null;
  const toggleIsFormOngoing = (value: boolean) => setIsOngoing(value);
  const handleCloseInternal = () => {
    if (!isOngoing && !isEdit) {
      window.location.reload();
    }
    setTimeout(() => toggleIsFormOngoing(true), 4000);
    handleClose();
  };

  return (
    <BaseModal
      isVisible={isVisible}
      handleClose={handleCloseInternal}
      customStyle={{ padding: '32px 0 0 0' }}
      titleAs="div"
      title={
        <Heading style={{ color: primaryText }} className="ml-10">
          {isOngoing ? changeInformationText : thanksTitle}
        </Heading>
      }
      iframeHeight="1800px"
    >
      <div className="px-8 pb-8 w-full md:w-[425px]">
        {isOngoing && <FormComponent toggleIsFormOngoing={toggleIsFormOngoing} />}
        {!isOngoing && <ThanksComponent handleClose={handleCloseInternal} />}
      </div>
    </BaseModal>
  );
}

export default ModalComponent;
