import React, { FC, useContext, useState } from 'react';

import { getCalculatedBrightnessFromHex, useGetPaletteColors } from 'return-portal-ui';
import { Heading } from 'ui/atoms';

import TrackingPageContext from '../../../../Context/TrackingPageContext';
import { EDIT_MODE_TABS } from '../../../../Interfaces';
import BaseModal from '../../../Common/BaseModal';
import EditButton from '../../../Common/EditButton';
import StatusSteps from '../../Modules/StatusSteps';
import ModalComponent from '../DeliveryInfoModal/Modal';
import Header from './Header';
import IncidentButton from './IncidentButton';
import PackagesListTabs from './PackagesListTabs';

interface StatusStepsModal {
  isVisible: boolean;
  handleClose(): void;
}

const StatusStepsModal: FC<StatusStepsModal> = (props) => {
  const { isVisible, handleClose } = props;
  const {
    portalSettings: {
      regionalSettings: { orderStatusInfo },
    },
    packagesHeaderComponent,
    appSettings: { isEdit },
  } = useContext(TrackingPageContext);

  const { secondaryBg, primaryText } = useGetPaletteColors();

  const [openChangeAddress, setOpenChangeAddress] = useState<boolean>(false);
  const toggleModals = (val: boolean) => {
    setOpenChangeAddress(val);
    handleClose();
  };

  const scrollBarColor = getCalculatedBrightnessFromHex(secondaryBg, 2);

  const additionalComponent = isEdit ? (
    <PackagesListTabs
      isVisible
      packages={['1', '2', '3', '4']}
      currentPackage={'1'}
      setCurrentPackage={() => null}
    />
  ) : (
    packagesHeaderComponent
  );

  return (
    <>
      <BaseModal
        isVisible={isVisible}
        handleClose={() => handleClose()}
        iframeHeight="0px"
        titleAs="div"
        title={
          <div className="pl-10 pr-4 flex items-center justify-between w-full">
            <Heading style={{ color: primaryText }}>{orderStatusInfo?.moreInfoText}</Heading>
            {additionalComponent && additionalComponent}
          </div>
        }
        customStyle={{
          padding: '32px 0 24px 0',
        }}
      >
        <div className="flex flex-col max-h-full md:h-full md:w-[550px] w-full overflow-hidden">
          <Header />
          <div className="md:mt-8 mt-4 flex flex-col w-full overflow-hidden relative">
            <div
              style={{
                scrollbarColor: `${scrollBarColor} ${secondaryBg}`,
                scrollbarWidth: 'thin',
              }}
              className="snap-y snap-mandatory relative overflow-y-auto px-[38px] pb-4"
            >
              <StatusSteps type="full-steps" />
            </div>

            <div className="!z-10 absolute h-[24px] w-full top-0 right-2  pointer-events-none">
              <div
                style={{
                  background: `linear-gradient(to bottom, ${secondaryBg} 0%, transparent)`,
                }}
                className="h-full "
              />
            </div>

            <div className="!z-10 absolute h-[24px] w-full bottom-0 right-2 pointer-events-none">
              <div
                style={{
                  background: `linear-gradient(to top, ${secondaryBg} 0%, transparent)`,
                }}
                className="h-full "
              />
            </div>
          </div>
          <IncidentButton type="bottom" handleToggleModals={handleClose} />
          <IncidentButton type="header" handleToggleModals={toggleModals} />

          {isEdit && <EditButton tab={EDIT_MODE_TABS.TRACKING} />}
        </div>
      </BaseModal>
      <ModalComponent isVisible={openChangeAddress} handleClose={() => toggleModals(false)} />
    </>
  );
};

export default StatusStepsModal;
