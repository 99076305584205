import React, { useContext } from 'react';

import { OVCButton, useGetPaletteColors } from 'return-portal-ui';

import TrackingPageContext from '../../../../../Context/TrackingPageContext';

interface IThanksComponent {
  handleClose(): void;
}

function ThanksComponent(props: IThanksComponent) {
  const { handleClose } = props;
  const {
    portalSettings: {
      regionalSettings: {
        deliveryInfo: { thanksButton, thanksText },
      },
    },
  } = useContext(TrackingPageContext);

  const { primaryText } = useGetPaletteColors();

  return (
    <div style={{ color: primaryText }}>
      <p>{thanksText}</p>
      <OVCButton className="mt-6 px-6 pr-8" onClick={() => handleClose()}>
        {thanksButton}
      </OVCButton>
    </div>
  );
}

export default ThanksComponent;
