import React, { FC, useContext, useState } from 'react';

import { AccountCircleFilled, Home, MessageNotification, NotAllowed } from 'icons';
import { OVCButton, getCalculatedBrightnessFromHex, useGetPaletteColors } from 'return-portal-ui';
import { Copy, Heading } from 'ui/atoms';

import TrackingPageContext from '../../../../Context/TrackingPageContext';
import { EDIT_MODE_TABS } from '../../../../Interfaces/IEditModal';
import Card from '../../../Common/Card';
import EditButton from '../../../Common/EditButton';
import Modal from '../../Modals/CancelOrderModal/Modal/Modal.component';
import Products from './Products';

const OrderInfo: FC = () => {
  const {
    trackingData,
    portalSettings: {
      regionalSettings: {
        orderInfo: { orderTotalText },
        cancelOrder: {
          infoStep: { cancelOrderButton },
        },
        orderStatusInfo: { titleOrder },
      },
      isOrderCancellable,
    },
    appSettings: { isEdit, trackingPageId },
    isDeleted,
    cancelingAllowed,
  } = useContext(TrackingPageContext);

  const { secondaryBg, primaryText, btnBg } = useGetPaletteColors();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => setIsModalOpen(false);

  if (!trackingData) return null;
  const {
    orderInfo: { orderTotal, id },
    deliveryInfo: {
      contact: { fullName, email, phone },
      address: { street, city, country, postcode },
      comment,
    },
  } = trackingData;

  const isToken = trackingPageId && trackingPageId.length > 25;

  const cancelOrderIsAvailableForCancel =
    (isOrderCancellable && isToken && !isDeleted && cancelingAllowed) || isEdit;

  const inputBgL1 = getCalculatedBrightnessFromHex(secondaryBg, 1);

  return (
    <div id="order-details">
      <Card className="p-6 relative flex flex-col">
        <div className="ml-2 mt-2 flex mb-8 flex-col md:flex-row md:justify-between">
          <Heading style={{ color: primaryText }}>
            {titleOrder} {id}
          </Heading>

          {cancelOrderIsAvailableForCancel && (
            <OVCButton
              icon={<NotAllowed width={24} height={24} />}
              onClick={() => setIsModalOpen(true)}
            >
              {cancelOrderButton ? cancelOrderButton : ''}
            </OVCButton>
          )}
        </div>
        <div className="flex flex-col md:flex-row gap-6">
          <div className="flex flex-col w-full md:max-w-[507px]">
            <Products />
            <div
              style={{ backgroundColor: inputBgL1, color: primaryText }}
              className="p-8 w-full rounded-[10px] flex justify-between mt-4"
            >
              <div className="flex flex-col font-light">
                <Heading style={{ color: primaryText }} className="capitalize" weight="regular">
                  {orderTotalText}
                </Heading>
              </div>

              <div className="flex flex-col font-semibold">
                <Heading style={{ color: primaryText }} className="capitalize" weight="semibold">
                  {orderTotal}
                </Heading>
              </div>
            </div>
          </div>
          <div className="p-[21px] flex flex-col gap-y-[36px]">
            <div className="flex gap-x-[12px]">
              <AccountCircleFilled
                style={{ color: btnBg }}
                width={24}
                height={24}
                className="min-w-[24px]"
              />

              <div style={{ color: primaryText }} className="flex flex-col break-words gap-2">
                <Copy as="div" noColor type="copy1" weight="semibold">
                  {fullName}
                </Copy>

                <Copy as="div" noColor type="copy1" weight="regular" className="opacity-60">
                  {email} {phone}
                </Copy>
              </div>
            </div>

            <div className="flex gap-x-[12px]">
              <Home style={{ color: btnBg }} width={24} height={24} className="min-w-[24px]" />

              <div style={{ color: primaryText }} className="flex flex-col break-words gap-2">
                <Copy as="div" noColor type="copy1" weight="regular" className="opacity-60">
                  {street}
                </Copy>
                <Copy as="div" noColor type="copy1" weight="regular" className="opacity-60">
                  {city}
                </Copy>

                <Copy as="div" noColor type="copy1" weight="regular" className="opacity-60">
                  {postcode} {country}
                </Copy>
              </div>
            </div>

            {comment && (
              <div className="flex gap-x-[12px]">
                <MessageNotification
                  style={{ color: btnBg }}
                  width={24}
                  height={24}
                  className="min-w-[24px]"
                />

                <div style={{ color: primaryText }} className="flex flex-col break-words">
                  <Copy as="div" noColor type="copy1" weight="regular" className="opacity-60">
                    {comment}
                  </Copy>
                </div>
              </div>
            )}
          </div>
        </div>

        {isEdit && <EditButton tab={EDIT_MODE_TABS.ORDER_STATUS} />}
      </Card>

      <Modal isVisible={isModalOpen} handleClose={closeModal} />
    </div>
  );
};

export default OrderInfo;
