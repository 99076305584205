import React from 'react';

interface IIconReturns {
  width?: number;
  height?: number;
  color?: string;
}

const IconReturns = ({ color = 'currentColor', width = 20, height = 20 }: IIconReturns) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" width={width} height={height}>
    <path d="M0 0h22v22H0z" fill="none" />
    <g>
      <path
        d="M6.449 7.388h9.1v1.829a.447.447 0 0 0 .774.358l2.539-2.851a.55.55 0 0 0 0-.725l-2.538-2.851a.451.451 0 0 0-.774.368v1.829H5.539a.974.974 0 0 0-.91 1.022v4.087a.974.974 0 0 0 .91 1.022.974.974 0 0 0 .91-1.022zm9.1 7.224h-9.1v-1.829a.447.447 0 0 0-.774-.358l-2.538 2.851a.55.55 0 0 0 0 .725l2.539 2.851a.451.451 0 0 0 .774-.368v-1.828h10.011a.974.974 0 0 0 .91-1.022v-4.087a.974.974 0 0 0-.91-1.022.974.974 0 0 0-.91 1.022z"
        fill={color}
      />
    </g>
  </svg>
);

export default IconReturns;
