import React, { FC } from 'react';

import { FieldProps } from 'formik';
import { OVCInput } from 'return-portal-ui';

interface FormikTextInputProps extends FieldProps {
  disabled?: boolean;
}

const FormikTextInput: FC<FormikTextInputProps> = ({
  field: { name, value, onChange },
  disabled = false,
}) => {
  return <OVCInput name={name} value={value} onChange={onChange} disabled={disabled} />;
};

export default FormikTextInput;
