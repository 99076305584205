import request from '../utils/request';

export interface StoreBrandListItem {
  _id: string;
  slug: string;
  brandUrl: string;
  logo?: string | null;
  language: Array<{
    _id: string;
    name: string;
    ISOCode: string;
    isDefault: boolean;
  }>;
}

export type StoreBrandingResponse = StoreBrandListItem[];

export function getBrandingList() {
  return request<StoreBrandingResponse>(`${process.env.OUTVIO_API_URL}/store-branding/list`, {
    method: 'GET',
    secure: true,
  });
}
