export function ContentCopy({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 -960 960 960"
      fill="currentColor"
      {...props}
    >
      <path d="M230.002-107.694q-30.307 0-51.307-21-21-21-21-51.307v-477.69q0-12.769 8.615-21.384t21.384-8.615q12.769 0 21.384 8.615 8.616 8.615 8.616 21.384v477.69q0 4.615 3.846 8.462 3.846 3.846 8.462 3.846h357.689q12.769 0 21.385 8.615 8.615 8.615 8.615 21.384t-8.615 21.384q-8.616 8.616-21.385 8.616H230.002Zm139.999-139.999q-30.308 0-51.308-21-20.999-21-20.999-51.307v-455.383q0-30.307 20.999-51.307 21-21 51.308-21h335.383q30.307 0 51.307 21 21 21 21 51.307V-320q0 30.307-21 51.307-21 21-51.307 21H370.001Zm0-59.998h335.383q4.615 0 8.462-3.847 3.846-3.846 3.846-8.462v-455.383q0-4.616-3.846-8.462-3.847-3.846-8.462-3.846H370.001q-4.616 0-8.462 3.846-3.847 3.846-3.847 8.462V-320q0 4.616 3.847 8.462 3.846 3.847 8.462 3.847Zm-12.309 0v-480V-307.691Z" />
    </svg>
  );
}
