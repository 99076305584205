export function Home({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em" {...props}>
      <g transform="translate(-785 -395)">
        <rect width="24" height="24" transform="translate(785 395)" fill="none" />
        <g transform="translate(784.438 394.999)">
          <path
            d="M6.162,19a1.682,1.682,0,0,1-1.127-.4,1.223,1.223,0,0,1-.473-.96V9.461a1.176,1.176,0,0,1,.17-.612A1.418,1.418,0,0,1,5.2,8.375l6.4-4.106a1.72,1.72,0,0,1,.46-.2,1.986,1.986,0,0,1,1.005,0,1.718,1.718,0,0,1,.459.2l6.4,4.106a1.418,1.418,0,0,1,.468.474,1.176,1.176,0,0,1,.17.612v8.177a1.223,1.223,0,0,1-.473.96,1.681,1.681,0,0,1-1.127.4H14.593v-6.08H10.531V19Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}
