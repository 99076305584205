export function MarkChatUnreadFilled({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 -960 960 960"
      fill="currentColor"
      {...props}
    >
      <path d="M760-710.769q-45.769 0-77.884-32.115t-32.115-77.884q0-45.769 32.115-77.884T760-930.767q45.769 0 77.884 32.115t32.115 77.884q0 45.769-32.115 77.884T760-710.769ZM100.001-118.464v-669.227q0-30.308 21-51.308t51.308-21h401.692q-3.231 15-3.423 29.807-.193 14.808 1.884 30.192 8.615 71.923 61.692 120.577Q687.231-630.769 760-630.769q26.23 0 52.038-7.499 25.807-7.5 47.961-22.5v328.459q0 30.308-21 51.308t-51.308 21H241.539L100.001-118.464Z" />{' '}
    </svg>
  );
}
