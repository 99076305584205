import React, { FC, useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { DeleteRounded } from '@mui/icons-material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Descendant } from 'slate';

import EditModalContext from '../../../Context/EditModalContext';
import { ButtonTypes } from '../../../Interfaces/IButton';
import { EDIT_MODE_TABS } from '../../../Interfaces/IEditModal';
import { ILanguageInputInfo, LanguageInputTypes } from '../../../Interfaces/ILanguageInputInfo';
import { SlateElementType, SlateLeafType } from '../../../Interfaces/IRichTextInput';
import Button from '../../Common/Button';
import LanguageInputCard from '../LanguageInputCard';
import LanguageInputInfo from '../LanguageInputInfo';
import Base from './Base';

const useQuestions = () => {
  const {
    formData: { regionalSettings },
    setFieldValue,
  } = useContext(EditModalContext);
  const { t } = useTranslation('trackingPage', { keyPrefix: 'editMode.inputs' });
  const questionTitle = t('questions.questions.title');
  const answerTitle = t('questions.questions.answer');

  const defaultAnswer: Descendant[] = [
    {
      type: SlateElementType.PARAGRAPH,
      children: [
        {
          type: SlateLeafType.TEXT,
          text: t('questions.questions.answer'),
        },
      ],
    } as unknown as Descendant,
  ];
  const questionsTitle: ILanguageInputInfo[] = [
    {
      title: t('questions.title'),
      objectKey: 'questions.title',
      type: LanguageInputTypes.TEXT,
    },
  ];

  const removeQuestionByIndex = (i: number): void => {
    regionalSettings.map(({ questions: { questions } }, index) => {
      const n = questions?.slice(0);
      if (n) delete n[i];
      /**
       * This is to deal with empty slots in arrays.
       */
      const x = n
        ?.map((el) => {
          if (el) return el;
          return null;
        })
        .filter((el) => el !== null);
      setFieldValue(`regionalSettings[${index}].questions.questions`, x);
      return null;
    });
  };
  const addQuestion = (): void => {
    regionalSettings.map(({ questions: { questions } }, index) => {
      const n =
        questions?.map(({ title, answer }, i) => {
          return { title, answer, order: i };
        }) || [];
      n.push({ order: n.length + 1, title: questionTitle, answer: defaultAnswer });
      setFieldValue(`regionalSettings[${index}].questions.questions`, n);
      return null;
    });
  };

  /**
   * Serialise questions to language input for cards.
   */
  const serializeQuestions = ():
    | { inputs: ILanguageInputInfo[]; title: string; index: number }[]
    | undefined => {
    const rs = regionalSettings[0];
    const copy = rs.questions.questions ? [...rs.questions.questions] : [];
    return copy
      .sort((a, b) => a.order - b.order)
      .map((_, index) => {
        return {
          title: `${questionTitle} ${index + 1}`,
          inputs: [
            {
              title: questionTitle,
              objectKey: `questions.questions[${index}].title`,
              type: LanguageInputTypes.TEXT,
            },
            {
              title: answerTitle,
              objectKey: `questions.questions[${index}].answer`,
              type: LanguageInputTypes.RICH_TEXT,
            },
          ],
          index,
        };
      });
  };
  const questions = serializeQuestions();
  return { questions, questionsTitle, removeQuestionByIndex, addQuestion };
};

const Questions: FC = () => {
  const { t } = useTranslation('trackingPage', { keyPrefix: 'editMode.modules.questions' });
  const { questionsTitle, questions, removeQuestionByIndex, addQuestion } = useQuestions();

  return (
    <Base title={t('title')} id={EDIT_MODE_TABS.QUESTIONS}>
      <h3 className="text-cyan-800 text-sm font-semibold mb-2">{t('questionsTitle')}</h3>
      <span className="text-sm font-light mb-6 text-slate-900 text-opacity-80">
        {t('questionsTitleDescription')}
      </span>
      <LanguageInputInfo inputs={questionsTitle} />
      <h3 className="text-cyan-800 text-sm font-semibold mb-2">{t('title')}</h3>
      <span className="text-sm font-light mb-6 text-slate-900 text-opacity-80">
        {t('questionsDescription')}
      </span>
      <div className="flex flex-col space-y-10">
        {questions &&
          questions.map(({ inputs, title, index }) => (
            <LanguageInputCard title={title} inputs={inputs} key={index}>
              <div className="flex justify-end w-full items-center">
                {/* <InsertLinkRoundedIcon className="text-slate-700" /> */}
                <Button
                  type={ButtonTypes.OV_DANGER}
                  onClick={() => removeQuestionByIndex(index)}
                  LeftIcon={DeleteRounded}
                  content={t('delete')}
                  iconColor="text-red"
                />
              </div>
            </LanguageInputCard>
          ))}
        <Button
          type={ButtonTypes.OV_PRIMARY_DARK}
          onClick={() => addQuestion()}
          LeftIcon={AddRoundedIcon}
          content={t('add')}
        />
      </div>
    </Base>
  );
};

export default Questions;
