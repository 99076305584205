import { request } from 'ui';

export type CreationProps = {
  companyName?: string;
  website?: string;
  facebookBusinessManagerID?: string;
  facebookUrl?: string;
  region?: string;
  estimatedMonthlyMessagesVolume?: string;
  businessVertical?: string;
  imageUrl?: string;
  brandName?: string;
  aboutInfo?: string;
  contactEmail?: string;
  address?: string;
  businessDescription?: string;
  existingPhoneNumber?: string;
};

export type EditProps = {
  imageUrl?: string;
  brandName?: string;
  aboutInfo?: string;
  contactEmail?: string;
  address?: string;
  businessDescription?: string;
};

export const enableWhatsappOnboarding = ({
  data,
  brandId,
}: {
  data: CreationProps;
  brandId: string;
}) => {
  return request(`${process.env.OUTVIO_API_URL}/store-branding/${brandId}/whatsapp-sender`, {
    method: 'POST',
    data,
    secure: true,
  });
};

export const enableWhatsappOnboardingDesk = ({
  data,
  brandId,
}: {
  data: CreationProps;
  brandId: string;
}) => {
  return request(`${process.env.OUTVIO_API_URL}/store-branding/${brandId}/whatsapp-sender-desk`, {
    method: 'POST',
    data,
    secure: true,
  });
};

export const getWhatsappOnboarding = ({ brandId }: { brandId: string }) => {
  return request(`${process.env.OUTVIO_API_URL}/store-branding/${brandId}/whatsapp-sender`, {
    method: 'GET',
    secure: true,
  });
};

export const editWhatsappOnboarding = ({ data, brandId }: { data: EditProps; brandId: string }) => {
  return request(`${process.env.OUTVIO_API_URL}/store-branding/${brandId}/whatsapp-sender`, {
    method: 'PUT',
    data,
    secure: true,
  });
};

export const whatsAppPayment = (storeId: string) => {
  return request(
    `${process.env.OUTVIO_API_URL}/store-settings/${storeId}/outvio-desk/whatsAppBilling`,
    {
      method: 'POST',
      secure: true,
    },
  );
};

export const requestSpecificPrice = (storeId: string) => {
  return request(`${process.env.OUTVIO_API_URL}/store-settings/${storeId}/whatsapp-sender-price`, {
    method: 'POST',
    secure: true,
  });
};

export const downgradeWhatsAppDesk = (storeId: string) => {
  return request(
    `${process.env.OUTVIO_API_URL}/store-settings/${storeId}/outvio-desk/whatsAppBilling`,
    {
      method: 'DELETE',
      secure: true,
    },
  );
};
