import React, { FC } from 'react';

import { RenderElementProps } from 'slate-react';

import { ParagraphElement, SlateElementType } from '../../../Interfaces/IRichTextInput';

const SlateElement: FC<RenderElementProps> = (props) => {
  const { attributes, children, element } = props;
  const { type } = element as ParagraphElement;
  /**
   * We need to do prop-spreading for this.
   */

  /* eslint-disable react/jsx-props-no-spreading */
  switch (type as SlateElementType) {
    case SlateElementType.PARAGRAPH:
    default:
      return <p {...attributes}>{children}</p>;
  }
  /* eslint-enable */
};

export default SlateElement;
