import React, { FC, useContext, useEffect, useMemo, useState } from 'react';

import { QueryClientProvider } from 'react-query';

import { OVCStoreDataContext, useGetPaletteColors } from 'return-portal-ui';

import TrackingPageContext, { TrackingPageContextProps } from '../../Context/TrackingPageContext';
import useGetPortalSettingsLanguage from '../../Hooks/useGetPortalSettingsLanguage';
import { IPortalSettings } from '../../Interfaces';
import { EDIT_MODE_TABS } from '../../Interfaces/IEditModal';
import { Features } from '../../Interfaces/IFeatures';
import { BannerModes, IColors, OutvioStatusCodes } from '../../Interfaces/IPortalSettings';
import {
  ITrackingData,
  SourceDestinationAddress,
  StepStatus,
} from '../../Interfaces/ITrackingData';
import { Product } from '../../Interfaces/Product';
import queryClient from '../../Stories/queryClient';
import '../../Utils/i18n';
import RatingModal from './Modals/RatingModal';
import Banner from './Modules/Banner';
import OrderInfo from './Modules/OrderInfo';
import OrderOverview from './Modules/OrderOverview';
import Questions from './Modules/Questions';
import SuggestedProductsBlock from './Modules/SuggestedProductsBlock';
import NotFound from './NotFound';

export const MAX_WIDTH_HORIZONTAL = '980px';
export const MAX_WIDTH_VERTICAL = '1196px';

export interface TrackingPageProps {
  isEdit: boolean;
  trackingPageId: string;
  lang: string;
  googleMapsKey: string;
  handleEditButton?(tab: EDIT_MODE_TABS): void;
  portalSettings: IPortalSettings;
  trackingData: ITrackingData;
  features: Features;
  handleRate?(rating: number): void;
  handleChangeDeliveryAddress?(
    address: Partial<SourceDestinationAddress>,
  ): Promise<{ success: true } | { success: false; errors: string[] }>;
  isSplitOrder?: boolean;
  isDeleted?: boolean;
  isReturn?: boolean;
  othersProducts?: Product[];
  packagesHeaderComponent?: React.ReactNode;
  shipmentHeaderComponent?: React.ReactNode;
  cancelingAllowed: boolean;
}

interface UseTrackingPage {
  isEdit: boolean;
  trackingPageId?: string;
  lang: string;
  googleMapsKey: string;
  handleEditButton?(tab: EDIT_MODE_TABS): void;
  handleRate?(rating: number): void;
  portalSettings: IPortalSettings;
  trackingData: ITrackingData;
  features: Features;
  handleChangeDeliveryAddress?(
    address: SourceDestinationAddress,
  ): Promise<{ success: true } | { success: false; errors: string[] }>;
  cancelingAllowed: boolean;
}

interface UseGetCustomColors {
  colors: IColors;
}

interface UseTrackingPageReturn {
  contextData: TrackingPageContextProps;
  toggleRatingModal(value: boolean): void;
  isRatingModalVisible: boolean;
  isModalButton: boolean;
}

export const useTrackingPage = (props: UseTrackingPage): UseTrackingPageReturn => {
  const {
    isEdit,
    trackingPageId,
    lang,
    googleMapsKey,
    handleEditButton,
    portalSettings: srcSettings,
    trackingData,
    features,
    handleRate,
    handleChangeDeliveryAddress,
    cancelingAllowed,
  } = props;
  const { portalSettings } = useGetPortalSettingsLanguage({
    lang,
    srcSettings,
  });
  const [isRatingModalVisible, setIsRatingModalVisible] = useState(false);
  const toggleRatingModal = (value: boolean) => setIsRatingModalVisible(value);
  const [isModalButton, setIsModalButton] = useState(false);

  useEffect(() => {
    const { useRateOrder } = portalSettings;
    const isDone =
      trackingData?.orderStatusInfo?.steps.find(({ code }) => code === OutvioStatusCodes.DELIVERED)
        ?.status === StepStatus.DONE || false;
    setIsRatingModalVisible(useRateOrder && isDone && !trackingData?.rating);
    setIsModalButton(useRateOrder && isDone && !trackingData?.rating);
  }, [trackingData]);

  const contextData = {
    trackingData,
    portalSettings,
    handleEditButton,
    handleRate,
    appSettings: {
      isEdit,
      trackingPageId,
      lang,
      googleMapsKey,
    },
    isError: !trackingData,
    features,
    handleChangeDeliveryAddress,
    cancelingAllowed,
  };

  return {
    contextData,
    toggleRatingModal,
    isRatingModalVisible,
    isModalButton,
  };
};

export const useSetCustomColors = (props: UseGetCustomColors): void => {
  const { colors } = props;
  Object.keys(colors).map((key) => {
    /**
     * Can't work around this. The only way of setting up dynamic variables.
     */
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    document.documentElement.style.setProperty(`--color-tp-${key}`, colors[key]);
    return null;
  });
};

const TrackingPage: FC<TrackingPageProps> = (props) => {
  const {
    isReturn,
    cancelingAllowed,
    isEdit,
    trackingPageId,
    lang,
    googleMapsKey,
    handleEditButton,
    portalSettings,
    trackingData,
    features,
    handleRate,
    handleChangeDeliveryAddress,
    isSplitOrder,
    othersProducts,
    packagesHeaderComponent,
    shipmentHeaderComponent,
    isDeleted,
  } = props;
  const { contextData, isRatingModalVisible, toggleRatingModal, isModalButton } = useTrackingPage({
    isEdit,
    trackingPageId,
    lang,
    googleMapsKey,
    handleEditButton,
    portalSettings,
    trackingData,
    features,
    handleRate,
    handleChangeDeliveryAddress,
    cancelingAllowed,
  });
  const {
    portalSettings: {
      colors,
      regionalSettings: { banners },
      useRateOrder,
      isBannerDisplay,
    },
    isError,
  } = contextData;
  const desktopBanner = isBannerDisplay ? banners?.desktop : undefined;
  const mobileBanner = isBannerDisplay ? banners?.mobile : undefined;
  const maxWidth =
    desktopBanner?.mode === BannerModes.VERTICAL ? MAX_WIDTH_VERTICAL : MAX_WIDTH_HORIZONTAL;
  useSetCustomColors({ colors });

  const { storeData } = useContext(OVCStoreDataContext);

  const { secondaryBg, header } = useGetPaletteColors();

  function renderRatingModal() {
    if (trackingData?.rating || !useRateOrder || !handleRate) return null;
    return (
      <RatingModal
        isRatingModalVisible={isRatingModalVisible}
        toggleRatingModal={toggleRatingModal}
        handleRate={handleRate}
      />
    );
  }

  const mergedContextData = useMemo(
    () => ({
      ...contextData,
      isSplitOrder,
      othersProducts,
      packagesHeaderComponent,
      shipmentHeaderComponent,
      isDeleted,
      isReturn,
    }),
    [
      contextData,
      isSplitOrder,
      othersProducts,
      packagesHeaderComponent,
      shipmentHeaderComponent,
      isDeleted,
      isReturn,
    ],
  );

  return (
    // @ts-ignore
    <QueryClientProvider client={queryClient}>
      <TrackingPageContext.Provider value={mergedContextData}>
        <div
          style={{
            backgroundImage: `url(${storeData.portalSettings.trackingDesktopBg})`,
            backgroundColor: secondaryBg,
          }}
          className="relative w-full flex flex-col justify-center md:mb-0 md:pt-[16px] bg-fixed bg-center bg-no-repeat bg-cover"
        >
          <div
            style={{ backgroundColor: secondaryBg }}
            className="absolute top-0 w-full h-full opacity-60"
          />

          <div className="w-full flex flex-col md:mx-auto md:gap-2 md:mb-4" style={{ maxWidth }}>
            {isError && <NotFound />}
            <OrderOverview isModalButton={isModalButton} toggleRatingModal={toggleRatingModal} />

            {mobileBanner && <Banner componentMode={BannerModes.MOBILE} />}

            <SuggestedProductsBlock />

            {desktopBanner?.mode === BannerModes.HORIZONTAL && (
              <Banner componentMode={BannerModes.HORIZONTAL} />
            )}

            <OrderInfo />
          </div>
        </div>

        <div style={{ backgroundColor: header }} className="w-full">
          <Questions />
          {desktopBanner?.mode === BannerModes.VERTICAL && (
            <Banner componentMode={BannerModes.VERTICAL} />
          )}
        </div>
        {renderRatingModal()}
      </TrackingPageContext.Provider>
    </QueryClientProvider>
  );
};

export default TrackingPage;
