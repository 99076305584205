import { useMutation } from '@tanstack/react-query';

import { validateCustomDomainByStore } from '../mutations';

export type ValidateCustomDomainByStoreProps = {
  storeBrandingId: string;
  customDomainId: string;
};

export function useValidateCustomDomainByStore() {
  return useMutation(({ storeBrandingId, customDomainId }: ValidateCustomDomainByStoreProps) =>
    validateCustomDomainByStore(storeBrandingId, customDomainId),
  );
}
