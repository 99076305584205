const getCouriersIcons = async () => {
  const res = await fetch(`${import.meta.env.VITE_OUTVIO_API_URL}/courier/schema`);
  const json: {
    data: {
      name: string;
      integration: string;
      alias: string;
      logo: string;
      smallLogo: string;
    }[];
  } = await res.json();
  return json?.data;
};

export default getCouriersIcons;
