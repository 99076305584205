import React, { FC, useContext } from 'react';

import { Field } from 'formik';

import EditModalContext from '../../Context/EditModalContext';
import LanguageInput from '../Common/Inputs/LanguageInput';

interface LanguageInputContainer {
  objectKey: string;
}

const LanguageInputContainer: FC<LanguageInputContainer> = (props) => {
  const { objectKey } = props;
  const {
    formData: { regionalSettings },
  } = useContext(EditModalContext);

  return (
    <div className="flex flex-col space-y-2">
      {regionalSettings.map((brand, index) => (
        <span key={objectKey + brand.language.ISOCode + index}>
          <Field
            name={`regionalSettings[${index}].${objectKey}`}
            component={LanguageInput}
            lang={brand.language}
          />
        </span>
      ))}
    </div>
  );
};

export default LanguageInputContainer;
