import React, { useContext } from 'react';

import { PoweredByOutvio } from 'icons';
import { twMerge } from 'tailwind-merge';
import { getColorContrast } from 'ui';

import { useGetPaletteColors } from '../hooks';
import OVCStoreDataContext from './OVCStoreDataContext';

const OVCFooterWatermark = () => {
  const {
    storeData: {
      feature: {
        customerPortal: { watermark },
      },
    },
  } = useContext(OVCStoreDataContext);

  const { primaryText } = useGetPaletteColors();

  const textColor = getColorContrast(primaryText, true);

  if (watermark) {
    return (
      <div className="max-w-[980px] w-full mx-auto mb-10" data-cy="ovc-layout-watermark">
        <div className={twMerge('flex justify-end w-full', textColor)}>
          <a
            className="flex items-center justify-center py-1 px-4 hover:bg-opacity-[0.04] rounded-[16px] pointer-events-none"
            rel="noreferrer"
            href="https://outvio.com/"
            target="_blank"
          >
            <PoweredByOutvio />
          </a>
        </div>
      </div>
    );
  }

  return null;
};

export default OVCFooterWatermark;
