import { IPortalSettings, ISingleRegionPortalSettings } from '../Interfaces';

interface IUseGetTrackingData {
  lang: string;
  srcSettings: IPortalSettings;
}

interface IUseGetTrackingDataReturn {
  portalSettings: ISingleRegionPortalSettings;
}

const useGetPortalSettingsLanguage = (props: IUseGetTrackingData): IUseGetTrackingDataReturn => {
  const { lang, srcSettings } = props;
  const regionalSettings =
    srcSettings.regionalSettings.find(({ language: { ISOCode } }) => {
      if (lang === ISOCode) return true;
      if (ISOCode?.length === 2 && ISOCode === lang?.slice(0, 2)) return true;
      return false;
    }) || srcSettings.regionalSettings[0];
  const portalSettings = { ...srcSettings, regionalSettings } as ISingleRegionPortalSettings;
  return { portalSettings };
};

export default useGetPortalSettingsLanguage;
