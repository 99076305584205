import { FC, ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

type TabData = {
  value: string;
  label: string | ReactNode;
  disabled?: boolean;
};
//
const SmallTabs: FC<{
  openTab: string;
  onChangeTab(newTab: string): void;
  data: TabData[];
  exact?: boolean;
}> = ({ openTab, onChangeTab, data, exact }) => {
  return (
    <div className="w-full grid grid-flow-col auto-cols-max items-center gap-1 min-h-[40px]">
      {data.map((entry: TabData) => {
        const isSelected = exact ? openTab === entry.value : openTab.startsWith(entry.value);
        return (
          <a
            key={entry.value}
            className={twMerge(
              'border-blueGray-300 px-3 h-full flex rounded-t border border-blueGray-300 border-b-transparent  items-center justify-center bg-white   text-blueGray-910/90 cursor-pointer uppercase',
              isSelected && 'bg-blueGray-300 border-blueGray-300 font-semibold',
            )}
            onClick={() => {
              onChangeTab(entry.value);
            }}
          >
            {entry.label}
          </a>
        );
      })}
    </div>
  );
};

export { SmallTabs };
