export function BoxWithArrow({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" {...props}>
      <g transform="translate(0 -1)">
        <rect width="20" height="20" transform="translate(0 1)" fill="none" />
        <path
          d="M16.4,9.8v5.1l-.1.1-5.7,3.3v-7l3.7-2.1L14.1,9,12.6,7.5,7.5,4.6,9.7,3.3a.188.188,0,0,1,.3,0l1.4.8a2.177,2.177,0,0,1,.5-.8l.2-.2-1.5-.9A1.884,1.884,0,0,0,9.8,2a3.091,3.091,0,0,0-.8.2L2.8,5.8A1.6,1.6,0,0,0,2,7.2v7.6a1.6,1.6,0,0,0,.8,1.4L9,19.8a3.091,3.091,0,0,0,.8.2,1.884,1.884,0,0,0,.8-.2l6.2-3.6a1.6,1.6,0,0,0,.8-1.4V9.2A1.563,1.563,0,0,1,16.4,9.8ZM3.8,6.8,6.2,5.4l6.1,3.5L9.9,10.3Z"
          fill="#13343c"
          stroke="#13343c"
          strokeWidth="0.2"
        />
        <path
          d="M18.7,5.2a.52.52,0,0,0-.2-.4.758.758,0,0,0-.4-.2c-.2,0-.3,0-.4.2L16.5,5.9V2.6a.52.52,0,0,0-.2-.4c0-.1-.2-.2-.4-.2a.52.52,0,0,0-.4.2.758.758,0,0,0-.2.4V5.8L14.1,4.7a.758.758,0,0,0-.4-.2.707.707,0,0,0-.6.6.52.52,0,0,0,.2.4l2.1,2.1a.551.551,0,0,0,.5.2.764.764,0,0,0,.5-.2l2.1-2.1C18.7,5.5,18.7,5.3,18.7,5.2Z"
          fill="#13343c"
        />
      </g>
    </svg>
  );
}
