import React from 'react';

interface IOVTab {
  children?: React.ReactNode;
  title: string;
}

const OVTab = ({ children = null, ...rest }: IOVTab) => {
  return <div {...rest}>{children}</div>;
};

export default OVTab;
