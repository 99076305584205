import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { hourCache } from '../../../constants';
import { CouriersWithMethodsResponse, getCouriersWithMethods } from '../../../queries/index';

export type CouriersWithMethodsOptions<T> = UseQueryOptions<
  CouriersWithMethodsResponse | undefined,
  unknown,
  T,
  [string]
>;

export function useCouriersWithMethods<T = CouriersWithMethodsResponse | undefined>(
  options?: CouriersWithMethodsOptions<T>,
) {
  return useQuery(['getCouriersWithMethods'], () => getCouriersWithMethods(), {
    staleTime: hourCache,
    cacheTime: hourCache,
    ...options,
  });
}
