import React, { FC, useContext } from 'react';

import { getCalculatedBrightnessFromHex, useGetPaletteColors } from 'return-portal-ui';
import { twMerge } from 'tailwind-merge';
import { DropdownButton } from 'ui';

import TrackingPageContext from '../../../../Context/TrackingPageContext';

interface Props {
  isVisible: boolean;
  packages: string[];
  currentPackage: string;
  setCurrentPackage: (index: number) => void;
}
const PackagesList: FC<Props> = ({ currentPackage, isVisible, packages, setCurrentPackage }) => {
  const {
    portalSettings: { regionalSettings },
  } = useContext(TrackingPageContext);
  const { secondaryBg, primaryText } = useGetPaletteColors();

  // in case of no multi package shipments
  if (!isVisible) return null;

  const packageOptions = packages?.map((_, index) => ({
    label: `${regionalSettings?.tracking?.buttonPackage || ''} ${index + 1}`,
    value: index,
    onClick: () => setCurrentPackage(index),
  }));

  const toneLevel1 = getCalculatedBrightnessFromHex(secondaryBg, 1);
  const toneLevel2 = getCalculatedBrightnessFromHex(secondaryBg, 2);
  const toneLevel3 = getCalculatedBrightnessFromHex(secondaryBg, 3);

  return (
    <div style={{ borderColor: toneLevel2 }} className="flex items-center gap-2 border-r">
      <DropdownButton
        isDoubleActionDisabled
        options={packageOptions}
        buttonClass={twMerge('!bg-transparent hover:!bg-opacity-70')}
        menuClass="!z-[11]"
        menuStyle={{
          backgroundColor: secondaryBg,
          boxShadow: '0 0 #0000',
          borderWidth: '1px',
          borderColor: toneLevel1,
        }}
        iconclass="hidden"
        position="bottom-start"
        style={{
          boxShadow: '0 0 #0000',
          color: primaryText,
        }}
        iconWrapperStyle={{ color: toneLevel3 }}
        menuItemActiveColor={toneLevel1}
        menuItemStyle={{ color: primaryText }}
      >
        <span>
          {regionalSettings?.tracking?.buttonPackage} {currentPackage}
        </span>
      </DropdownButton>
    </div>
  );
};

export default PackagesList;
