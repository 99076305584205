import { useMutation } from '@tanstack/react-query';
import { request } from 'ui';

export type Props = {
  storeBranding: any;
  allStoreBrandings: any[];
  extraFields?: any;
};

export function useUpdateBrandings() {
  return useMutation(({ storeBranding, allStoreBrandings, extraFields }: Props) =>
    updateStoreBrandingNEW(storeBranding, allStoreBrandings, extraFields),
  );
}

export const updateStoreBrandingNEW = (
  storeBranding: any,
  allStoreBrandings: any[] = [],
  extraFields: any = {},
) =>
  request(`${process.env.OUTVIO_API_URL}/store-branding`, {
    method: 'PUT',
    data: {
      storeBrandings: allStoreBrandings.map((sb) => {
        if (sb._id === storeBranding._id) {
          return storeBranding;
        }
        return sb;
      }),
      ...extraFields,
    },
    secure: true,
  });
