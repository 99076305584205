export function Verified({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g transform="translate(-259 -213)">
        <g transform="translate(261 215)">
          <path
            d="M8.88,11.694,7.046,9.86a.572.572,0,0,0-.412-.168.558.558,0,0,0-.42.168.59.59,0,0,0,0,.832L8.38,12.859a.69.69,0,0,0,1,0l4.392-4.392a.572.572,0,0,0,.168-.412.558.558,0,0,0-.168-.42.59.59,0,0,0-.832,0ZM10,17.5a7.307,7.307,0,0,1-2.925-.591,7.489,7.489,0,0,1-3.985-3.984,7.537,7.537,0,0,1,0-5.85A7.489,7.489,0,0,1,7.074,3.091a7.537,7.537,0,0,1,5.85,0,7.489,7.489,0,0,1,3.986,3.984,7.537,7.537,0,0,1,0,5.85,7.488,7.488,0,0,1-3.984,3.985A7.3,7.3,0,0,1,10,17.5Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}
