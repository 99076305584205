import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { EDIT_MODE_TABS } from '../../../Interfaces/IEditModal';
import { ILanguageInputInfo, LanguageInputTypes } from '../../../Interfaces/ILanguageInputInfo';
import LanguageInputInfo from '../LanguageInputInfo';
import Base from './Base';

export const useLoginPage = () => {
  const { t } = useTranslation('trackingPage', { keyPrefix: 'editMode.inputs' });
  const loginPageInputs: ILanguageInputInfo[] = [
    {
      title: t('loginPageInfo.titleTrackingPortal'),
      objectKey: 'loginPageInfo.titleTrackingPortal',
      type: LanguageInputTypes.TEXT,
    },
    {
      title: t('loginPageInfo.inputInsertIdOrTrackingNumber'),
      objectKey: 'loginPageInfo.inputInsertIdOrTrackingNumber',
      type: LanguageInputTypes.TEXT,
    },
    {
      title: t('loginPageInfo.inputInsertDestinationPostcode'),
      objectKey: 'loginPageInfo.inputInsertDestinationPostcode',
      type: LanguageInputTypes.TEXT,
    },
    {
      title: t('loginPageInfo.buttonTrackOrder'),
      objectKey: 'loginPageInfo.buttonTrackOrder',
      type: LanguageInputTypes.TEXT,
    },
  ];

  return {
    loginPageInputs,
  };
};

const LoginPage: FC = () => {
  const { t } = useTranslation('trackingPage', { keyPrefix: 'editMode.modules' });
  const { loginPageInputs } = useLoginPage();

  return (
    <Base title={t('loginPageInfo.title')} id={EDIT_MODE_TABS.LOGIN_PAGE}>
      <h3 className="text-cyan-800 text-sm font-semibold mb-2">
        {t('orderStatus.titleAndButtons')}
      </h3>
      <span className="text-sm font-light mb-6 text-slate-900 text-opacity-80">
        {t('orderStatus.titleAndButtonsDescription')}
      </span>
      <LanguageInputInfo inputs={loginPageInputs} />
    </Base>
  );
};

export default LoginPage;
