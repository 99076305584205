import { useEffect } from 'react';

function initializedWidget() {
  const id = 'outvio-support-chat';
  if (document.getElementById(id)) {
    return;
  }

  let envString = '';
  const env = window.location.host.split('.')[1];
  if (['dev', 'staging'].includes(env)) {
    envString = `.${env}`;
  }

  const script = document.createElement('script');
  script.src = `https://api${envString}.outvio.com/desk/chat/widget?authType=appToken&chatToken=${
    import.meta.env.VITE_CHAT_WIDGET_TOKEN
  }`;
  script.type = 'text/javascript';
  script.async = true;
  script.id = id;
  document.body.appendChild(script);
}

export function useInitOutvioChatWidget() {
  useEffect(() => {
    initializedWidget();
  }, []);
}
