import {
  type ChangeEvent,
  type Dispatch,
  type InputHTMLAttributes,
  type ReactNode,
  type SetStateAction,
} from 'react';

import { twMerge } from 'tailwind-merge';

export type PrefixSuffixInputFragmentProps = InputHTMLAttributes<HTMLInputElement> & {
  name: string;
  placeholderWidth: string;
  placeholderWidthClassName: string;
  prefix: string;
  suffix: string;
  isError: boolean;
  inputClassName?: string;
  icon?: ReactNode;
  iconPosition?: 'left' | 'right';
  setIsFocused?: Dispatch<SetStateAction<boolean>>;
  register?: (name: string) => any;
};

const onInput = (event: ChangeEvent<HTMLInputElement>, placeholderWidth: string) => {
  if (event.target.value.length === 0) {
    event.target.style.width = placeholderWidth;
  } else {
    const input = document.createElement('span');
    input.innerText = event.target.value;
    const children = event.target.parentElement?.children;
    const prefixWidth = children?.[0]?.scrollWidth ?? 0;
    const suffixWidth = children?.[children.length - 1].scrollWidth ?? 0;
    const maxWidth = (event.target.parentElement?.scrollWidth ?? 0) - prefixWidth - suffixWidth;
    Object.assign(input.style, {
      position: 'absolute',
      top: '-9999px',
      left: '-9999px',
      visibility: 'hidden',
      whiteSpace: 'nowrap',
      fontFamily: 'Open Sans',
      fontSize: '14px',
      fontWeight: '400',
      letterSpacing: '0.35px',
      margin: '0',
      padding: '0',
    });
    document.body.appendChild(input);
    const width = input.scrollWidth;
    document.body.removeChild(input);
    event.target.style.width = `${Math.min(width, maxWidth)}px`;
  }
};

export function PrefixSuffixInputFragment({
  name,
  type = 'text',
  placeholderWidth,
  placeholderWidthClassName,
  inputClassName,
  iconPosition = 'left',
  icon,
  prefix,
  suffix,
  isError,
  setIsFocused,
  register,
  disabled,
  ...otherProps
}: PrefixSuffixInputFragmentProps) {
  return (
    <>
      <span className={twMerge('pl-4 text-[#849FAD]', disabled && 'text-v2blueGray-400')}>
        {prefix}
      </span>
      <input
        name={name}
        type={type}
        data-testid="outvioui--input__input"
        className={twMerge(
          'relative z-0 rounded-md text-v2blueGray-800 h-9 text-sm tracking-wide bg-transparent outline-none focus:outline-none',
          !prefix && 'pl-4',
          suffix ? placeholderWidthClassName : 'grow pr-4',
          inputClassName,
          icon && (iconPosition === 'right' ? 'mr-14' : 'ml-14'),
          isError && 'placeholder-v2red text-v2red',
          disabled && 'cursor-not-allowed text-v2blueGray-400',
        )}
        {...otherProps}
        {...(register ? register(name) : {})}
        onFocus={() => setIsFocused?.(true)}
        onBlur={() => setIsFocused?.(false)}
        onInput={(event: ChangeEvent<HTMLInputElement>) => {
          onInput(event, placeholderWidth);
        }}
        disabled={disabled}
      />
      <span className={twMerge('pr-4 text-[#849FAD]', disabled && 'text-v2blueGray-400')}>
        {suffix}
      </span>
    </>
  );
}

export default PrefixSuffixInputFragment;
