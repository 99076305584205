export function Warehouse({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16" {...props}>
      <g transform="translate(1.583 0.624)">
        <path
          d="M2.417,13.213V6.577a1.081,1.081,0,0,1,.2-.643,1.136,1.136,0,0,1,.523-.409L8.275,3.6a.9.9,0,0,1,.372-.08.955.955,0,0,1,.379.08l5.132,1.929a1.136,1.136,0,0,1,.523.409,1.081,1.081,0,0,1,.2.643v6.636a1.07,1.07,0,0,1-.323.783,1.058,1.058,0,0,1-.779.325H11.938V8.745a.207.207,0,0,0-.059-.152.2.2,0,0,0-.151-.06H5.573a.2.2,0,0,0-.152.06.207.207,0,0,0-.059.152v5.576H3.514A1.051,1.051,0,0,1,2.737,14,1.075,1.075,0,0,1,2.417,13.213Zm4.575,1.108V13.24H8.067v1.081Zm1.121-2.067V11.172H9.188v1.081Zm1.121,2.067V13.24h1.075v1.081Z"
          transform="translate(-1.922 -1.558)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
