import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { EDIT_MODE_TABS } from '../../../Interfaces/IEditModal';
import LogoUploader from '../LogoUploader';
import Base from './Base';

const Logo: FC = () => {
  const { t } = useTranslation('trackingPage', { keyPrefix: 'editMode.modules.logo' });
  return (
    <Base title={t('title')} id={EDIT_MODE_TABS.LOGO}>
      <LogoUploader />
    </Base>
  );
};

export default Logo;
