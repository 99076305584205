import React, { ReactNode } from 'react';

import { Maybe } from 'app-types/desk';
import { Like } from 'icons';
import { FormattedMessage } from 'localization';
import { twMerge } from 'tailwind-merge';

import { DateTime } from '../../../components';
import { DeskCommentLike } from './DeskCommentLike';

export enum EnumNestingLevel {
  first = 1,
  second = 2,
  third = 3,
}

export const DeskCommentUI = ({
  onReplyClick,
  isHasChild,
  isShowLineSecond,
  isShowLineThird,
  senderPhoto,
  senderName,
  createdAt,
  body,
  isActive,
  attachments,
  replyName,
  nestingLevel = EnumNestingLevel.first,
}: {
  onReplyClick?: () => void;
  isHasChild?: boolean;
  isShowLineSecond?: boolean;
  isShowLineThird?: boolean;
  isActive?: boolean;
  nestingLevel?: EnumNestingLevel;
  senderPhoto?: JSX.Element;
  senderName: Maybe<string>;
  body?: Maybe<string>;
  createdAt?: Maybe<string>;
  replyName?: Maybe<string>;
  attachments?: ReactNode;
}) => {
  return (
    <>
      <div
        className={twMerge(
          'pb-5',
          nestingLevel === EnumNestingLevel.second && 'pl-[48px]',
          nestingLevel === EnumNestingLevel.third && 'pl-[96px]',
        )}
      >
        <div className="flex gap-4 relative">
          {(nestingLevel === EnumNestingLevel.second ||
            nestingLevel === EnumNestingLevel.third) && <DeskCommentCorner />}
          {isShowLineSecond && <DeskCommentLineSecond />}
          {isShowLineThird && <DeskCommentLineThird />}
          {isHasChild && <DeskCommentLine />}

          {senderPhoto}

          <div>
            <div
              className={twMerge(
                'bg-v2blueGray-0 py-4 px-5 rounded-[10px]',
                isActive && 'bg-v2blue-600/20',
              )}
            >
              <div className="text-xs font-semibold tracking-[-0.216px] leading-[22px] text-v2blueGray-800">
                {senderName}
              </div>
              <div className="text-sm tracking-[-0.196px] leading-[22px] text-v2blueGray-800">
                {replyName && (
                  <>
                    <span className="font-semibold text-v2blue-600">{replyName}</span>{' '}
                  </>
                )}
                {body}
                {attachments}
              </div>
              {createdAt && (
                <div className="text-xs text-v2blueGray-500 flex justify-end mt-1">
                  <DateTime value={createdAt} reverse separator={<>&nbsp;&nbsp;</>} />
                </div>
              )}
            </div>
            <div className="pl-5 pt-2 flex gap-4">
              <DeskCommentLike
                active={false}
                disabled
                activeIcon={<Like />}
                text={<FormattedMessage id="desk-app.like" />}
              />
              <DeskCommentLike
                onClick={onReplyClick}
                text={<FormattedMessage id="desk-app.reply" />}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const DeskCommentCorner = () => {
  return (
    <div className="w-12 h-9 absolute rounded-bl-[14px] border-b border-l -translate-x-[32px] -translate-y-[20px] border-v2blueGray-200 -z-10"></div>
  );
};

const DeskCommentLine = ({ className }: { className?: string }) => {
  return (
    <div
      className={twMerge(
        'w-px h-full absolute translate-x-[16px] translate-y-[16px] bg-v2blueGray-200 -z-10',
        className,
      )}
    ></div>
  );
};

const DeskCommentLineSecond = () => {
  return <DeskCommentLine className="-translate-x-[32px] -translate-y-[0px] h-[calc(100%+20px)]" />;
};

const DeskCommentLineThird = () => {
  return <DeskCommentLine className="-translate-x-[80px] -translate-y-[0px] h-[calc(100%+20px)]" />;
};
