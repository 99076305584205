import React, { FC, useContext, useEffect, useMemo, useState } from 'react';

import { Field } from 'formik';

import EditModalContext from '../../Context/EditModalContext';
import { ILanguageInputInfo, LanguageInputTypes } from '../../Interfaces/ILanguageInputInfo';
import RichTextInput from '../Common/Inputs/RichTextInput';
import TextInput from '../Common/Inputs/TextInput';
import TextareaInput from '../Common/Inputs/TextareaInput';
import LanguageSelector from './LanguageSelector';

interface LanguageInputCard {
  inputs: ILanguageInputInfo[];
  title?: string;
  children?: React.ReactNode;
}

const LanguageInputCard: FC<LanguageInputCard> = (props) => {
  const { inputs, title, children } = props;
  const {
    formData: { regionalSettings },
  } = useContext(EditModalContext);
  const languages = useMemo(
    () => regionalSettings.map((setting) => setting.language),
    [regionalSettings],
  );
  const [selectedLanguage, setSelectedLanguage] = useState(languages[0]);
  const [langIndex, setLangIndex] = useState(0);

  useEffect(
    () => setLangIndex(languages.findIndex(({ ISOCode }) => ISOCode === selectedLanguage.ISOCode)),
    [selectedLanguage],
  );

  const renderFieldInput = (lang: ILanguageInputInfo, name: string) => {
    const { type } = lang;
    switch (type) {
      case LanguageInputTypes.TEXTAREA:
        return <Field component={TextareaInput} name={name} type="textarea" />;
      case LanguageInputTypes.RICH_TEXT:
        return <Field component={RichTextInput} name={name} />;
      case LanguageInputTypes.TEXT:
      case LanguageInputTypes.RADIO:
      case LanguageInputTypes.CHECKBOX:
      default:
        return <Field component={TextInput} name={name} type="text" />;
    }
  };

  return (
    <div className="flex flex-col">
      {title && (
        <span className="text-cyan-800 text-sm font-semibold text-base font-bold">{title}</span>
      )}
      <LanguageSelector
        languages={languages}
        selectedLanguage={selectedLanguage}
        setSelectedLanguage={setSelectedLanguage}
      />
      <div className="flex flex-col rounded border border-slate-100 px-8 py-6 mt-4 shadow-sm space-y-3">
        {inputs.map((input) => {
          const { title: fieldTitle, objectKey } = input;
          const name = `regionalSettings[${langIndex}].${objectKey}`;
          return (
            <label htmlFor={name} className="flex flex-col" key={name}>
              <span className="text-sm font-semibold text-slate-900 text-opacity-80 mb-3">
                {fieldTitle}
              </span>
              {renderFieldInput(input, name)}
            </label>
          );
        })}
        {children}
      </div>
    </div>
  );
};

export default LanguageInputCard;
