const cancelOrder = async (orderOtn: string) => {
  const response = await fetch(
    `${import.meta.env.VITE_OUTVIO_API_URL}/customer/order/${orderOtn}/cancel`,
    {
      method: 'DELETE',
    },
  );

  return response;
};

export default cancelOrder;
