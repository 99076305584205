import { useQuery } from '@tanstack/react-query';

import { hourCache } from '../../../constants';
import request from '../../../utils/request';

type WhatsAppInfoProps = {
  data: { isActive: boolean; hasPendingUpdate: boolean; phone?: string };
};

export function useGetWhatsappOnboardingInfo(storeBrandingId?: string) {
  return useQuery(
    [`getWhatsAppInfo-${storeBrandingId}`],
    () =>
      request<WhatsAppInfoProps>(
        `${process.env.OUTVIO_API_URL}/store-branding/${storeBrandingId}/whatsapp-sender-status`,
        {
          method: 'GET',
          secure: true,
        },
      ),
    {
      staleTime: hourCache,
      cacheTime: hourCache,
      enabled: !!storeBrandingId,
    },
  );
}
