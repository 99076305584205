import { request } from 'ui';

export const saveDashboardPickup = ({
  warehouse_id,
  pickup_id,
  courier_id,
}: {
  warehouse_id?: string;
  pickup_id?: string;
  courier_id?: string;
}) =>
  request(`${process.env.OUTVIO_API_URL}/courier/pickup`, {
    method: 'POST',
    data: { warehouse_id, pickup_id, courier_id },
    secure: true,
  });
