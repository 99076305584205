import { render } from 'react-dom';

import PackageJson from '../package.json';
import Routes from './routes';

console.log(`version: ${PackageJson.version}`);

if (process.env.OUTVIO_APP_ENV === 'production') {
  // Insert Google tag manager
  const scriptGTMHead = document.createElement('script');
  scriptGTMHead.text = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push(
  {'gtm.start': new Date().getTime(),event:'gtm.js'}
  );var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-5KR7XJ5');`;
  document.head.appendChild(scriptGTMHead);
}

render(<Routes />, document.getElementById('root'));
