import React, { FC, useContext, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import EditModalContext from '../../../Context/EditModalContext';
import { EDIT_MODE_TABS, EDIT_MODE_TABS_INFO } from '../../../Interfaces/IEditModal';
import NavbarTab from './NavbarTab';

const NAVBAR_WIDTH_DESKTOP = 'min-w-[282px]';

interface Navbar {
  currentModule: EDIT_MODE_TABS;
}

const Navbar: FC<Navbar> = (props) => {
  const { currentModule } = props;
  const { goToModule } = useContext(EditModalContext);
  const sortedTabs = useMemo(
    () => EDIT_MODE_TABS_INFO.sort((a, b) => a.index - b.index),
    [EDIT_MODE_TABS_INFO],
  );
  const { t } = useTranslation('trackingPage', { keyPrefix: 'editMode.modules' });
  return (
    <div className={`${NAVBAR_WIDTH_DESKTOP} hidden md:flex md:flex-col overflow-hidden`}>
      {sortedTabs.map(({ id, name }) => (
        <NavbarTab
          name={t(`${name}.title`)}
          isSelected={currentModule === id}
          onClick={() => goToModule(id)}
          key={name}
        />
      ))}
    </div>
  );
};

export default Navbar;
