import { Tooltip } from '@material-ui/core';

type HelpIconProps = {
  tooltipText?: string;
  onClick?: () => void;
  children?: React.ReactNode;
};

export function HelpIcon({ tooltipText, onClick, children }: HelpIconProps) {
  return (
    <Tooltip
      onClick={onClick}
      className={`select-none w-6 h-6 text-base font-semibold flex items-center 
        justify-center rounded-full cursor-pointer 
        bg-v2blueGray-300 text-white`}
      title={tooltipText || ''}
    >
      <div>{children ? children : '?'}</div>
    </Tooltip>
  );
}
