import React, { FC, useCallback } from 'react';

import { FieldProps } from 'formik';
import { Editable, RenderElementProps, RenderLeafProps, Slate } from 'slate-react';

import useInitEditor from '../../../Hooks/useInitEditor';
import { getFieldError } from '../../../Utils/getFieldErrors';
import SlateElement from './SlateElement';
import SlateLeaf from './SlateLeaf';

const RichTextInput: FC<FieldProps> = (props) => {
  const {
    field: { name, value },
    form: { setFieldValue, errors },
  } = props;
  const { editor } = useInitEditor();
  // TODO: Fix validation for empty rich text
  const error = getFieldError(errors, name);
  const renderElement = useCallback(
    ({ element, children, attributes }: RenderElementProps) => (
      <SlateElement element={element} attributes={attributes}>
        {children}
      </SlateElement>
    ),
    [],
  );
  const renderLeaf = useCallback(
    ({ children, attributes, text, leaf }: RenderLeafProps) => (
      <SlateLeaf attributes={attributes} text={text} leaf={leaf}>
        {children}
      </SlateLeaf>
    ),
    [],
  );

  const slateData = { value, initialValue: value, editor } as any;

  return (
    <Slate onChange={(v) => setFieldValue(name, v)} {...slateData}>
      <div
        className={`bg-slate-100 rounded-md text-sm px-4 py-3 font-semibold text-slate-900 text-opacity-80 cursor-text ${error}`}
      >
        <Editable renderElement={renderElement} renderLeaf={renderLeaf} />
      </div>
    </Slate>
  );
};

export default RichTextInput;
