import request from '../utils/request';

export enum ThreePLFilterType {
  shops = 'shops',
  integrations = 'integrations',
}

export interface ThreePLFilterListItem {
  id: string;
  name: string;
}

export type ThreePLFilterList = ThreePLFilterListItem[];

export const getThreePLFilterList = (filterType: ThreePLFilterType) => {
  return request<ThreePLFilterList>(`${process.env.OUTVIO_API_URL}/three-pl/filter/${filterType}`, {
    method: 'GET',
    secure: true,
  });
};
