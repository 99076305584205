import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { EDIT_MODE_TABS } from '../../../Interfaces/IEditModal';
import { ILanguageInputInfo, LanguageInputTypes } from '../../../Interfaces/ILanguageInputInfo';
import LanguageInputInfo from '../LanguageInputInfo';
import Base from './Base';

export const useSuggestedProducts = () => {
  const { t } = useTranslation('trackingPage', { keyPrefix: 'editMode.inputs' });
  const inputs: ILanguageInputInfo[] = [
    {
      title: t('suggestedProducts.titleProductsForYou'),
      objectKey: 'suggestedProducts.titleProductsForYou',
      type: LanguageInputTypes.TEXT,
    },
    {
      title: t('suggestedProducts.buttonShopNow'),
      objectKey: 'suggestedProducts.buttonShopNow',
      type: LanguageInputTypes.TEXT,
    },
  ];

  return {
    inputs,
  };
};

const SuggestedProducts: FC = () => {
  const { t } = useTranslation('trackingPage', { keyPrefix: 'editMode.modules' });
  const { inputs } = useSuggestedProducts();

  return (
    <Base title={t('suggestedProducts.title')} id={EDIT_MODE_TABS.SUGGESTED_PRODUCTS}>
      <h3 className="text-cyan-800 text-sm font-semibold mb-2">
        {t('orderStatus.titleAndButtons')}
      </h3>
      <span className="text-sm font-light mb-6 text-slate-900 text-opacity-80">
        {t('orderStatus.titleAndButtonsDescription')}
      </span>
      <LanguageInputInfo inputs={inputs} />
    </Base>
  );
};

export default SuggestedProducts;
