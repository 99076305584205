import React, { VFC, useContext, useEffect, useState } from 'react';

import TrackingPageContext from '../../../../Context/TrackingPageContext';
import {
  DisplayStep,
  OutvioStatusCodes,
  StatusStepsDisplay,
  StepStatus,
} from '../../../../Interfaces';
import StepRow from './StepRow';

interface StatusStepsProps {
  type: StatusStepsDisplay;
}

const useStatusSteps = ({ type }: StatusStepsProps) => {
  const { trackingData } = useContext(TrackingPageContext);
  const [visualSteps, setVisualSteps] = useState<DisplayStep[]>([]);
  useEffect(() => {
    if (trackingData) {
      const filteredSteps: DisplayStep[] = [];
      switch (type) {
        case 'categories':
          setVisualSteps(
            trackingData?.orderStatusInfo?.steps.filter(({ isSubStep }) => !isSubStep),
          );
          break;
        case 'incidents':
          trackingData?.orderStatusInfo?.steps.map((step) => {
            if (!step.isSubStep) {
              filteredSteps.push(step);
              return null;
            }
            if (
              [
                StepStatus.INCIDENT,
                StepStatus.BACKTRACK_INCIDENT,
                StepStatus.PAST_INCIDENT,
              ].includes(step.status)
            ) {
              filteredSteps.push(step);
            }
            return null;
          });
          setVisualSteps(filteredSteps);
          break;
        case 'full-steps':
        default:
          setVisualSteps(trackingData?.orderStatusInfo?.steps);
          break;
      }
    }
  }, [trackingData, type]);

  return { steps: visualSteps };
};

const removeDuplicatesDelShop = (steps: DisplayStep[]) => {
  const uniqueSteps: DisplayStep[] = [];
  steps?.forEach?.((step) => {
    const index = uniqueSteps.findIndex(
      (uniqueStep) =>
        (uniqueStep.displayCode === OutvioStatusCodes.DELIVERED_TO_SHOP ||
          uniqueStep.displayCode === OutvioStatusCodes.DELIVERED) &&
        uniqueStep.displayCode === step.displayCode,
    );
    if (index === -1) {
      uniqueSteps.push(step);
    }
  });
  return uniqueSteps;
};

const handleNewOrderStatus = (steps: DisplayStep[]) => {
  const firstStep = steps?.[0];
  const secondStep = steps?.[1];
  if (steps.length < 2 || !firstStep || !secondStep) {
    return steps;
  }
  return steps.map((step, i) => {
    if (i === 0) {
      return {
        ...step,
        isLast: secondStep?.status !== StepStatus.DONE,
        isLastDoneStep: secondStep?.status !== StepStatus.DONE,
        nextStatus: secondStep?.status === StepStatus.DONE ? StepStatus.DONE : StepStatus.PENDING,
      };
    }
    return step;
  });
};

const StatusSteps: VFC<StatusStepsProps> = ({ type }) => {
  const { steps } = useStatusSteps({ type });

  return (
    <div className="grid grid-cols-status-steps">
      {removeDuplicatesDelShop(handleNewOrderStatus(steps)).map((step, index) => (
        <StepRow key={`${step.code}-${index}`} step={step} />
      ))}
    </div>
  );
};

export default StatusSteps;
