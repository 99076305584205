import { IResponseOrderSearchResultItem } from 'app-types';
import {
  IResponseCourierStats,
  IResponseCustomerStats,
  IResponseDeskSidebarOrder,
  IResponseOrder,
} from 'app-types/src/order';
import { IResponseCourierDetail } from 'app-types/src/order';
import { SourceStep, SourceTrackingData } from 'tracking-fe/src';

import request from '../../request';

export const getCustomerOrderDetails = ({
  customerId,
  params,
}: {
  customerId: string;
  params: { fromDate?: string; ordersInfo: boolean };
}) => {
  const fromDate = params?.fromDate;
  const ordersInfo = params?.ordersInfo;
  return request<IResponseDeskSidebarOrder>(
    //@ts-ignore
    `${import.meta.env.VITE_OUTVIO_API_URL}/order/search/customer/${encodeURIComponent(
      customerId,
    )}?${fromDate ? 'fromDate=' + fromDate : ''}${ordersInfo ? '&ordersInfo=' + ordersInfo : ''}`,
    {
      method: 'GET',
      secure: true,
    },
  ).then((res) => res?.orders);
};

export const getCourierDetail = (idShipment: string) => {
  return request<IResponseCourierDetail>(
    //@ts-ignore
    `${import.meta.env.VITE_OUTVIO_API_URL}/order/info/${idShipment}`,
    {
      method: 'GET',
      secure: true,
    },
  ).then((res) => res?.data);
};

export const getTrackingData = ({
  otn,
  trackingLanguage,
}: {
  otn: string;
  trackingLanguage: string;
}): Promise<SourceTrackingData | undefined> =>
  request<{ tracking: SourceTrackingData }>(
    //@ts-ignore
    `${import.meta.env.VITE_OUTVIO_API_URL}/customer/order/${otn}/general-info`,
    {
      secure: true,
      method: 'GET',
      params: {
        trackingLanguage,
      },
    },
  ).then((res) => res?.tracking);

export type RequestTrackingInfo = {
  tracking: { steps: SourceStep[]; packages?: string[] };
};

export const getTrackingInfo = ({
  otn,
  trackingLanguage,
  cache = false,
}: {
  otn: string;
  trackingLanguage: string;
  cache?: boolean;
}): Promise<RequestTrackingInfo | undefined> =>
  //@ts-ignore
  request(`${import.meta.env.VITE_OUTVIO_API_URL}/customer/order/${otn}/track-info`, {
    secure: true,
    method: 'GET',
    params: { trackingLanguage, cache },
  } as any);

export type SplitOrderTrackingData = Array<{
  otn: string;
  products: any;
  discountApplied?: number;
  currency: string;
  tax: number;
  total: number;
  shipping: { price: number };
}>;

export const getSplitOrderTrackingData = ({
  otn,
  trackingLanguage,
}: {
  otn: string;
  trackingLanguage: string;
}): Promise<SplitOrderTrackingData | undefined> =>
  request<{ data: SplitOrderTrackingData }>(
    //@ts-ignore
    `${import.meta.env.VITE_OUTVIO_API_URL}/customer/order/${otn}/split-orders`,
    {
      method: 'GET',
      params: {
        trackingLanguage,
      },
    },
  ).then((res) => res?.data);

export const searchOrders = (input: string) =>
  request<IResponseOrderSearchResultItem>(
    //@ts-ignore
    `${import.meta.env.VITE_OUTVIO_API_URL}/order/search/${encodeURIComponent(input)}`,
    {
      method: 'GET',
      secure: true,
    },
  ).then((res) => res?.orders || []);

export const orderInfo = (orderId: string) =>
  request<IResponseOrder>(
    //@ts-ignore
    `${import.meta.env.VITE_OUTVIO_API_URL}/order/${encodeURIComponent(orderId)}/info`,
    {
      method: 'GET',
      secure: true,
    },
  ).then((res) => res?.data);

export const customerStats = (customerEmail: string) =>
  request<IResponseCustomerStats>(
    //@ts-ignore
    `${import.meta.env.VITE_OUTVIO_API_URL}/order/search/customer-stats/${encodeURIComponent(
      customerEmail,
    )}`,
    {
      method: 'GET',
      secure: true,
    },
  ).then((res) => res?.data);

export const courierStats = (courierEmail: string) =>
  request<IResponseCourierStats>(
    //@ts-ignore
    `${import.meta.env.VITE_OUTVIO_API_URL}/order/search/courier-stats/${encodeURIComponent(
      courierEmail,
    )}`,
    {
      method: 'GET',
      secure: true,
    },
  ).then((res) => res?.data);

export const cancelReturn = (orderId: string) =>
  request<{ success: boolean }>(
    //@ts-ignore
    `${import.meta.env.VITE_OUTVIO_API_URL}/order/cancel-return/${orderId}`,
    {
      method: 'DELETE',
      secure: true,
    },
  );

export type TDeskSearch = 'returns' | 'pickups' | 'orders';

export interface IDeskSearchResponse {
  success: boolean;
  results: Array<IDeskSearchResponseItem>;
  count: number;
}

export interface IDeskSearchResponseItem {
  returnData?: {
    hasExchange?: boolean;
  };
  internalStatus: string;
  createdAt: string;
  shipping: {
    courier: string;
  };
  currentStatus: string;
  otn: string;
  source: string;
  id: string;
  pickUpStatus?: 'failed' | 'done' | 'pending' | 'canceled';
}
export const searchDesk = ({
  type,
  q,
  limit,
  after,
}: {
  type: TDeskSearch;
  q: string;
  limit: number;
  after?: number;
}) =>
  request<IDeskSearchResponse>(
    //@ts-ignore
    `${import.meta.env.VITE_OUTVIO_API_URL}/search/${type}?q=${encodeURIComponent(
      q,
    )}&limit=${limit}&after=${after || 0}`,
    {
      method: 'GET',
      secure: true,
    },
  );
