import { Suspense, lazy, useEffect } from 'react';

import {
  QueryClient as LegacyQueryClient,
  QueryClientProvider as LegacyQueryClientProvider,
} from 'react-query';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useNavigate } from 'react-router-dom-v5-compat';
import { CompatRouter } from 'react-router-dom-v5-compat';

import { ApolloProvider } from '@apollo/client';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import 'cms/styles/index.css';
import { useReloadTabsOnImpersonate } from 'hooks/useReloadTabsOnImpersonate';
import { OVLocalCache } from 'return-portal-ui';
import { Loader } from 'ui';
import { apolloClient } from 'utils/graphql/apolloClient';

import OutvioMuiTheme from '../config/material-ui-theme';
import PageLoadErrorBoundary from './components/PageLoadErrorBoundary';

const AuthModule = lazy(() => import('./modules/AuthModule/module'));
const CmsModule = lazy(() => import('./modules/CmsModule/module'));
const AdminModule = lazy(() => import('./modules/AdminModule/module'));

const theme = createTheme(OutvioMuiTheme);

const queryClient = new QueryClient();

const legacyQueryClient = new LegacyQueryClient({
  defaultOptions: {
    queries: {
      // These are disabled for now, to not increase load on API for now
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});

// Temp solution for the initial auto-login
const RootRedirect = () => {
  const navigate = useNavigate();
  useEffect(() => {
    OVLocalCache.get('token')
      .then((token) => {
        if (token) {
          navigate(`/cms/dashboard${window.location.search}`);
        } else {
          navigate(`/auth/login${window.location.search}`);
        }
      })
      .catch(() => navigate(`/auth/login${window.location.search}`));
  }, []);

  return <Loader />;
};

const Routes = () => {
  useReloadTabsOnImpersonate();
  return (
    <MuiThemeProvider theme={theme}>
      {/* @ts-ignore */}
      <QueryClientProvider client={queryClient}>
        {/* @ts-ignore */}
        <LegacyQueryClientProvider client={legacyQueryClient}>
          <ApolloProvider client={apolloClient}>
            <BrowserRouter>
              <CompatRouter>
                <PageLoadErrorBoundary>
                  <Suspense fallback={<Loader />}>
                    <Switch>
                      <Route path="/" exact component={RootRedirect} />
                      <Route path="/auth" component={AuthModule} />
                      <Route path="/cms" component={CmsModule} />
                      <Route path="/admin" component={AdminModule} />
                    </Switch>
                  </Suspense>
                </PageLoadErrorBoundary>
              </CompatRouter>
            </BrowserRouter>
          </ApolloProvider>
        </LegacyQueryClientProvider>
      </QueryClientProvider>
    </MuiThemeProvider>
  );
};

export default Routes;
