export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
};

export interface ApiKeyImportInput {
  apiKey: Scalars['String']['input'];
  domain: Scalars['String']['input'];
  email: Scalars['String']['input'];
  months: Scalars['Float']['input'];
}

export enum Integration {
  Gorgias = 'gorgias',
  Zendesk = 'zendesk',
}

export interface Mutation {
  __typename?: 'Mutation';
  Gorgias_startImport: Scalars['Boolean']['output'];
  Zendesk_startImport: Scalars['Boolean']['output'];
}

export type MutationGorgias_StartImportArgs = {
  input: ApiKeyImportInput;
};

export type MutationZendesk_StartImportArgs = {
  input: ApiKeyImportInput;
};

export interface Query {
  __typename?: 'Query';
  importInStatus: Scalars['Boolean']['output'];
}

export type QueryImportInStatusArgs = {
  integration: Integration;
};
