import { Languages } from 'app-types';

import { DefaultLanguage } from '../../types';
import { useDefaultMessages } from '../useDefaultMessages';

export const getDeskLanguage = async (
  locale: DefaultLanguage | string,
): Promise<{ default: Record<string, string> }> => {
  switch (locale.slice(0, 2)) {
    case DefaultLanguage.en:
      return import('./en-EN');
    case DefaultLanguage.es:
      return import('./es-ES');
    case DefaultLanguage.pt:
      return import('./pt-PT');
    case DefaultLanguage.de:
      return import('./de-DE');
    case DefaultLanguage.pl:
      return import('./pl-PL');
    default:
      return { default: {} };
  }
};

export function useDeskMessages(language: Languages | string) {
  return useDefaultMessages(language, getDeskLanguage);
}
