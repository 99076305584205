export function UploadImageIcon({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em" {...props}>
      <g id="Group_16553" data-name="Group 16553" transform="translate(-481 -281)">
        <rect
          id="Rectangle_9497"
          data-name="Rectangle 9497"
          width="24"
          height="24"
          transform="translate(481 281)"
          fill="none"
        />
        <path
          id="Path_41539"
          data-name="Path 41539"
          d="M1623.467,4476h-8.755c-.567,0-.859,0-.992-.114a.477.477,0,0,1-.161-.4c.01-.17.217-.377.623-.784l8.028-8.028a2.445,2.445,0,0,1,.774-.633,1.031,1.031,0,0,1,.586,0,2.445,2.445,0,0,1,.774.633l3.655,3.655v1.134m-4.533,4.533a6.958,6.958,0,0,0,2.984-.312,2.7,2.7,0,0,0,1.237-1.237,6.962,6.962,0,0,0,.312-2.984m-4.533,4.533h-7.933a6.958,6.958,0,0,1-2.984-.312,2.706,2.706,0,0,1-1.237-1.237,6.965,6.965,0,0,1-.312-2.984v-7.934a6.967,6.967,0,0,1,.312-2.984,2.706,2.706,0,0,1,1.237-1.237,6.968,6.968,0,0,1,2.984-.312h7.933a6.968,6.968,0,0,1,2.984.312,2.7,2.7,0,0,1,1.237,1.237,6.965,6.965,0,0,1,.312,2.984v7.934m-9.917-7.272a1.889,1.889,0,1,1-1.889-1.889A1.894,1.894,0,0,1,1618.083,4464.194Z"
          transform="translate(-1126.5 -4174.5)"
          fill="rgba(255,255,255,0)"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          opacity="0.82"
        />
      </g>
    </svg>
  );
}
