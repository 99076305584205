import request from '../../utils/request';

export const invoiceFeatureRequest = () => {
  return request<{ success: boolean }>(
    `${process.env.OUTVIO_API_URL}/invoice/request-feature-invoice-checking`,
    {
      method: 'POST',
      secure: true,
    },
  );
};
