import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Field } from 'formik';

import { EDIT_MODE_TABS } from '../../../Interfaces/IEditModal';
import EditColorSelector from '../../Common/Inputs/EditColorSelector';
import BackgroundUploader from '../BackgroundUploader';
import Base from './Base';

const ColorPalette: FC = () => {
  const { t } = useTranslation('trackingPage', { keyPrefix: 'editMode.modules.colorPalette' });
  return (
    <Base title={t('description')} id={EDIT_MODE_TABS.COLOR_PALETTE}>
      <div className="flex flex-col space-y-[24px] divide-y divide-slate-200">
        <div className="w-full ">
          <div className="grid grid-cols-3 gap-y-2">
            <Field component={EditColorSelector} name="colors.header" />
            <Field component={EditColorSelector} name="colors.primaryText" />
            <Field component={EditColorSelector} name="colors.btnBg" />
            <Field component={EditColorSelector} name="colors.secondaryBg" />
          </div>
        </div>
        <div className="pt-6">
          <h2 className="text-cyan-800 text-lg font-bold mb-8">{t('backgroundTitle')}</h2>
          <BackgroundUploader />
        </div>
      </div>
    </Base>
  );
};

export default ColorPalette;
