import { Query } from 'app-types/desk-analytics';

import { DAILY_TICKETS_COUNT_TICKETS_PER_DAY } from '../../../gql/desk-analytics/queries';
import { useQueryDeskAnalytics } from '../useQueryDeskAnalytics';

export function useGetDailyTicketPerDay({
  from,
  to,
  tags,
  channels,
}: {
  from?: string;
  to?: string;
  tags?: string[];
  channels?: string[];
}) {
  const { data, loading, error } = useQueryDeskAnalytics<Query>(
    DAILY_TICKETS_COUNT_TICKETS_PER_DAY,
    {
      skip: !from || !to,
      variables: { from, to, tags, channels },
    },
  );
  return {
    data: data?.DailyTickets_countTicketsPerDay,
    loading,
    error,
  };
}
