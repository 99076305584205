import React from 'react';
import { useContext } from 'react';

import { StarRateRounded } from '@mui/icons-material';
import { OVCButton, getCalculatedBrightnessFromHex, useGetPaletteColors } from 'return-portal-ui';
import { twMerge } from 'tailwind-merge';
import { Copy, Heading } from 'ui/atoms';

import TrackingPageContext from '../../../Context/TrackingPageContext';
import { EDIT_MODE_TABS } from '../../../Interfaces';
import Card from '../../Common/Card';
import EditButton from '../../Common/EditButton';

type EstimatedDeliveryDateBlockProps = {
  toggleRatingModal(value: boolean): void;
  isModalButton: boolean;
};

const EstimatedDeliveryDateBlock = ({
  toggleRatingModal,
  isModalButton,
}: EstimatedDeliveryDateBlockProps) => {
  const {
    handleRate,
    trackingData,
    appSettings,
    portalSettings: {
      regionalSettings: {
        orderStatusSteps,
        orderStatusInfo,
        rating: { rateButton },
      },
    },
    isDeleted,
  } = useContext(TrackingPageContext);

  const rating = trackingData?.rating;

  const { secondaryBg, primaryText, btnBg } = useGetPaletteColors();

  const lastStep = trackingData?.orderStatusInfo?.steps?.find((x) => x.isLastDoneStep);
  const isIncident = trackingData?.orderStatusInfo?.isIncident;

  const formattedDate = trackingData?.estimatedDeliverDate
    ? Intl.DateTimeFormat(appSettings.lang, {
        month: 'short',
        day: 'numeric',
      }).format(new Date(trackingData.estimatedDeliverDate))
    : '-';

  const scrollToOrderDetails = () => {
    const element = document.getElementById('order-details');
    if (element) {
      const offset = 83;
      const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  const inputBgL2 = getCalculatedBrightnessFromHex(secondaryBg, 2);

  const estimatedDeliveryTimeColor = () => {
    if (isIncident || isDeleted) {
      if (trackingData?.estimatedDeliverDate || isDeleted) {
        return { textColor: 'text-v2orange-200', bgColor: 'bg-v2orange-200' };
      } else {
        return { textColor: primaryText, bgColor: primaryText };
      }
    }

    return { textColor: btnBg, bgColor: btnBg };
  };

  function renderModalButton() {
    if (isModalButton && !rating && handleRate) {
      return (
        <OVCButton
          className="w-auto pr-4"
          onClick={() => toggleRatingModal(true)}
          icon={<StarRateRounded />}
        >
          {rateButton}
        </OVCButton>
      );
    }

    return null;
  }

  return (
    <Card className="p-6 h-[329px] flex flex-col relative w-full">
      <div className="pl-2 flex items-center justify-between">
        <Heading style={{ color: primaryText }}>{orderStatusInfo?.title}</Heading>
        {renderModalButton() || (
          <OVCButton
            onClick={scrollToOrderDetails}
            style={{ color: primaryText }}
            className="pl-3 pr-4"
            theme="secondary"
          >
            {orderStatusInfo?.seeOderDetails}
          </OVCButton>
        )}
      </div>
      <Copy as="div" style={{ color: primaryText }} className="mt-8 ml-4 opacity-60">
        {orderStatusInfo?.lastUpdate} {lastStep?.dateTime}
      </Copy>
      <Heading
        type="heading8"
        style={{ color: !isIncident && !isDeleted ? primaryText : '' }}
        className={twMerge('mt-[9px] ml-4', isIncident || isDeleted ? 'text-v2orange-200' : '')}
      >
        {isDeleted
          ? orderStatusSteps?.DELETED_SHIPMENT || 'Shipment cancelled'
          : lastStep && orderStatusSteps[lastStep?.displayCode]}
      </Heading>

      <div style={{ borderColor: inputBgL2 }} className="mt-10 border-b mb-[33px]" />

      <div className="ml-4 h-[62px] flex items-center justify-between">
        <div style={{ color: primaryText }} className="flex-1 flex flex-col">
          <Copy as="div" noColor className="ml-2 opacity-60">
            {orderStatusInfo?.estimatedDeliveryDate}
          </Copy>

          <Heading
            type="heading7"
            className={twMerge('mt-2 ml-2')}
            style={{ color: estimatedDeliveryTimeColor().textColor }}
          >
            {formattedDate}
          </Heading>
        </div>

        <div
          style={{ backgroundColor: estimatedDeliveryTimeColor().bgColor }}
          className={twMerge('w-1 rounded-[12px] h-full')}
        />
      </div>
      {appSettings.isEdit && <EditButton tab={EDIT_MODE_TABS.ORDER_STATUS} />}
    </Card>
  );
};

export default EstimatedDeliveryDateBlock;
