import { StoreBranding } from 'return-portal-ui/old-ui/types/StoreBranding';
import {
  CustomReturnReason,
  ReturnReimbursement,
  StoreSettingsReturnChargePolicy,
  StoreSettingsReturnCourierPolicy,
  StoreSettingsReturnCustomerMethods,
  StoreSettingsReturnWarehousePolicy,
  StoreSettingsReturnWarehouseRule,
  Warehouse,
} from 'return-portal-ui/old-ui/types/StoreSettings';

import type {
  IPortalProduct,
  IPortalReturnMethod,
  IPortalReturnMethods,
  IReturnAddress,
} from './return-portal/return-portal';

export type Language = 'es-ES' | 'en-EN' | 'de-DE' | 'pl-PL' | 'pt-PT';

export enum Languages {
  es = 'es-ES',
  en = 'en-EN',
  de = 'de-DE',
  pl = 'pl-PL',
  pt = 'pt-PT',
}

export interface IResponseFileUpload {
  fieldname: string;
  originalname: string;
  encoding: string;
  mimetype: string;
  size: number;
  bucket: string;
  key: string;
  acl: string;
  contentType: string;
  contentDisposition?: any;
  storageClass: string;
  serverSideEncryption?: any;
  metadata: {
    fieldName: string;
  };
  location: string;
  etag: string;
}

export enum AmazonIntegrationRegions {
  europe = 'eu-west-1',
  northAmerica = 'us-east-1',
}

export type Cmses =
  | 'aliExpress'
  | 'allegro'
  | 'api'
  | 'amazon'
  | 'amazonApp'
  | 'ebay'
  | 'ecwid'
  | 'epages'
  | 'etsy'
  | 'magento'
  | 'magento2'
  | 'odoo'
  | 'prestashop'
  | 'salesforce'
  | 'shopify'
  | 'squarespace'
  | 'wordpress'
  | 'manomano'
  | 'logiCommerce'
  | 'openCart2'
  | 'openCart3'
  | 'shopifyApp'
  | 'vivino'
  | 'voog'
  | 'wix'
  | 'xls'
  | 'shopware5'
  | 'shopware6'
  | 'katana'
  | 'pulpo'
  | 'vtex'
  | 'bigCommerce'
  | 'logisFashion';

export interface Integration {
  _id: string;
  store: string;
  type: Cmses;
  isActive: boolean;
  deleted: boolean;
  isBlocked: boolean;
  lastConnection: Date;
  endpoint: string;
  config: any;
  alias: string;
}

export interface IntegrationAvailable {
  name: string;
  logo: string;
  integration: string;
  featured: boolean;
  version?: string;
}

export enum StoreSettingsGroupProductsBy {
  SKU = 'sku',
  BARCODE = 'barcode',
}

export interface StoreSettingsPopulated {
  _id: string;
  warehouses: Warehouse[];
  is3pl: boolean;
  scanAndPack: {
    processSingleMultiSeparately: boolean;
    printAutomatically: boolean;
    allowManualPicking: boolean;
    groupProductsBy: StoreSettingsGroupProductsBy;
    allowScanPackaging: boolean;
    showOrderMessage?: boolean;
    matchingScannedNumber?: string;
  };
  return: {
    days: number;
    chargePolicy: StoreSettingsReturnChargePolicy;
    defaultPrice?: number;
    warehousePolicy: StoreSettingsReturnWarehousePolicy;
    selectedWarehouse?: string;
    warehouseRules: StoreSettingsReturnWarehouseRule[];
    customerAllowedMethods: StoreSettingsReturnCustomerMethods[];
    returnCourierPolicy: StoreSettingsReturnCourierPolicy;
    selectedReturnCourier?: number; // use it with SELECTED_COURIER as default value
    selectedReturnMethod?: number; // use it with SELECTED_COURIER as default value
    usePickupByDefault: boolean;
    toClientShopUrl?: string;
    useCustomReasons: boolean;
    disableDefaultReasons: boolean;
    customReasons: CustomReturnReason[];
    reimbursement: ReturnReimbursement;
    freeShippingOnCredit: boolean;
    termsAndConditionsLink?: string;
    customerCanDefineReturnPackaging: boolean;
    hasButtonToManageProductExternally: boolean;
    externalButtonLink: string;
  };
  storeBrandings: StoreBranding[];
  currency: string;
  clientCode: string;
  extensionsList: {
    [key in Cmses]: {
      cms: Cmses;
      featured?: boolean;
      logo: string;
      instructionUrls?: {
        [key: string]: string;
      };
      name: string;
      url?: string;
      url_EN?: string;
      plugin?: string;
    };
  };
  apiKey?: string;
}

export interface PostOfficeAddress {
  courier: string;
  id: string; // to use when send shipments
  name: string;
  address: string;
  postcode: string;
  city: string;
  country: string;
  province: string;
  countryCode: string;
  pickupTime: {
    from: string;
    to: string;
  };
  contactName: string;
  contactPhone: string;
  comments: string;
  dni: string;
  offloadPostcode: string;
  schedule: string;
  routingcode: string; // for SmartPost
}

export { IPortalProduct, IPortalReturnMethods, IPortalReturnMethod, IReturnAddress };
