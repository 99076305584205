import { OVCAuthTrackOrderCTA, OVCInput, useGetRegionalTranslationsV2 } from 'return-portal-ui';

const AuthTrackingPreview = () => {
  const regionalTranslations = useGetRegionalTranslationsV2();

  return (
    <form className="w-full flex flex-col gap-2 mt-6">
      <OVCInput
        placeholder={regionalTranslations?.loginPageInfo?.inputInsertIdOrTrackingNumber || ''}
        name="orderIdentifier"
        centeredPlaceholderText
      />
      <OVCInput
        placeholder={regionalTranslations?.loginPageInfo?.inputInsertDestinationPostcode || ''}
        name="postcode"
        centeredPlaceholderText
      />
      <OVCAuthTrackOrderCTA type="button" className="mt-6" />
    </form>
  );
};

export default AuthTrackingPreview;
