export function CreditScore({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 -960 960 960"
      fill="currentColor"
      {...props}
    >
      <path d="M160-623.845h640v-83.846q0-4.616-3.846-8.463-3.847-3.846-8.463-3.846H172.309q-4.616 0-8.463 3.846-3.846 3.847-3.846 8.463v83.846Zm-59.999-83.846q0-30.308 21-51.308t51.308-21h615.382q30.308 0 51.308 21t21 51.308v211.536H160v243.846q0 4.616 3.846 8.463 3.847 3.846 8.463 3.846h145.155q12.769 0 21.384 8.615t8.615 21.384q0 12.769-8.615 21.384-8.615 8.616-21.384 8.616H172.309q-30.308 0-51.308-21t-21-51.308v-455.382Zm59.999 0V-240v-143.77 65.231V-720v12.309Zm431.846 512.922 205.077-205.078q8.923-8.923 21.192-8.807 12.269.115 21.576 9.423 8.693 9.307 9 21.076.308 11.769-9 21.076L617.153-134.54q-10.846 10.846-25.307 10.846-14.462 0-25.308-10.846L456.769-244.309q-8.307-8.308-8.807-20.577-.5-12.269 8.807-21.576 8.692-8.692 20.769-8.692 12.077 0 21.384 8.692l92.924 91.693Z" />
    </svg>
  );
}
