import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, Operation, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import { IGraphQLErrorExtensions } from 'src/ts/graphql-custom';

const defaultClient: keyof typeof clients = 'returns';

const clients = {
  returns: new HttpLink({
    uri: window.VITE_RETURNS_API || `${process.env.OUTVIO_API_URL}/returns/graphql`,
  }),
  notifications: new HttpLink({
    uri: window.VITE_NOTIFICATIONS_API || `${process.env.OUTVIO_API_URL}/notifications/graphql`,
  }),
  deskAnalytics: new HttpLink({
    uri: window.VITE_DESK_ANALYTICS_API || `${process.env.OUTVIO_API_URL}/desk-analytics/graphql`,
  }),
  desk: new HttpLink({
    uri: window.VITE_DEV_DESK_API
      ? `${window.VITE_DEV_DESK_API}/graphql`
      : `${process.env.OUTVIO_API_URL}/desk/graphql`,
  }),
  core: new HttpLink({
    uri: `${process.env.OUTVIO_API_URL}/graphql`,
  }),
  deskMigrate: new HttpLink({
    uri: window.VITE_DESK_MIGRATE_API
      ? `${window.VITE_DESK_MIGRATE_API}/graphql`
      : `${process.env.OUTVIO_API_URL}/desk-migrate/graphql`,
  }),
};

const isRequestedClient = (clientName: string) => (op: Operation) =>
  op.getContext().clientName === clientName;

const ClientResolverLink = Object.entries(clients)
  .map(([clientName, Link]) => [clientName, ApolloLink.from([Link])] as const)
  .reduce(
    ([, PreviousLink], [clientName, NextLink]) => {
      const ChainedLink = ApolloLink.split(isRequestedClient(clientName), NextLink, PreviousLink);

      return [clientName, ChainedLink];
    },
    ['_default', clients[defaultClient]],
  )[1];

declare module '@apollo/client' {
  interface DefaultContext {
    clientName?: keyof typeof clients;
    headers?: Record<string, any>;
  }
}

const authLink = new ApolloLink((operation, forward) => {
  const token = window.localStorage.getItem('token');
  const impersonateToken = window.localStorage.getItem('impersonateToken');
  const _t = impersonateToken || token || '';
  operation.setContext((context: Record<any, any>) => ({
    ...context,
    headers: {
      ...(context.headers ? context.headers : {}),
      'x-access-token': JSON.parse(_t),
    },
  }));

  return forward(operation);
});

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ extensions }: { extensions: IGraphQLErrorExtensions }) => {
      if (extensions?.response?.statusCode === 401) {
        return window.location.assign('/auth/logout');
      }
    });
  }
});

const cache = new InMemoryCache();

export const apolloClient = new ApolloClient({
  link: from([authLink, errorLink, ClientResolverLink]),
  cache,
  connectToDevTools: process.env.NODE_ENV === 'development',
});
