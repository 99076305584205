export interface ICourier {
  name: string;
  integration: string;
  alias: string;
  logo: string;
  smallLogo?: string;
}

export interface CourierType {
  _id: string;
  id: number;
  name: string; //DHL Express
  integration: string; // dhl
  alias: string; // DHL-EX
  shipping_endpoint: null | string;
  tracking_endpoint: null | string;
  pickup_endpoint: null | string;
  rate_endpoint: null | string;
  virtual: boolean;
  allowed_countries: string[]; // 2 letter
  available_countries: CourierCountry[];
}

export interface CourierTypeWithMethods extends CourierType {
  methods: Method[];
}

export interface CourierCountry {
  id: number;
  name: string;
  code: string; // 2 letter, ES
  vat: number;
  language: string; // 2 letter, es
}

export interface Method {
  _id: string;
  type: string;
  name: string;
  support_returns: boolean;
  RateFiles: {
    _id: string;
    courier: string;
    isSampleRate: boolean;
    method: string;
    originalFilename: string;
    url: string;
  };
}

export interface Courier {
  id: number;
  _id: string;
  alias: string | null;
  credentials: any;
  characteristics: any;
  is_default: boolean;
  active: boolean;
  domestic_returns?: boolean;
  international_returns?: boolean;
  is_duplicate: boolean | null;
  is_blocked: boolean;
  contact_info: any;
  standard_rate: null | string;
  express_rate: null | string;
  country: CourierCountry;
  valid_credentials: boolean;
  warehouses: string[];
  methods: Method[];
}

export interface CourierReturn {
  _id: string;
  country?: string;
  CourierType: {
    _id: string;
    id?: number;
    name: string;
    integration: string;
    virtual: boolean;
    allowed_countries: string[];
    methods: string[];
    alias: string;
    credentials: Array<{
      field: string;
      type: string;
      required: boolean;
      translations: {
        EN: string;
        DE: string;
        ES: string;
        PL: string;
        PT: string;
      };
    }>;
    logo: string;
    settings: string[];
    surcharges: string[];
  };
  id: number;
  original_characteristics?: {
    addCostPerRemoteZone?: boolean;
    reverseSurcharge?: {
      international: number;
      domestic: number;
    };
    addReverseCosts?: number;
    dimensions?: {
      box_dimensions: any;
      weight: any;
    };
    isAuto?: boolean;
    cod?: {
      percentage: number;
      rate: number;
    };
    insurance?: {
      percentage: number;
      min: number;
    };
    specialZonesCosts?: {
      highRiskCountry: number;
      unRestrictionsCountry: number;
      euRestrictionsCountry: number;
    };
  };
  is_default: boolean;
  contact_info?: {
    premium: {
      phone?: string;
      email: string;
    };
    standard?: {
      email: string;
      phone?: string;
    };
  };
  alias?: string;
  valid_credentials: boolean;
  is_blocked: boolean;
  international_returns: boolean;
  domestic_returns: boolean;
  active: boolean;
  __v: number;
  methods: Array<{
    _id: string;
    id?: number;
    type: string;
    name: string;
    returnOptions: Array<{
      _id: string;
      id: number;
      name: string;
      __v: number;
    }>;
    supportReturns: boolean;
    __v: number;
    alias: string;
  }>;
  characteristics?: {
    supportCustomReference?: boolean;
    internalCourierName?: string;
    isAuto?: boolean;
  };
  credentials?: string;
}

export interface PopulatedCourier extends Courier {
  CourierType: CourierType;
  method: Method;
}

export enum FedexSignatureImageType {
  letterhead = 'IMAGE_1',
  signature = 'IMAGE_2',
}

export interface FedexCustomSignature {
  file: File;
  courierId: string;
  imgType: FedexSignatureImageType;
}

export interface DHLPacketGermanyUploadData {
  file: File;
  courierId: string;
}

export interface CourierStatistics {
  _id: string;
  __v: number;
  courierName: string;
  deliveryAttempts: number;
  domestic: number;
  international: number;
  customerExperience: number;
  incidents: number;
  requestRate: boolean;
}

export interface CouriersList {
  _id: string;
  name: string;
  alias: string;
  integration: string;
  courierName: string;
  courierAlias: string;
  active?: boolean;
  onlyTracking?: boolean;
  couriers: string[];
  thirdPartyCourier?: boolean;
  statistics: CourierStatistics;
}

export interface CouriersListExtended extends CouriersList {
  countryCode: string;
  thirdPartyCourier: boolean;
  courierName: string;
}

export type TRates = Record<string, IMethodWeights[]>;

export interface IZoneWeight {
  price: number;
  weight: number;
}

export interface IMethodWeights {
  name: string;
  weight: IZoneWeight[];
}

export type TMethodCountries = Record<string, string[]>;
export type TRatesCountries = Record<string, TMethodCountries>;

export interface IRatesResponse {
  rates: TRates;
  countries: TRatesCountries;
}
export interface IOrderProofOfDeliveryResponse {
  success: boolean;
  pods: Array<{
    otn: string;
    url: string;
  }>;
}

export interface ICourierOauthSignUpResponse {
  redirectUri: string;
  success: boolean;
}
