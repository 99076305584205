import { useQuery } from '@apollo/client';
import { QueryHookOptions } from '@apollo/client/react/types/types';
import { Query } from 'app-types/desk';

import { GET_SUPPORT_TICKET_QUERY } from './queries';

type ResultData = { ticket: Query['Ticket_GetOneSupport'] };

export function useSupportTicketById(id?: string, options?: QueryHookOptions<ResultData>) {
  return useQuery<ResultData>(GET_SUPPORT_TICKET_QUERY, {
    variables: { id },
    skip: !id,
    pollInterval: 120000, // each 2 mins
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'network-only',
    ...options,
    context: { ...options?.context, clientName: 'desk' },
  });
}
