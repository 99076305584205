const sizeNormalStyles = {
  control: (styles: any, { isDisabled, isFocused }: any) => ({
    ...styles,
    backgroundColor: '#fff',
    borderColor: isDisabled ? '#eff5f6' : isFocused ? '#4bb4b3' : '#a6dad6',
    boxShadow: 'none', // To get rid of obnoxious blue focus outline
    borderRadius: '12px',
    '&:hover': {
      borderColor: isFocused ? '#4bb4b3' : '#a6dad6',
    },
    height: '25px',
    minHeight: '25px',
  }),
  container: (styles: any) => ({
    ...styles,
    width: '100%',
  }),
  dropdownIndicator: (styles: any, { isFocused, isDisabled }: any) => ({
    ...styles,
    padding: '0 8px',
    fontSize: '22px',
    color: isDisabled ? '#eff5f6' : isFocused ? '#4bb4b3' : '#a6dad6',
    '&:hover': {
      color: '#4bb4b3',
    },
  }),
  clearIndicator: (styles: any) => ({
    ...styles,
    padding: '0 8px',
  }),
  input: (styles: any) => ({
    ...styles,
    margin: 0,
    paddingBottom: 0,
    paddingTop: 0,
  }),
  placeholder: (styles: any) => ({
    ...styles,
    fontSize: '13px',
    color: '#acbbbc',
    whiteSpace: 'nowrap',
  }),
  indicatorSeparator: (styles: any) => ({
    ...styles,
    display: 'none',
  }),
  valueContainer: () => ({
    flex: 1,

    padding: '0 8px',
    fontSize: '13px',
    lineHeight: '24px',
    color: '#173c40',

    height: '24px',
    display: 'inline-block',

    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
  singleValue: (styles: any) => ({
    ...styles,
    color: '#173c40',
    maxWidth: 'calc(100% - 34px)',
  }),
  menu: (styles: any) => ({
    ...styles,
    borderRadius: '5px',
  }),
  menuPortal: (base: any) => ({ ...base, zIndex: 500000001 }),
  option: (styles: any, { isSelected, isFocused, isDisabled }: any) => ({
    ...styles,
    backgroundColor: isSelected ? '#4bb4b3' : isFocused ? '#dbf0f0' : '#fff',
    color: isDisabled ? '#ACBBBC' : isSelected ? '#fff' : '#4bb4b3',
    fontSize: '13px',
  }),
};

const sizeNormalErrorStyles = {
  ...sizeNormalStyles,
  control: (styles: any) => ({
    ...styles,
    backgroundColor: '#fff',
    borderColor: '#f3135e', // isDisabled ? '#eff5f6' : '#f3135e',
    boxShadow: 'none', // To get rid of obnoxious blue focus outline
    borderRadius: '12px',
    '&:hover': {
      borderColor: '#f3135e',
    },
    height: '25px',
    minHeight: '25px',
  }),
  dropdownIndicator: (styles: any, { isDisabled }: any) => ({
    ...styles,
    padding: '0 8px',
    fontSize: '22px',
    color: isDisabled ? '#eff5f6' : '#f3135e',
    '&:hover': {
      color: '#f3135e',
    },
  }),
};

const sizeMobileNormalStyles = {
  ...sizeNormalStyles,
  control: (styles: any, { isDisabled, isFocused }: any) => ({
    ...styles,
    backgroundColor: '#fff',
    borderColor: isDisabled ? '#eff5f6' : isFocused ? '#4bb4b3' : '#a6dad6',
    boxShadow: 'none', // To get rid of obnoxious blue focus outline
    borderRadius: '12px',
    '&:hover': {
      borderColor: isFocused ? '#4bb4b3' : '#a6dad6',
    },
    height: '34px',
    minHeight: '34px',
  }),
  placeholder: (styles: any) => ({
    ...styles,
    fontSize: '16px',
    color: '#acbbbc',
    whiteSpace: 'nowrap',
  }),
  valueContainer: () => ({
    flex: 1,

    padding: '0 8px',
    fontSize: '13px',
    lineHeight: '24px',
    color: '#173c40',

    height: '24px',
    display: 'inline-block',

    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
  option: (styles: any, { isSelected, isFocused, isDisabled }: any) => ({
    ...styles,
    backgroundColor: isSelected ? '#4bb4b3' : isFocused ? '#dbf0f0' : '#fff',
    color: isDisabled ? '#ACBBBC' : isSelected ? '#fff' : '#4bb4b3',
    fontSize: '16px',
  }),
};

const sizeMobileNormalErrorStyles = {
  ...sizeMobileNormalStyles,
  control: (styles: any) => ({
    ...styles,
    backgroundColor: '#fff',
    borderColor: '#f3135e', // isDisabled ? '#eff5f6' : '#f3135e',
    boxShadow: 'none', // To get rid of obnoxious blue focus outline
    borderRadius: '12px',
    '&:hover': {
      borderColor: '#f3135e',
    },
    height: '34px',
    minHeight: '34px',
  }),
  dropdownIndicator: (styles: any, { isDisabled }: any) => ({
    ...styles,
    padding: '0 8px',
    fontSize: '22px',
    color: isDisabled ? '#eff5f6' : '#f3135e',
    '&:hover': {
      color: '#f3135e',
    },
  }),
};

export const normalSizeStyles = sizeNormalStyles;
export const normalSizeErrorStyles = sizeNormalErrorStyles;
export const mobileNormalSizeStyles = sizeMobileNormalStyles;
export const mobileNormalSizeErrorStyles = sizeMobileNormalErrorStyles;
