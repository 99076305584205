import React from 'react';

import { useGetPaletteColors } from '../hooks';

const OVCLayoutContainer = ({ children }: { children: React.ReactNode }) => {
  const { header, primaryText } = useGetPaletteColors();

  return (
    <div
      className="w-full min-h-screen flex flex-col"
      style={{
        backgroundColor: header,
        color: primaryText,
      }}
      data-cy="ovc-layout-container"
    >
      {children}
    </div>
  );
};

export default OVCLayoutContainer;
