import { gql, useQuery } from '@apollo/client';
import { Query } from 'app-types/returns';

export function useReturnReasonGetById(id?: string, options?: any) {
  const { data, loading, error } = useQuery<Query>(
    gql`
      query ReturnReason_GetById($returnReasonGetByIdId: String!) {
        ReturnReason_GetById(id: $returnReasonGetByIdId) {
          id
          labels {
            language
            body
          }
        }
      }
    `,
    {
      variables: { returnReasonGetByIdId: id },
      skip: !id,
      ...options,
    },
  );
  return {
    data: data?.ReturnReason_GetById,
    loading,
    error,
  };
}
