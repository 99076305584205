import { useMutation } from '@tanstack/react-query';

import { type CreateCustomDomainByStoreData, createCustomDomainByStore } from '../mutations';

export type CreateCustomDomainByStoreProps = {
  storeBrandingId: string;
  data: CreateCustomDomainByStoreData;
};

export function useCreateCustomDomainByStore() {
  return useMutation(({ storeBrandingId, data }: CreateCustomDomainByStoreProps) =>
    createCustomDomainByStore(storeBrandingId, data),
  );
}
