export interface IGoogleMapsColors {
  mapBase: string;
  mapWater: string;
  mapPath: string;
  mapDivisor: string;
  mapText: string;
  mapPin: string;
}

export enum ColorTypes {
  TEXT = '#101010',
  BASE = '#edf7f8',
  WATER = '#c7dde4',
  STROKE = '#FFFFFF',
  BRIGHT_STROKE = '#FFFFFF',
  DARK_STROKE = '#000000',
  PATH = '#283740',
  PIN = '#5794f1',
}
