import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { FieldProps } from 'formik';

import ColorSelector from './ColorSelector';

const EditColorSelector: FC<FieldProps> = (props) => {
  const {
    field: { name, value },
    form: { setFieldValue },
  } = props;
  const updateValue = (newValue: string) => setFieldValue(name, newValue);
  const { t } = useTranslation('trackingPage', { keyPrefix: 'colors' });

  return (
    <div className="flex">
      <ColorSelector color={value} onChange={(colorResult) => updateValue(colorResult.hex)} />
      <div className="flex flex-col text-sm ml-4 text-slate-700">
        <span className="font-semibold mb-1">{t(name.split('.')[1])}</span>
        <span className="font-light">{value}</span>
      </div>
    </div>
  );
};

export default EditColorSelector;
