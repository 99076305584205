import React, { FC } from 'react';

import { ILanguageInputInfo } from '../../Interfaces/ILanguageInputInfo';
import LanguageInputContainer from './LanguageInputContainer';

interface LanguageInputInfo {
  inputs: ILanguageInputInfo[];
}

const LanguageInputInfo: FC<LanguageInputInfo> = (props) => {
  const { inputs } = props;
  if (!inputs) return null;

  return (
    <div className="flex flex-col space-y-6">
      {inputs.map(({ title, objectKey }, index) => (
        <div className="grid md:grid-cols-2" key={`${objectKey}${title}${index}`}>
          <div className="text-sm font-light mb-6 text-slate-900 text-opacity-80">{title}</div>
          <LanguageInputContainer objectKey={objectKey} />
        </div>
      ))}
    </div>
  );
};

export default LanguageInputInfo;
