import React, { FC } from 'react';

import _get from 'lodash.get';

import { FieldProps } from 'formik';

import { getFieldError } from '../../../Utils/getFieldErrors';

const TextareaInput: FC<FieldProps> = (props) => {
  const {
    field: { name, value },
    form: { setFieldValue, initialValues, errors },
  } = props;
  const placeholder = _get(initialValues, name);
  const error = getFieldError(errors, name);

  return (
    <textarea
      className={`bg-slate-100 rounded-md text-sm px-4 py-3 font-semibold text-slate-900 text-opacity-80 ${error}`}
      value={value}
      name={name}
      id={name}
      placeholder={placeholder}
      onChange={({ target: { value: newValue } }) => setFieldValue(name, newValue)}
      rows={5}
    />
  );
};

export default TextareaInput;
