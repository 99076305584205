import { useEffect } from 'react';

import { useStoreSettings } from 'data-layer';

export const LSWaitApprovalKey = 'waitApproval';

const redirectUrl = '/v2/welcome/account-review';

export function useValidateStore() {
  const { data, loading } = useStoreSettings();
  const approved = data?.approved;
  const hasPendingOnboarding = data?.hasPendingOnboarding;

  useEffect(() => {
    if (loading || hasPendingOnboarding) return;
    let keyExists;
    try {
      keyExists = localStorage.getItem(LSWaitApprovalKey);
    } catch (err) {
      //
    }

    if ((!approved || keyExists) && window.location.pathname !== redirectUrl) {
      window.location.href = redirectUrl;
    }
  }, [approved, loading, hasPendingOnboarding]);
}
