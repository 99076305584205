import moment from 'moment';

import { TrackingStep } from '../types/Tracking';

type SimpleStep = {
  id: string;
  status: string;
  active: boolean;
  hasWarning: boolean;
  location: string | null;
  date: string;
  touched: boolean;
};

const normalizeStatus = (status: string | null | undefined) =>
  String(status).toUpperCase().replace(/\s/g, '_');

export const emptySimpleSteps: SimpleStep[] = [
  {
    id: 'CREATED', // key for simple step
    status: 'CREATED', // status label, will be translated in-component
    active: false,
    hasWarning: false, // currently only used by EXCEPTION status
    location: '',
    date: '',
    touched: true, // to know if we already got this step during processing
  },
  {
    id: 'SHIPPED',
    status: 'SHIPPED',
    active: false,
    hasWarning: false,
    location: '',
    date: '',
    touched: false,
  },
  {
    id: 'IN_TRANSIT',
    status: 'IN_TRANSIT',
    active: false,
    hasWarning: false,
    location: '',
    date: '',
    touched: false,
  },
  {
    id: 'OUT_FOR_DELIVERY',
    status: 'OUT_FOR_DELIVERY',
    active: false,
    hasWarning: false,
    location: '',
    date: '',
    touched: false,
  },
  {
    id: 'DELIVERED',
    status: 'DELIVERED',
    active: false,
    hasWarning: false,
    location: '',
    date: '',
    touched: false,
  },
];
export const emptySimpleStepsDelpu: SimpleStep[] = [
  {
    id: 'CREATED', // key for simple step
    status: 'CREATED', // status label, will be translated in-component
    active: false,
    hasWarning: false, // currently only used by EXCEPTION status
    location: '',
    date: '',
    touched: true, // to know if we already got this step during processing
  },
  {
    id: 'SHIPPED',
    status: 'SHIPPED',
    active: false,
    hasWarning: false,
    location: '',
    date: '',
    touched: false,
  },
  {
    id: 'IN_TRANSIT',
    status: 'IN_TRANSIT',
    active: false,
    hasWarning: false,
    location: '',
    date: '',
    touched: false,
  },
  {
    id: 'OUT_FOR_DELIVERY',
    status: 'OUT_FOR_DELIVERY',
    active: false,
    hasWarning: false,
    location: '',
    date: '',
    touched: false,
  },
  {
    id: 'DELIVERED_TO_PICKUP_POINT',
    status: 'DELIVERED_TO_PICKUP_POINT',
    active: false,
    hasWarning: false,
    location: '',
    date: '',
    touched: false,
  },
  {
    id: 'DELIVERED',
    status: 'DELIVERED',
    active: false,
    hasWarning: false,
    location: '',
    date: '',
    touched: false,
  },
];

export const emptySimpleStepsDelShop: SimpleStep[] = [
  {
    id: 'CREATED', // key for simple step
    status: 'CREATED', // status label, will be translated in-component
    active: false,
    hasWarning: false, // currently only used by EXCEPTION status
    location: '',
    date: '',
    touched: true, // to know if we already got this step during processing
  },
  {
    id: 'SHIPPED',
    status: 'SHIPPED',
    active: false,
    hasWarning: false,
    location: '',
    date: '',
    touched: false,
  },
  {
    id: 'IN_TRANSIT',
    status: 'IN_TRANSIT',
    active: false,
    hasWarning: false,
    location: '',
    date: '',
    touched: false,
  },
  {
    id: 'OUT_FOR_DELIVERY',
    status: 'OUT_FOR_DELIVERY',
    active: false,
    hasWarning: false,
    location: '',
    date: '',
    touched: false,
  },
  {
    id: 'DELIVERED_TO_SHOP',
    status: 'DELIVERED_TO_SHOP',
    active: false,
    hasWarning: false,
    location: '',
    date: '',
    touched: false,
  },
  {
    id: 'DELIVERED',
    status: 'DELIVERED',
    active: false,
    hasWarning: false,
    location: '',
    date: '',
    touched: false,
  },
];

const DELPU = [
  'DELIVERED_TO_PICKUP_POINT',
  'DELIVERED_CITYPAQ',
  'DELIVERED_POST_OFFICE',
  'DELIVERED_PO_BOX',
];

const INCIDENT_TRACKING_STATUSES = [
  'EXCEPTION',
  'BAD_ADDRESS',
  'NOT_AT_HOME',
  'MISSING_DOCUMENTATION',
  'CANCELLED',
  'LOST',
  'DAMAGED',
  'DESTROYED',
  'STOLEN',
  'RETURNED_TO_ORIGIN',
  'REFUSED',
  'DELAYED',
  'IMPORTANT_INCIDENTS',
  'PARTIALLY_DELIVERED',
  'OTHER_INCIDENTS',
  'RETURN_TO_ORIGIN',
];

const checkHasPickupPoint = (steps: TrackingStep[]) => {
  return steps.some(
    (step) => step.code === 'DELPU' || DELPU.includes(normalizeStatus(step.status)),
  );
};

const checkHasDeliveredToShop = (steps: TrackingStep[]) => {
  return steps.some(
    (step) => step.code === 'DELSHOP' || normalizeStatus(step.status) === 'DELIVERED_TO_SHOP',
  );
};

export const getLastStepSimpleIndex = (
  step: TrackingStep,
  hasPickupPointStep: boolean,
  hasDeliveredToShopStep: boolean,
) => {
  if (typeof step === 'undefined') {
    return 0;
  }
  switch (normalizeStatus(step.status)) {
    case 'DELIVERED':
      return hasPickupPointStep || hasDeliveredToShopStep ? 5 : 4;
    case 'DELIVERED_TO_PICKUP_POINT':
    case 'DELIVERED_CITYPAQ':
    case 'DELIVERED_POST_OFFICE':
    case 'DELIVERED_PO_BOX':
    case 'DELIVERED_TO_SHOP':
      return 4;
    case 'OUT_FOR_DELIVERY':
      return 3;
    case 'IN_TRANSIT':
    case 'IN_TRANSIT_DELAYED':
    case 'EXCEPTION':
    case 'BAD_ADDRESS':
    case 'NOT_AT_HOME':
    case 'MISSING_DOCUMENTATION':
    case 'CANCELLED':
    case 'LOST':
    case 'DAMAGED':
    case 'DESTROYED':
    case 'STOLEN':
    case 'RETURNED_TO_ORIGIN':
    case 'RETURN_TO_ORIGIN':
    case 'REFUSED':
    case 'DELAYED':
    case 'IMPORTANT_INCIDENTS':
    case 'PARTIALLY_DELIVERED':
    case 'OTHER_INCIDENTS':
      return 2;
    case 'PICKUP':
    case 'PICKED_UP':
    case 'SHIPMENT PICKUP':
      return 1;
    default:
      return 0;
  }
};

export const makeSimpleSteps = (
  steps: TrackingStep[],
  origin: string,
  destination: string,
  creationStepDate: string | null,
  estimatedDelivery: string | null,
) => {
  const revSteps = steps.map((e) => ({ ...e })).reverse();
  const hasPickupPointStep = checkHasPickupPoint(steps);
  const hasDeliveredToShopStep = checkHasDeliveredToShop(steps);
  let simpleSteps = hasPickupPointStep
    ? emptySimpleStepsDelpu.map((e) => ({ ...e }))
    : hasDeliveredToShopStep
    ? emptySimpleStepsDelShop.map((e) => ({ ...e }))
    : emptySimpleSteps.map((e) => ({ ...e }));
  const ignoreStepsAfter = getLastStepSimpleIndex(
    revSteps[0],
    hasPickupPointStep,
    hasDeliveredToShopStep,
  );
  const deliveredStepIndex = hasPickupPointStep || hasDeliveredToShopStep ? 5 : 4;

  // Bootstrap defaults
  // CREATED step is default filled and done
  simpleSteps[0].active = true;
  simpleSteps[0].location = origin;
  simpleSteps[0].date = creationStepDate
    ? moment.utc(creationStepDate).format('DD/MM/YYYY HH:mm')
    : '';
  // DELIVERED step default to estimate
  simpleSteps[deliveredStepIndex].location = estimatedDelivery !== null ? 'Estimated by' : null;
  simpleSteps[deliveredStepIndex].date =
    estimatedDelivery !== null ? moment.utc(estimatedDelivery).format('DD/MM/YYYY HH:mm') : '';

  // Process steps backwards. If we got an entry for a given simple step,
  // mark it touched, so earlier entries don't overwrite it.
  // Use ignoreStepsAfter to ignore steps that are "after" the last step.
  //   Ex.: An IN TRANSIT DELAYED can come after an OUT FOR DELIVERY, in that
  //   case we would inactivate the OUT FOR DELIVERY step
  let currIndex = 0;
  let currStep = null;
  while (currIndex <= revSteps.length - 1) {
    currStep = revSteps[currIndex];

    const formattedStatus = normalizeStatus(currStep.status);

    if (
      (formattedStatus === 'DELIVERED' ||
        formattedStatus === 'DELIVERED_POST_OFFICE' ||
        formattedStatus === 'DELIVERED_CITYPAQ') &&
      !simpleSteps[deliveredStepIndex].touched &&
      ignoreStepsAfter >= 4
    ) {
      simpleSteps[deliveredStepIndex].touched = true;
      simpleSteps[deliveredStepIndex].active = true;
      simpleSteps[deliveredStepIndex].location =
        currStep && currStep.location ? currStep.location : destination || '';
      simpleSteps[deliveredStepIndex].date = currStep && currStep.date ? currStep.date : '';
    } else if (
      ['DELIVERED_TO_PICKUP_POINT', 'DELIVERED_TO_SHOP'].includes(formattedStatus) &&
      !simpleSteps[4].touched &&
      ignoreStepsAfter >= 4
    ) {
      simpleSteps[4].touched = true;
      simpleSteps[4].active = true;
      simpleSteps[4].location = currStep && currStep.location ? currStep.location : '';
      simpleSteps[4].date = currStep && currStep.date ? currStep.date : '';
    } else if (
      formattedStatus === 'OUT_FOR_DELIVERY' &&
      !simpleSteps[3].touched &&
      ignoreStepsAfter >= 3
    ) {
      simpleSteps[3].touched = true;
      simpleSteps[3].active = true;
      simpleSteps[3].location = currStep && currStep.location ? currStep.location : '';
      simpleSteps[3].date = currStep && currStep.date ? currStep.date : '';
    } else if (
      currStep.status === 'IN TRANSIT' &&
      !simpleSteps[2].touched &&
      ignoreStepsAfter >= 2
    ) {
      simpleSteps[2].status = 'IN_TRANSIT';
      simpleSteps[2].touched = true;
      simpleSteps[2].active = true;
      simpleSteps[2].location = currStep && currStep.location ? currStep.location : '';
      simpleSteps[2].date = currStep && currStep.date ? currStep.date : '';
    } else if (
      formattedStatus === 'IN_TRANSIT_DELAYED' &&
      !simpleSteps[2].touched &&
      ignoreStepsAfter === 2 // This and EXCEPTION status, to prevent them taking over if order is DELIVERED
    ) {
      simpleSteps[2].status = 'IN_TRANSIT_DELAYED';
      simpleSteps[2].touched = true;
      simpleSteps[2].active = true;
      simpleSteps[2].location = currStep && currStep.location ? currStep.location : '';
      simpleSteps[2].date = currStep && currStep.date ? currStep.date : '';
    } else if (
      INCIDENT_TRACKING_STATUSES.includes(formattedStatus) &&
      !simpleSteps[2].touched &&
      ignoreStepsAfter === 2
    ) {
      simpleSteps[2].status = 'EXCEPTION';
      simpleSteps[2].touched = true;
      simpleSteps[2].active = true;
      simpleSteps[2].hasWarning = true;
      simpleSteps[2].location = currStep && currStep.location ? currStep.location : '';
      simpleSteps[2].date = currStep && currStep.date ? currStep.date : '';
    } else if (
      (formattedStatus === 'PICKUP' ||
        formattedStatus === 'PICKED_UP' ||
        formattedStatus === 'SHIPMENT PICKUP') &&
      !simpleSteps[1].touched &&
      ignoreStepsAfter >= 1
    ) {
      simpleSteps[1].touched = true;
      simpleSteps[1].active = true;
      simpleSteps[1].location = currStep && currStep.location ? currStep.location : '';
      simpleSteps[1].date = currStep && currStep.date ? currStep.date : '';
    }

    // Continue iteration
    currIndex += 1;
  }

  // Fill in empty steps
  simpleSteps = simpleSteps.map((step, index) => ({
    ...step,
    active: index <= ignoreStepsAfter,
  }));

  return { simpleSteps, hasPickupPointStep, hasDeliveredToShopStep };
};

export const filterDupliacateSteps = (steps: TrackingStep[]) => {
  const filtered: TrackingStep[] = [];

  steps.forEach((step) => {
    if (
      filtered.findIndex(
        (filtStep) => filtStep.date === step.date && filtStep.code === step.code,
      ) === -1
    ) {
      filtered.push(step);
    }
  });

  return filtered;
};

export const filterSteps = (steps: TrackingStep[]) => {
  return steps.filter((step) => !!step.status);
};

export const sortSteps = (steps: TrackingStep[]) => {
  const sorted: TrackingStep[] = [...steps];

  sorted.sort((a, b) => {
    const aTime = moment(a.date, 'DD/MM/YYYY HH:mm');
    const bTime = moment(b.date, 'DD/MM/YYYY HH:mm');

    if (aTime.isBefore(bTime)) {
      return -1;
    }
    if (aTime.isAfter(bTime)) {
      return 1;
    }
    return 0;
  });

  return sorted;
};

export const normalizeStatusText = (steps: TrackingStep[]) => {
  return steps.map((step) => ({ ...step, status: (step?.status || '').toUpperCase() }));
};
