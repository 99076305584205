export function MessageNotification({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 25 25" {...props}>
      <g id="Messgae" transform="translate(24.5 24.5) rotate(180)">
        <rect
          id="Rectangle_3429"
          data-name="Rectangle 3429"
          width="24"
          height="24"
          fill="none"
          stroke="rgba(0,0,0,0)"
          strokeWidth="1"
        />
        <path
          id="Subtraction_35"
          data-name="Subtraction 35"
          d="M5502.467-10229h-7.936a7.089,7.089,0,0,1-2.982-.306,2.666,2.666,0,0,1-1.237-1.206,6.6,6.6,0,0,1-.314-2.911v-4.973a6.585,6.585,0,0,1,.314-2.908,2.666,2.666,0,0,1,1.237-1.206,7.132,7.132,0,0,1,2.982-.3h2.381a6.106,6.106,0,0,0,1.162-.057,3.065,3.065,0,0,0,.718-.249,5.784,5.784,0,0,0,.943-.661l2.258-1.762.09-.069.006,0c.335-.257.5-.386.659-.386a.468.468,0,0,1,.368.175c.1.129.1.372.1.856v2.157h.013a7.648,7.648,0,0,1,1.668.091,2.833,2.833,0,0,1,2,1.952,6.988,6.988,0,0,1,.095,1.641v5.708a6.605,6.605,0,0,1-.313,2.911,2.688,2.688,0,0,1-1.237,1.206A7.093,7.093,0,0,1,5502.467-10229ZM5492-10240v9h13v-9Z"
          transform="translate(-5486.5 10248.5)"
          stroke="currentColor"
          strokeLinecap="round"
          fill="currentColor"
          strokeLinejoin="round"
          strokeWidth="1.4"
        />
        <path
          id="Subtraction_36"
          data-name="Subtraction 36"
          d="M5504-10232h-14v-10h14v10Zm-7.5-4a1,1,0,0,0-1,1,1,1,0,0,0,1,1h5a1,1,0,0,0,1-1,1,1,0,0,0-1-1Zm-3-4a1,1,0,0,0-1,1,1,1,0,0,0,1,1h8a1,1,0,0,0,1-1,1,1,0,0,0-1-1Z"
          transform="translate(-5485 10250)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
