import React from 'react';

import _get from 'lodash.get';

import { FieldProps } from 'formik';

interface CustomInput extends FieldProps {
  left?: React.ReactNode;
  placeholder?: string;
}

function CustomInput(props: CustomInput) {
  const {
    field: { name, value, onChange, onBlur },
    left,
    form: { initialValues },
    placeholder: srcPlaceholder,
  } = props;
  const placeholder = _get(initialValues, name) || srcPlaceholder;

  return (
    <div className="flex bg-slate-100 rounded-md text-sm">
      <label htmlFor={name} className="p-4 font-bold text-slate-800 text-opacity-80 min-w-[50px]">
        {left}
      </label>
      <div className="h-full w-[1px] bg-slate-200 py-4 my-auto" />
      <input
        type="text"
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        className="block font-semibold w-full py-3 px-4 text-slate-900 text-opacity-80 bg-opacity-10 bg-slate-100 rounded-md"
        id={name}
        placeholder={placeholder}
      />
    </div>
  );
}

export default CustomInput;
