import { useMutation } from '@apollo/client';
import { MutationHookOptions } from '@apollo/client/react/types/types';
import { Mutation, TicketCreateBySupportInput } from 'app-types/desk';

import { CREATE_SUPPORT_TICKET_MUTATION } from './queries';

export function useCreateSupportTicket(
  options?: MutationHookOptions<
    {
      ticket: Mutation['Ticket_CreateSupport'];
    },
    { input: TicketCreateBySupportInput }
  >,
) {
  return useMutation<
    {
      ticket: Mutation['Ticket_CreateSupport'];
    },
    { input: TicketCreateBySupportInput }
  >(CREATE_SUPPORT_TICKET_MUTATION, {
    ...options,
    context: { ...options?.context, clientName: 'desk' },
  });
}
