import React, { useContext } from 'react';

import { FileRejection } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import EditModalContext from '../../Context/EditModalContext';
import ImageUploader from './ImageUploader';

const useBackgroundUploader = () => {
  const { formData, setFieldValue, uploadFileRequest } = useContext(EditModalContext);

  const handleDeleteBackground = (): void => {
    setFieldValue('trackingDesktopBg', undefined);
  };

  const handleUploadBackground = async (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
  ): Promise<boolean> => {
    return fileRejections.length
      ? new Promise<boolean>((_, reject) => {
          reject(fileRejections[0].errors[0].message);
        }).catch(() => false)
      : uploadFileRequest({ file: acceptedFiles[0] })
          .then(({ file: { location } }) => setFieldValue('trackingDesktopBg', location))
          .then(() => true)
          .catch(() => false);
  };

  return {
    trackingDesktopBg: formData?.trackingDesktopBg || '',
    handleUploadBackground,
    handleDeleteBackground,
  };
};

const BackgroundUploader = () => {
  const { trackingDesktopBg, handleUploadBackground, handleDeleteBackground } =
    useBackgroundUploader();
  const { t } = useTranslation('trackingPage', { keyPrefix: 'editMode.modules.colorPalette' });

  return (
    <ImageUploader
      handleUploadImage={(acceptedFiles, fileRejections) =>
        handleUploadBackground(acceptedFiles, fileRejections)
      }
      handleDeleteImage={() => handleDeleteBackground()}
      uploadText={t('uploadBackground')}
      uploadDescription={t('uploadDescription')}
      imageSrc={trackingDesktopBg || undefined}
      className="w-full h-[300px] bg-center bg-contain bg-no-repeat rounded"
    />
  );
};

export default BackgroundUploader;
