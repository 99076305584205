import React, { useContext } from 'react';

import { FileRejection } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import EditModalContext from '../../Context/EditModalContext';
import ImageUploader from './ImageUploader';

const useLogoUploader = () => {
  const {
    formData: { logo },
    setFieldValue,
    uploadFileRequest,
  } = useContext(EditModalContext);

  const handleDeleteLogo = (): void => {
    setFieldValue('logo', undefined);
  };
  const handleUploadLogo = async (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
  ): Promise<boolean> => {
    return fileRejections.length
      ? new Promise<boolean>((_, reject) => {
          reject(fileRejections[0].errors[0].message);
        }).catch(() => false)
      : uploadFileRequest({ file: acceptedFiles[0] })
          .then(({ file: { location } }) => setFieldValue('logo', location))
          .then(() => true)
          .catch(() => false);
  };

  return { logo, handleUploadLogo, handleDeleteLogo };
};

const LogoUploader = () => {
  const { logo, handleUploadLogo, handleDeleteLogo } = useLogoUploader();
  const { t } = useTranslation('trackingPage', { keyPrefix: 'editMode.modules.logo' });

  const {
    formData: { colors },
  } = useContext(EditModalContext);

  return (
    <ImageUploader
      handleUploadImage={(acceptedFiles, fileRejections) =>
        handleUploadLogo(acceptedFiles, fileRejections)
      }
      handleDeleteImage={() => handleDeleteLogo()}
      uploadText={t('uploadText')}
      uploadDescription={t('uploadDescription')}
      imageSrc={logo || undefined}
      style={{ backgroundColor: colors?.header || '#FFFFFF' }}
    />
  );
};

export default LogoUploader;
