import { IResponseFileUpload } from 'app-types';
import { request } from 'ui';

export const uploadImage = ({
  file,
  maxResolution,
  folder,
}: {
  file: File;
  maxResolution?: number;
  folder?: string;
}): Promise<{ success: boolean; file: IResponseFileUpload }> => {
  const formData = new FormData();
  formData.append('image', file);
  const search = maxResolution ? `?maxResolution=${maxResolution}` : '?';
  const folterToUpload = folder ? `&folder=${folder}` : '';
  return request(`${process.env.OUTVIO_API_URL}/upload${search}${folterToUpload}`, {
    method: 'POST',
    data: formData,
    secure: true,
    upload: true,
  });
};

export const getSignedUrl = (url: string) => {
  return request(`${process.env.OUTVIO_API_URL}/upload/create-signed-url`, {
    method: 'POST',
    data: { key: url },
    secure: true,
  });
};

export const getSignedRateUrl = (url: string) => {
  return request(`${process.env.OUTVIO_API_URL}/upload/create-rates-signed-url`, {
    method: 'POST',
    data: { key: url },
    secure: true,
  });
};
