import React from 'react';

import EstimatedDeliveryDateBlock from '../EstimatedDeliveryDateBlock';
import OrderStatusV2 from './OrderStatusV2';

type OrderOverviewWithoutMapProps = {
  toggleRatingModal(value: boolean): void;
  isModalButton: boolean;
};

const OrderOverviewWithoutMap = ({
  toggleRatingModal,
  isModalButton,
}: OrderOverviewWithoutMapProps) => {
  return (
    <>
      <div className="md:hidden">
        <EstimatedDeliveryDateBlock
          toggleRatingModal={toggleRatingModal}
          isModalButton={isModalButton}
        />
      </div>

      <div className="md:max-w-[547px] w-full">
        <OrderStatusV2 />
      </div>

      <div className="md:max-w-[425px] hidden md:flex w-full h-full order-2 md:order-1">
        <EstimatedDeliveryDateBlock
          toggleRatingModal={toggleRatingModal}
          isModalButton={isModalButton}
        />
      </div>
    </>
  );
};

export default OrderOverviewWithoutMap;
