export function Refresh({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 96 960 960"
      fill="currentColor"
      {...props}
    >
      <path d="M481.539 875.999q-125.625 0-212.812-87.17-87.187-87.169-87.187-212.768t87.187-212.829q87.187-87.231 212.812-87.231 70.154 0 132.769 31.193 62.615 31.192 104.153 88.039v-89.232q0-12.75 8.629-21.375t21.384-8.625q12.756 0 21.371 8.625 8.615 8.625 8.615 21.375v178.46q0 15.365-10.394 25.759t-25.76 10.394h-178.46q-12.749 0-21.374-8.628-8.625-8.629-8.625-21.384 0-12.756 8.625-21.371 8.625-8.615 21.374-8.615h128q-31.615-57.923-87.692-91.27Q548.077 336 481.539 336q-100 0-170 70t-70 170q0 100 70 170t170 70q71.307 0 130.769-38.231t87.308-102.308q3.846-8.307 12.076-13.884Q719.923 656 729.23 656q17.231 0 25.846 11.961 8.615 11.961 1.923 27.346-34.538 81.923-109.307 131.307-74.769 49.385-166.153 49.385Z" />
    </svg>
  );
}
