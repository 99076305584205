import React, { FC, useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Dialog } from '@mui/material';
import { useIntegrations } from 'data-layer';
import { Field } from 'formik';
import { HelpIcon } from 'ui';

import EditModalContext from '../../../Context/EditModalContext';
import { CancelOrderType } from '../../../Interfaces';
import { ButtonTypes } from '../../../Interfaces/IButton';
import { EDIT_MODE_TABS } from '../../../Interfaces/IEditModal';
import { ILanguageInputInfo, LanguageInputTypes } from '../../../Interfaces/ILanguageInputInfo';
import Button from '../../Common/Button';
import Toggle from '../../Common/Inputs/Toggle';
import LanguageInputCard from '../LanguageInputCard';
import LanguageInputInfo from '../LanguageInputInfo';
import Base from './Base';
import PopUpDialogPreview from './PopUpDialogPreview';

export const useOrderStatus = () => {
  const { t } = useTranslation('trackingPage', { keyPrefix: 'editMode.inputs' });
  const orderStatusInfo: ILanguageInputInfo[] = [
    {
      title: t('orderStatusInfo.title'),
      objectKey: 'orderStatusInfo.title',
      type: LanguageInputTypes.TEXT,
    },
    //=== new =====
    {
      title: t('orderStatusInfo.lastUpdate'),
      objectKey: 'orderStatusInfo.lastUpdate',
      type: LanguageInputTypes.TEXT,
    },

    {
      title: t('orderStatusInfo.estimatedDeliveryDate'),
      objectKey: 'orderStatusInfo.estimatedDeliveryDate',
      type: LanguageInputTypes.TEXT,
    },
    {
      title: t('orderStatusInfo.titleOrder'),
      objectKey: 'orderStatusInfo.titleOrder',
      type: LanguageInputTypes.TEXT,
    },
    {
      title: t('orderInfo.orderTotalText'),
      objectKey: 'orderInfo.orderTotalText',
      type: LanguageInputTypes.TEXT,
    },
    {
      title: t('orderStatusInfo.seeOderDetails'),
      objectKey: 'orderStatusInfo.seeOderDetails',
      type: LanguageInputTypes.TEXT,
    },
  ];

  const orderInfoCancel1: ILanguageInputInfo[] = [
    {
      title: t('cancelOrder.infoStep.title'),
      objectKey: 'cancelOrder.infoStep.title',
      type: LanguageInputTypes.TEXT,
    },
    {
      title: t('cancelOrder.infoStep.message'),
      objectKey: 'cancelOrder.infoStep.message',
      type: LanguageInputTypes.TEXTAREA,
    },
    {
      title: t('cancelOrder.infoStep.checkbox'),
      objectKey: 'cancelOrder.infoStep.checkbox',
      type: LanguageInputTypes.RICH_TEXT,
    },

    {
      title: t('cancelOrder.infoStep.cancelDialogButton'),
      objectKey: 'cancelOrder.infoStep.cancelDialogButton',
      type: LanguageInputTypes.TEXT,
    },
    {
      title: t('cancelOrder.infoStep.confirmDialogButton'),
      objectKey: 'cancelOrder.infoStep.confirmDialogButton',
      type: LanguageInputTypes.TEXT,
    },
  ];

  const orderInfoCancelButton: ILanguageInputInfo[] = [
    {
      title: t('cancelOrder.infoStep.cancelOrderButton'),
      objectKey: 'cancelOrder.infoStep.cancelOrderButton',
      type: LanguageInputTypes.TEXT,
    },
  ];

  const cancelOrderOptions: { value: CancelOrderType; label: string }[] = [
    {
      value: CancelOrderType.not_shipped,
      label: t('cancelOrder.cancelOption.beforeShipping'),
    },
    {
      value: CancelOrderType['24h'],
      label: t('cancelOrder.cancelOption.within24HoursOfCreatingOrder'),
    },
  ];

  return {
    orderStatusInfo,
    orderInfoCancel1,
    orderInfoCancelButton,
    cancelOrderOptions,
  };
};

const OrderStatus: FC = () => {
  const { t } = useTranslation('trackingPage', { keyPrefix: 'editMode.modules' });
  const {
    formData: { isOrderCancellable, allowOrderCancel },
    setFieldValue,
  } = useContext(EditModalContext);

  const [orderType, setOrderType] = useState<CancelOrderType | undefined>(allowOrderCancel);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  const { data: integrations } = useIntegrations();

  useEffect(() => {
    setFieldValue(`allowOrderCancel`, orderType);
  }, [orderType]);

  const { orderStatusInfo, orderInfoCancel1, orderInfoCancelButton, cancelOrderOptions } =
    useOrderStatus();

  const hasShopifyIntegration = integrations?.data?.some(
    (integration) => integration.type === 'shopifyApp',
  );

  return (
    <Base title={t('orderStatus.title')} id={EDIT_MODE_TABS.ORDER_STATUS}>
      <h3 className="text-cyan-800 text-sm font-semibold mb-2">
        {t('orderStatus.titleAndButtons')}
      </h3>
      <span className="text-sm font-light mb-6 text-slate-900 text-opacity-80">
        {t('orderStatus.titleAndButtonsDescription')}
      </span>
      <LanguageInputInfo inputs={orderStatusInfo} />

      <div className="flex mt-10 mb-2">
        <Field component={Toggle} name="isOrderCancellable" type="checkbox" />
        <span className="text-sm text-slate-900 text-opacity-80 font-semibold ml-6">
          {t('orderInformation.allowClientsToCancel')}
        </span>
      </div>

      <div className={`flex flex-col ml-[60px] ${isOrderCancellable ? 'block' : 'hidden'}`}>
        <span className="text-sm mb-6 text-slate-900 text-opacity-80">
          {t('orderInformation.allowCancelBefore')}
        </span>
        <div className="flex flex-col space-y-3 mb-10">
          {cancelOrderOptions.map(({ value, label }, index) => (
            <label className="flex items-center" key={`order-type-${index}`} htmlFor={label}>
              <input
                type="radio"
                onChange={() => setOrderType(value)}
                checked={value === orderType}
                className="appearance-none relative rounded-full w-5 h-5 bg-slate-300 before:bg-white checked:bg-primary before:border-white before:border-[3px] before:block before:absolute before:rounded-full before:top-0.5 before:left-0.5 before:w-4 before:h-4 checked:before:bg-primary"
                id={label}
              />
              <span className="text-slate-700 text-sm ml-3">{label}</span>
            </label>
          ))}
        </div>

        <div className="flex items-center mb-10">
          <div className="mt-1">
            <Field
              component={Toggle}
              name="autoRefundOnCancel"
              type="checkbox"
              disabled={!hasShopifyIntegration}
            />
          </div>
          <div className="flex items-center gap-2">
            <span className="text-sm text-slate-900 text-opacity-80 font-semibold ml-6">
              {t('orderInformation.autoRefundOnCancel.title')}
            </span>
            <HelpIcon tooltipText={t('orderInformation.autoRefundOnCancel.tooltip')} />
          </div>
        </div>

        <span className="text-cyan-800 text-sm font-semibold">
          {t('orderInformation.cancelOrderButton.title')}
        </span>
        <span className="text-sm font-light mb-6 text-slate-900 text-opacity-80 mt-2">
          {t('orderInformation.cancelOrderButton.description')}
        </span>
        <LanguageInputInfo inputs={orderInfoCancelButton} />
        <div className="flex flex-col space-y-10 my-6">
          <div className="flex space-x-4 items-center">
            <span className="text-cyan-800 text-base font-bold">
              {t('orderInformation.cancelOrderPopUp.title')}
            </span>
            <HelpIcon onClick={() => setModalIsOpen(true)} />

            <Dialog
              open={modalIsOpen}
              onClose={() => setModalIsOpen(false)}
              PaperComponent={({ children }) => (
                <div className="p-12 bg-white rounded-2xl">{children}</div>
              )}
            >
              <div className="w-[560px] flex flex-col items-center">
                <PopUpDialogPreview />
                <div className="mt-8 flex-1 text-center flex flex-col gap-y-8">
                  <span className="text-cyan-800 text-xl font-bold">
                    {t('orderInformation.cancelOrderPopUp.title')}
                  </span>
                  <span className="text-cyan-800 text-base">
                    {t('orderInformation.cancelOrderPopUp.description')}
                  </span>
                  <div className="w-full flex justify-end">
                    <Button
                      type={ButtonTypes.OV_PRIMARY_DARK}
                      onClick={() => setModalIsOpen(false)}
                    >
                      {t('orderInformation.cancelOrderPopUp.button')}
                    </Button>
                  </div>
                </div>
              </div>
            </Dialog>
          </div>
        </div>
        <div className="flex flex-col space-y-10">
          <LanguageInputCard inputs={orderInfoCancel1} />
        </div>
      </div>
    </Base>
  );
};

export default OrderStatus;
