import React, { FC, useContext, useEffect, useState } from 'react';

import { FileRejection } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import LinkRoundedIcon from '@mui/icons-material/LinkRounded';
import { Field } from 'formik';

import EditModalContext from '../../Context/EditModalContext';
import { BannerModes, BannerTypes, IBanner, ILanguage, IRegionalSettings } from '../../Interfaces';
import CustomInput from '../Common/Inputs/CustomInput';
import ImageUploader from './ImageUploader';

interface BannerUploader {
  selectedLanguage: ILanguage;
  selectedMode: BannerModes;
}

interface UseBannerUploader {
  ISOCode: string;
  regionalSettings: IRegionalSettings[];
  /* eslint-disable @typescript-eslint/no-explicit-any */
  setFieldValue(field: string, value: any, shouldValidate?: boolean | undefined): void;
  /* eslint-enable @typescript-eslint/no-explicit-any */
}

export const useBannerUploader = (props: UseBannerUploader) => {
  const { ISOCode, setFieldValue, regionalSettings } = props;
  const { uploadFileRequest } = useContext(EditModalContext);
  const [langIndex, setLangIndex] = useState(0);
  const [settingsLang, setSettingsLang] = useState(regionalSettings[0]);
  const [desktop, setDesktop] = useState(settingsLang.banners?.desktop);
  const [mobile, setMobile] = useState(settingsLang.banners?.mobile);
  const [bannerUrlName, setBannerUrlName] = useState(`regionalSettings[${langIndex}].banners.url`);
  useEffect(() => {
    const settingsLangIndex = regionalSettings.findIndex(
      (setting) => setting.language.ISOCode === ISOCode,
    );
    setLangIndex(settingsLangIndex);
    setSettingsLang(regionalSettings[settingsLangIndex]);
    setDesktop(regionalSettings[settingsLangIndex].banners?.desktop);
    setMobile(regionalSettings[settingsLangIndex].banners?.mobile);
    setBannerUrlName(`regionalSettings[${settingsLangIndex}].banners.url`);
  }, [regionalSettings, ISOCode]);
  const handleBannerUpload = async (
    mode: BannerModes,
    bannerType: BannerTypes,
    files: File[],
    fileRejections: FileRejection[],
  ): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
      if (fileRejections.length) reject();
      else {
        uploadFileRequest({ file: files[0] })
          .then(({ file: { location } }) => location)
          .then((value) => {
            const field = `regionalSettings[${langIndex}].banners`;
            const data: IBanner = {
              imgSrc: value,
              mode,
              alt: 'Banner',
            };
            setFieldValue(`${field}.${bannerType}`, data);
            resolve(true);
          })
          .catch(() => reject());
      }
    }).catch(() => false);
  };

  const handleDeleteBanner = (bannerType: BannerTypes) => {
    const field = `regionalSettings[${langIndex}].banners.${bannerType}`;
    setFieldValue(field, undefined);
  };

  return { handleBannerUpload, desktop, mobile, handleDeleteBanner, bannerUrlName };
};

const BannerUploader: FC<BannerUploader> = (props) => {
  const {
    selectedLanguage: { ISOCode },
    selectedMode,
  } = props;
  const {
    formData: { regionalSettings },
    setFieldValue,
  } = useContext(EditModalContext);

  const { handleBannerUpload, desktop, mobile, handleDeleteBanner, bannerUrlName } =
    useBannerUploader({
      ISOCode,
      regionalSettings,
      setFieldValue,
    });
  const { t } = useTranslation('trackingPage', { keyPrefix: 'editMode.modules.banner' });
  const [height, setHeight] = useState('h-[100px] w-full');
  useEffect(() => {
    setHeight(selectedMode === BannerModes.VERTICAL ? 'h-[464px] w-[272px]' : 'h-[100px] w-full');
  }, [selectedMode]);

  function renderBannerContainer(banner: IBanner | undefined, bannerType: BannerTypes) {
    return (
      <ImageUploader
        handleDeleteImage={() => handleDeleteBanner(bannerType)}
        handleUploadImage={(acceptedFiles: File[], fileRejections) =>
          handleBannerUpload(selectedMode, bannerType, acceptedFiles, fileRejections)
        }
        imageSrc={banner?.imgSrc}
        uploadText={bannerType === BannerTypes.DESKTOP ? t('desktopHeader') : t('mobileHeader')}
        uploadDescription={t(
          `${bannerType === BannerTypes.MOBILE ? bannerType : selectedMode}UploaderDescription`,
        )}
        className={`${
          bannerType === BannerTypes.DESKTOP ? height : 'h-[100px] w-full'
        } bg-cover bg-center w-full rounded`}
      />
    );
  }

  return (
    <div className="flex flex-col space-y-2">
      <div>{renderBannerContainer(desktop, BannerTypes.DESKTOP)}</div>
      <div>{renderBannerContainer(mobile, BannerTypes.MOBILE)}</div>
      <Field
        component={CustomInput}
        name={bannerUrlName}
        left={<LinkRoundedIcon />}
        placeholder="https://your-store.com/"
      />
    </div>
  );
};

export default BannerUploader;
