import request from '../utils/request';

type WarehouseOrders = {
  warehouseId: string;
  orders: number;
};

type OrdersPerWarehouseResponse = {
  success: boolean;
  data: Array<WarehouseOrders>;
};

export const getOrdersPerWarehouse = (): Promise<Array<WarehouseOrders> | undefined> =>
  request<OrdersPerWarehouseResponse | undefined>(
    `${process.env.OUTVIO_API_URL}/order/warehouses/count`,
    {
      method: 'GET',
      secure: true,
    },
  ).then((response) => {
    const { data } = (response as OrdersPerWarehouseResponse | undefined) ?? {};
    return data ?? [];
  });
