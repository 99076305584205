import { Integration } from 'app-types';

import request from '../utils/request';

export const getAllIntegrations = () => {
  return request<{ data: Integration[] }>(`${process.env.OUTVIO_API_URL}/integration`, {
    method: 'GET',
    secure: true,
  });
};
