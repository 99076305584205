import React, { FC, useContext, useMemo } from 'react';

import TrackingPageContext from '../../../../Context/TrackingPageContext';
import { IQuestion } from '../../../../Interfaces';
import { EDIT_MODE_TABS } from '../../../../Interfaces/IEditModal';
import EditButton from '../../../Common/EditButton';
import Question from './Question';

interface UseQuestions {
  questions?: IQuestion[];
}

export const useQuestions = (props: UseQuestions) => {
  const questions = props?.questions || [];
  const sortedQuestions = useMemo(
    () => [...questions].sort((a, b) => a.order - b.order),
    [questions],
  );
  if (!questions || questions.length <= 0) return { sortedQuestions: [] as IQuestion[] };
  return { sortedQuestions };
};

const Questions: FC = () => {
  const {
    portalSettings: {
      regionalSettings: {
        questions: { questions },
      },
    },
    appSettings: { isEdit },
  } = useContext(TrackingPageContext);
  const { sortedQuestions } = useQuestions({ questions });

  if (!sortedQuestions.length) return null;

  return (
    <div className="relative w-full max-w-[980px] mx-auto pl-6 md:pr-6 pr-10 py-[50px]">
      {isEdit && (
        <div className="absolute top-0 right-0">
          <EditButton tab={EDIT_MODE_TABS.QUESTIONS} />
        </div>
      )}

      <div className="m-0 grid gap-6">
        {sortedQuestions.map((question) => (
          <Question question={question} key={question.order} />
        ))}
      </div>
    </div>
  );
};

export default Questions;
