import request from '../utils/request';

type ValidateCustomDomainsResponse = {
  success: boolean;
};

export const validateCustomDomainByStore = (
  storeBrandingId: string,
  customDomainId: string,
): Promise<boolean> =>
  request<ValidateCustomDomainsResponse | undefined>(
    `${process.env.OUTVIO_API_URL}/portal/${storeBrandingId}/custom-domain/${customDomainId}/validate`,
    {
      method: 'POST',
      secure: true,
    },
  ).then((response) => {
    const { success } = response ?? {};
    return success ?? false;
  });
