import React, { type HTMLProps } from 'react';

import { useTranslation } from 'react-i18next';

import { useFormikContext } from 'formik';
import { Copy, TextInput } from 'ui/atoms';

import { EDIT_MODE_TABS } from '../../../../Interfaces';
import Base from '../Base';
import { FaviconUploader } from './PageTitleAndFavicon.hooks';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface _PageTitleAndFaviconProps {}

export type PageTitleAndFaviconProps = HTMLProps<HTMLDivElement> & _PageTitleAndFaviconProps;

// eslint-disable-next-line no-empty-pattern
export function PageTitleAndFavicon({}: PageTitleAndFaviconProps) {
  const { t } = useTranslation('trackingPage', {
    keyPrefix: 'editMode.modules.pageTitleAndFavicon',
  });
  const { values, setFieldValue } = useFormikContext<{
    pageTitle: string;
  }>();

  return (
    <Base title={t('title')} id={EDIT_MODE_TABS.PAGE_TITLE_AND_FAVICON}>
      <div className="rounded border border-slate-100 px-6 pt-6 pb-[27px] shadow-sm space-y-3">
        <Copy className="ml-2 mb-3 text-[#1F3C4E] tracking-[0px]" weight="semibold">
          {t('inputTitle')}
        </Copy>
        <TextInput
          className="w-full"
          name="pageTitle"
          placeholder={t('inputPlaceholder')}
          value={values.pageTitle}
          onChange={(event) => {
            setFieldValue('pageTitle', event.target.value);
          }}
        />
        <Copy className="!mt-6 mb-3 ml-2 text-[#1F3C4E] tracking-[0px]" weight="semibold">
          {t('faviconTitle')}
        </Copy>
        <FaviconUploader />
      </div>
    </Base>
  );
}

export default PageTitleAndFavicon;
