import React, { FC } from 'react';

import { UploadImageIcon } from 'icons';
import { getCalculatedBrightnessFromHex, useGetPaletteColors } from 'return-portal-ui';
import { Copy } from 'ui/atoms';

import { IOrderProduct } from '../../../../Interfaces';
import Image from '../../../Common/Image';

const PRODUCT_IMG_WIDTH = '90px';
const PRODUCT_IMG_HEIGHT = '90px';

interface Product {
  product: IOrderProduct;
}

const Product: FC<Product> = (props) => {
  const { secondaryBg, primaryText } = useGetPaletteColors();

  const {
    product: { name, amount, price, imgSrc, variant, discountPrice },
  } = props;

  const inputBgL1 = getCalculatedBrightnessFromHex(secondaryBg, 1);
  const inputBgL3 = getCalculatedBrightnessFromHex(secondaryBg, 3);

  function renderProductImage() {
    if (imgSrc) {
      return (
        <Image
          src={imgSrc || ''}
          alt={name}
          width={PRODUCT_IMG_WIDTH}
          height={PRODUCT_IMG_HEIGHT}
          minHeight={PRODUCT_IMG_HEIGHT}
          minWidth={PRODUCT_IMG_WIDTH}
          customStyle="rounded-[10px] mr-[18px]"
        />
      );
    }

    return (
      <div
        className="rounded-[10px] mr-[18px] flex items-center justify-center"
        style={{
          minWidth: PRODUCT_IMG_WIDTH,
          minHeight: PRODUCT_IMG_HEIGHT,
          backgroundColor: inputBgL1,
          color: inputBgL3,
        }}
      >
        <UploadImageIcon className="text-[40px]" />
      </div>
    );
  }

  return (
    <div className="flex justify-between" style={{ color: primaryText }}>
      {renderProductImage()}
      <div className="flex flex-col w-full justify-center gap-1">
        <div className="flex w-full justify-between">
          <Copy
            as="div"
            type="copy7"
            style={{ color: primaryText }}
            className="capitalize max-w-[236px]"
            weight="semibold"
          >
            {name.toLowerCase()}
          </Copy>
          <Copy
            as="div"
            type="copy7"
            style={{ color: primaryText }}
            className="whitespace-nowrap"
            weight="semibold"
          >
            {discountPrice || price}
          </Copy>
        </div>
        <div className="flex w-full justify-between">
          <Copy
            as="div"
            type="copy1"
            style={{ color: primaryText }}
            className="opacity-60 max-w-[236px]"
            weight="regular"
          >
            {variant}
          </Copy>

          <Copy
            as="div"
            type="copy1"
            style={{ color: primaryText }}
            className="opacity-60"
            weight="regular"
          >
            {`x${amount}`}
          </Copy>
        </div>
      </div>
    </div>
  );
};

export default Product;
