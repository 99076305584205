import React, { useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { OVCButton, useGetPaletteColors } from 'return-portal-ui';
import { Editable, Slate } from 'slate-react';

import TrackingPageContext from '../../../../../Context/TrackingPageContext';
import useInitEditor from '../../../../../Hooks/useInitEditor';
import cancelOrder from '../../../../../Utils/api/apiCancelOrder';
import Checkbox from '../../../../Common/Inputs/Checkbox';

interface IInfoComponent {
  onClose: () => void;
}

function InfoComponent(props: IInfoComponent) {
  const { onClose } = props;
  const {
    portalSettings: {
      regionalSettings: {
        cancelOrder: {
          infoStep: { message, checkbox, confirmDialogButton, cancelDialogButton },
        },
      },
    },
    appSettings: { isEdit, trackingPageId },
  } = useContext(TrackingPageContext);

  const { primaryText } = useGetPaletteColors();

  const [isAccepted, setIsAccepted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { t } = useTranslation('trackingPage', { keyPrefix: 'editMode.inputs' });

  const toggleSelected = (value: boolean) => setIsAccepted(value);
  const { editor } = useInitEditor();

  const handleSubmit = async () => {
    setIsSubmitting(true);
    if (isEdit) {
      //submit simulation for cms
      await new Promise((res) => setTimeout(res, 2000));
      setIsSubmitting(false);
      onClose();
    } else {
      try {
        if (trackingPageId) {
          const response = await cancelOrder(trackingPageId);
          if (!response.ok) {
            const data = await response.json();

            throw new Error(data?.message);
          }

          window.location.reload();
        }
      } catch (error: any) {
        setError(`${t('cancelOrder.cancelErrorMessage')}`);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const slateData = { value: checkbox, initialValue: checkbox, editor } as any;

  return (
    <div className="flex flex-col">
      <span className="mb-6 text-sm text-redTP-500 bg-redTP-200/60 px-6 py-4 font-semibold rounded-lg">
        {message}
      </span>

      <Checkbox
        checked={isAccepted}
        label={
          <Slate {...slateData}>
            <span style={{ color: primaryText }} className="text-sm font-light select-none">
              <Editable readOnly />
            </span>
          </Slate>
        }
        onChange={toggleSelected}
      />

      <div className="flex ml-auto gap-4 mt-6">
        <OVCButton
          style={{ color: primaryText }}
          theme="secondary"
          className="pr-[12px]"
          onClick={onClose}
        >
          {cancelDialogButton}
        </OVCButton>

        <OVCButton
          className="pr-[12px]"
          showLoader={isSubmitting}
          disabled={!isAccepted || isSubmitting || !!error}
          onClick={handleSubmit}
        >
          {confirmDialogButton}
        </OVCButton>
      </div>

      {error && (
        <div className="mt-10 rounded-lg p-4 bg-red-600 text-white font-semibold">{error}</div>
      )}
    </div>
  );
}

export default InfoComponent;
