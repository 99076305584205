import { useQuery } from '@tanstack/react-query';
import request from 'ui/utils/request';

type Credit = {
  success: boolean;
  credit: {
    _id: string;
    value: number;
    currency: string;
    messages: number;
    conversations: number;
    conversationsLimit: number;
    packages: number;
    lastTopUp: number;
    waitingForTopUp: boolean;
    waitingForMessagesTopUp: boolean;
    waitingForConversationsTopUp: boolean;
    blockExchangeTill: number;
    lockedTill: number;
    timezone: string;
    resetDate: string;
    hasActiveNotificationsWhatsApp: boolean;
    hasActiveDeskWhatsApp: boolean;
    store: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
  };
};

export const getWhatsappSMSCredit = ({ channel = 'sms' }) =>
  request(`${process.env.OUTVIO_API_URL}/payment/messaging/credit/${channel}`, {
    method: 'GET',
    secure: true,
  }) as Promise<Credit>;

export function useCreditWhatsapp(enabled: boolean) {
  return useQuery<Credit>(
    ['CreditPanelGetCreditWhatsapp', 'whatsapp'],
    () =>
      getWhatsappSMSCredit({
        channel: 'whatsapp',
      }),
    {
      cacheTime: 0, // Disable Cache for this query
      staleTime: 0, // Disable Cache for this query
      enabled,
    },
  );
}
