import React, { FC, ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import isEqual from 'lodash-es/isEqual';

import { useStoreBrandings, useUpdateBrandings, useUpdatePortalBrandSettings } from 'data-layer';
import { Form, Formik } from 'formik';

import EditModalContext from '../../Context/EditModalContext';
import { IPortalSettings } from '../../Interfaces';
import {
  DEFAULT_MODULE_TOP_VALUES,
  EDIT_MODE_TABS,
  IModuleTopValue,
} from '../../Interfaces/IEditModal';
import { Features } from '../../Interfaces/IFeatures';
import { IUploadFileResponse } from '../../Interfaces/IRequests';
import { brighten, desaturate } from '../../Utils/Color';
import { editModalSchema } from '../../Utils/editModalYupSchema';
import BaseModal from '../Common/BaseModal';
import ModulesContainer from './ModulesContainer';
import Navbar from './Navbar';

export interface EditModalProps {
  isVisible: boolean;
  handleClose(): void;
  selectedTab?: EDIT_MODE_TABS;
  uploadFileRequest(props: { file: File; maxResolution?: number }): Promise<IUploadFileResponse>;
  updateSettingsRequest(portalSettings: IPortalSettings): Promise<boolean>;
  appLang: string;
  portalSettings: IPortalSettings;
  upgradeButton: ReactNode;
  features: Features;
  storeBrandingId: string;
  currency: string;
}

interface UseEditModal {
  defaultSelectedTab?: EDIT_MODE_TABS;
  uploadFileRequest(props: { file: File; maxResolution?: number }): Promise<IUploadFileResponse>;
  updateSettingsRequest(portalSettings: IPortalSettings): Promise<boolean>;
  portalSettings: IPortalSettings;
  storeBrandingId: string;
  handleClose: () => void;
}

type TrackingPortalDomainsData = {
  isTrackingCustomDomainEnabled?: boolean;
  slug?: string;
  trackingSlug?: string;
};

type AuthPageData = {
  trackingDesktopBg?: string;
};

const serializeColors = (formData: IPortalSettings): IPortalSettings => {
  const borderAndInput = '#eaf0f9';
  return {
    ...formData,
    colors: {
      ...formData.colors,
      mapWater: formData.colors.mapWater,
      mapDivisor: formData.colors.mapBase,
      border: borderAndInput,
      inputBg: borderAndInput,
      alertAlt: desaturate(brighten(formData.colors.alertColor, 60), 70),
    },
  };
};

export const useEditModal = (props: UseEditModal) => {
  const { updateBrandingSettings } = useUpdatePortalBrandSettings();
  const { data: allStoreBrandingsData, refetch: refetchBrandings } = useStoreBrandings();
  const { mutateAsync: updateBrandings } = useUpdateBrandings();

  const {
    defaultSelectedTab,
    uploadFileRequest,
    updateSettingsRequest,
    portalSettings,
    storeBrandingId,
    handleClose,
  } = props;

  const storeBrandingData = allStoreBrandingsData?.find((x) => x._id === storeBrandingId);

  const [isSending, setIsSending] = useState(false);
  const handleIsSending = (value: boolean) => setIsSending(value);
  const modulesRef = useRef<HTMLDivElement>(null);
  const [modulesTopValues, setModulesTopValues] = useState(DEFAULT_MODULE_TOP_VALUES);
  const [currentModule, setCurrentModule] = useState(
    defaultSelectedTab || EDIT_MODE_TABS.PORTAL_DOMAINS,
  );
  const addModuleTopValue = (module: IModuleTopValue) => {
    setModulesTopValues((prevState) => {
      return prevState.map((queryItem) => (queryItem.id === module.id ? module : queryItem));
    });
  };
  const modalTop = useMemo(
    () => modulesRef.current?.offsetTop || 0,
    [modulesRef.current?.offsetTop],
  );
  const getModuleTop = (module: IModuleTopValue) => module.top - modalTop + 5;
  const goToModule = (id: EDIT_MODE_TABS) => {
    const module = modulesTopValues.find(({ id: srcId }) => srcId === id);
    if (module?.top) {
      modulesRef.current?.scrollTo({ top: getModuleTop(module), behavior: 'smooth' });
      setTimeout(() => {
        modulesRef.current?.scrollTo({ top: getModuleTop(module), behavior: 'smooth' });
      }, 300);
    }
  };
  useEffect(() => {
    if (defaultSelectedTab) {
      let canGoToModule = false;
      modulesTopValues.map(({ top }) => {
        if (top !== 0) {
          canGoToModule = true;
        }
        return null;
      });
      if (canGoToModule) goToModule(defaultSelectedTab);
    }
  }, [modulesTopValues]);

  const handleScroll = () => {
    const i = modulesTopValues.findIndex(
      (module) => module.top - 90 > (modulesRef.current?.scrollTop || 0),
    );

    let curr;
    if (modulesRef.current) {
      const { scrollTop, clientHeight, scrollHeight } = modulesRef.current;
      const scrollDistanceToBottom = scrollTop + clientHeight - scrollHeight;

      if (scrollDistanceToBottom > -5) {
        curr = modulesTopValues[i];
      }
    }

    if (curr === undefined) {
      curr = modulesTopValues[i - 1] || modulesTopValues[0];
    }

    setCurrentModule(curr?.id || EDIT_MODE_TABS.PORTAL_DOMAINS);
  };

  const handleUpdateSettings = (
    formData: IPortalSettings & TrackingPortalDomainsData & AuthPageData,
    originalData: IPortalSettings & TrackingPortalDomainsData,
  ): void => {
    handleIsSending(true);

    const portalDomainsData = {
      isTrackingCustomDomainEnabled: formData.isTrackingCustomDomainEnabled,
      trackingSlug: formData.trackingSlug,
    };

    const portalDomainsInitialData = {
      isTrackingCustomDomainEnabled: originalData.isTrackingCustomDomainEnabled,
      trackingSlug: originalData.trackingSlug,
    };

    let updateBrandingSettingPromise: Promise<any> = Promise.resolve(true);

    if (!isEqual(portalDomainsData, portalDomainsInitialData)) {
      updateBrandingSettingPromise = updateBrandingSettings(storeBrandingId, portalDomainsData);
    }

    const formDataCopy = { ...formData };
    const originalDataCopy = { ...originalData };

    delete formDataCopy.slug;
    delete formDataCopy.trackingSlug;
    delete formDataCopy.isTrackingCustomDomainEnabled;
    delete originalDataCopy.slug;
    delete originalDataCopy.trackingSlug;
    delete originalDataCopy.isTrackingCustomDomainEnabled;
    // ======= tracking auth BG ==============================
    delete formDataCopy.trackingDesktopBg;

    let updateSettingsRequestPromise: Promise<any> = Promise.resolve(true);

    if (formData.trackingDesktopBg !== storeBrandingData?.portalSettings?.trackingDesktopBg) {
      updateSettingsRequestPromise = updateBrandings({
        storeBranding: {
          ...storeBrandingData,
          portalSettings: {
            ...storeBrandingData?.portalSettings,
            trackingDesktopBg: formData.trackingDesktopBg || '',
          },
        },
        allStoreBrandings: allStoreBrandingsData || [],
      })
        .then(() => refetchBrandings())
        .then(() => {
          if (!isEqual(formDataCopy, originalDataCopy)) {
            const serialized: IPortalSettings = serializeColors(formDataCopy);
            updateSettingsRequest(serialized);
          }
        })
        .finally(() => handleClose());
    } else if (!isEqual(formDataCopy, originalDataCopy)) {
      const serialized: IPortalSettings = serializeColors(formDataCopy);
      updateSettingsRequestPromise = updateSettingsRequest(serialized);
    }

    Promise.all([updateBrandingSettingPromise, updateSettingsRequestPromise])
      .catch((error) =>
        // eslint-disable-next-line no-console
        console.log(error),
      )
      .finally(() => {
        if (isEqual(formDataCopy, originalDataCopy)) {
          handleClose();
        }
        handleIsSending(false);
      });
  };

  return {
    contextValue: {
      addModuleTopValue,
      goToModule,
      uploadFileRequest,
    },
    originalData: {
      ...portalSettings,
      trackingDesktopBg: storeBrandingData?.portalSettings?.trackingDesktopBg,
    },
    modulesRef,
    handleScroll,
    currentModule,
    handleUpdateSettings,
    isSending,
    handleIsSending,
  };
};

const EditModal: FC<EditModalProps> = (props) => {
  const {
    isVisible,
    handleClose,
    selectedTab: defaultSelectedTab,
    uploadFileRequest,
    updateSettingsRequest,
    appLang,
    portalSettings,
    upgradeButton,
    features,
    storeBrandingId,
    currency,
  } = props;
  const {
    contextValue,
    modulesRef,
    originalData,
    handleScroll,
    currentModule,
    handleUpdateSettings,
    isSending,
  } = useEditModal({
    handleClose,
    defaultSelectedTab,
    uploadFileRequest,
    updateSettingsRequest,
    portalSettings,
    storeBrandingId,
  });
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(appLang);
  }, [appLang]);
  const { t } = useTranslation('trackingPage', { keyPrefix: 'editMode' });
  if (!originalData) return null;

  return (
    <BaseModal
      isVisible={isVisible}
      handleClose={handleClose}
      title={<span className="text-primary font-semibold">{t('title')}</span>}
      customStyle={{ maxHeight: 'calc(100vh - 80px)', position: 'relative', zIndex: 1 }}
      isOv
    >
      <div className="w-full flex justify-between overflow-hidden" style={{ maxHeight: '100%' }}>
        {/* eslint-disable-next-line no-console */}
        <Formik
          initialValues={originalData}
          onSubmit={(values) => handleUpdateSettings(values, originalData)}
          validationSchema={editModalSchema}
          enableReinitialize
        >
          {({ initialValues, values, setFieldValue, errors }) => (
            <Form className="block w-full">
              {/* eslint-disable react/jsx-no-constructed-context-values */}
              <EditModalContext.Provider
                value={{
                  ...contextValue,
                  formData: values,
                  setFieldValue,
                  upgradeButton,
                  features,
                  currency,
                }}
              >
                {/* eslint-enable react/jsx-no-constructed-context-values */}
                <div
                  className="w-full flex justify-between overflow-hidden"
                  style={{ maxHeight: '100%' }}
                >
                  <Navbar currentModule={currentModule} />
                  <ModulesContainer
                    values={values}
                    initialValues={initialValues}
                    modulesRef={modulesRef}
                    handleScroll={handleScroll}
                    isSending={isSending}
                    errors={errors}
                    storeBrandingId={storeBrandingId}
                  />
                </div>
              </EditModalContext.Provider>
            </Form>
          )}
        </Formik>
      </div>
    </BaseModal>
  );
};

export default EditModal;
