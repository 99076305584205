import React, { useContext } from 'react';

import { useGetPaletteColors } from 'return-portal-ui';

import TrackingPageContext from '../../../../Context/TrackingPageContext';
import { EDIT_MODE_TABS } from '../../../../Interfaces';
import Card from '../../../Common/Card';
import EditButton from '../../../Common/EditButton';

const StatusHighlight = () => {
  const {
    trackingData,
    shipmentHeaderComponent,
    appSettings: { isEdit },
  } = useContext(TrackingPageContext);
  const { primaryText } = useGetPaletteColors();

  if (!trackingData || !shipmentHeaderComponent) return null;

  return (
    <Card
      wrapperClassName="md:mb-2 flex-1"
      className="md:p-4 p-6 relative w-full md:flex-row flex-col gap-4 flex justify-center items-center"
    >
      <div style={{ color: primaryText }} className="flex-1">
        {shipmentHeaderComponent}
      </div>

      {isEdit && <EditButton tab={EDIT_MODE_TABS.TRACKING} />}
    </Card>
  );
};

export default StatusHighlight;
