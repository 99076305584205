import React, { FC } from 'react';

interface NavbarTab {
  name: string;
  isSelected: boolean;
  onClick?(): void;
}

const NavbarTab: FC<NavbarTab> = (props) => {
  const { name, isSelected, onClick } = props;
  const selectedStyle = 'border-l border-primary border-l-[4px] bg-hub-100';
  const baseStyle =
    'transition-colors duration-100 font-semibold text-sm py-3 px-4 rounded-r text-left text-primaryTP-800 hover:bg-hub-100';

  return (
    <button
      type="button"
      className={`${baseStyle} ${isSelected ? selectedStyle : 'opacity-60 pl-5'}`}
      onClick={onClick}
    >
      {name}
    </button>
  );
};

export default NavbarTab;
