import React, { FC } from 'react';

import { SvgIconComponent } from '@mui/icons-material';

import { ButtonTypes } from '../../Interfaces/IButton';

const btnClassName =
  'border border-tp-border flex justify-between items-center w-max px-[18px] py-2 rounded-[10px]';
interface UseButton {
  type: ButtonTypes;
  sourceIconColor?: string;
  sourceIconSize?: 'small' | 'inherit' | 'large' | 'medium' | undefined;
  isDisabled: boolean;
}

interface UseButtonReturn {
  className: string;
  colorClassName: string;
  iconSize: 'small' | 'inherit' | 'large' | 'medium' | undefined;
}

interface Button {
  isSubmit?: boolean;
  content?: string | React.ReactNode;
  RightIcon?: SvgIconComponent;
  LeftIcon?: SvgIconComponent;
  ReactLeftIcon?: React.ReactNode; // React Node type Icon ( for example a svg )
  type?: ButtonTypes;
  iconColor?: string;
  iconSize?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: any;
  children?: React.ReactNode;
  isDisabled?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

const useButton = (props: UseButton): UseButtonReturn => {
  const { type, sourceIconColor, sourceIconSize, isDisabled } = props;
  let className: string;
  let colorClassName: string;
  const iconSize = sourceIconSize || 'inherit';

  switch (type) {
    case ButtonTypes.PRIMARY:
      className = `${btnClassName}  bg-tp-primary text-tp-secondaryBg border-0`;
      colorClassName = 'text-tp-secondaryBg';
      break;
    case ButtonTypes.SECONDARY:
      className = `${btnClassName}  bg-tp-secondaryBg text-tp-primaryText`;
      colorClassName = 'text-tp-primary';
      break;
    case ButtonTypes.ALERT:
      className = `${btnClassName}  bg-tp-alertColor text-tp-secondaryBg border-0`;
      colorClassName = 'text-tp-secondaryBg';
      break;
    case ButtonTypes.MAIN:
      className = `${btnClassName}  bg-tp-secondary text-tp-secondaryBg border-0 shadow-2xl`;
      colorClassName = 'text-tp-secondaryBg';
      break;
    case ButtonTypes.GRAY:
      className = `${btnClassName} bg-slate-100 text-tp-primaryText border-0`;
      colorClassName = 'text-tp-primary';
      break;
    case ButtonTypes.OV_PRIMARY:
      className = `${btnClassName}  text-white bg-primary border-0`;
      colorClassName = 'text-tp-secondaryBg';
      break;
    case ButtonTypes.OV_PRIMARY_DARK:
      className = `${btnClassName}  text-white bg-primaryTP-800 border-0 shadow`;
      colorClassName = 'text-white';
      break;
    case ButtonTypes.OV_DANGER:
      className = `${btnClassName} bg-redTP-500 bg-opacity-10 border-0 text-redTP-500`;
      colorClassName = 'text-white';
      break;
    default:
      className = `${btnClassName} bg-tp-primary text-tp-secondaryBg border-0`;
      colorClassName = 'text-tp-primary';
      break;
  }

  if (sourceIconColor) colorClassName = sourceIconColor;

  if (isDisabled) className = `${className} opacity-30`;

  return { className, iconSize, colorClassName };
};

const Button: FC<Button> = (props) => {
  const {
    isSubmit,
    iconColor: sourceIconColor,
    content,
    RightIcon,
    LeftIcon,
    type = ButtonTypes.PRIMARY,
    iconSize,
    onClick,
    children,
    isDisabled = false,
    className: overrideClassName,
    style: overrideStyle,
    ReactLeftIcon,
  } = props;
  const { className, colorClassName } = useButton({
    type,
    sourceIconColor,
    isDisabled,
  });

  const iconClassName = 'flex items-center';

  return (
    <button
      type={isSubmit ? 'submit' : 'button'}
      className={overrideClassName || className}
      onClick={onClick}
      disabled={isDisabled}
      style={overrideStyle}
    >
      {LeftIcon && (
        <span style={{ fontSize: iconSize || 'inherit' }} className={iconClassName}>
          <LeftIcon fontSize={iconSize ? 'inherit' : 'medium'} className={colorClassName} />
        </span>
      )}
      {ReactLeftIcon || false}
      {content && <span className="mx-2 text-sm font-semibold">{content}</span>}
      {children}
      {RightIcon && (
        <span style={{ fontSize: iconSize || 'inherit' }} className={iconClassName}>
          <RightIcon fontSize={iconSize ? 'inherit' : 'medium'} className={colorClassName} />
        </span>
      )}
    </button>
  );
};

export default Button;
