import { useState } from 'react';

import { useCreditWhatsapp, useGetWhatsappListInfo } from 'data-layer';
import { CheckSquare } from 'icons';
import { FormattedMessage } from 'localization';

import { Copy, DialogContainer } from '../../atoms';
import { ConfirmationWindow } from '../../molecules';
import HelpToImprove from './downgradeSteps/WhatsAppHelpToImproveStep';

type WhatsAppForDeskDowngradeProps = {
  onClose: () => void;
  storeId: string;
};

enum DowngradeSteps {
  none,
  downgrade,
  done,
}

const WhatsAppForDeskDowngrade = ({ onClose, storeId }: WhatsAppForDeskDowngradeProps) => {
  const [step, setStep] = useState<DowngradeSteps>(DowngradeSteps.downgrade);

  const { refetch } = useGetWhatsappListInfo(storeId);
  const { refetch: creditRefetch } = useCreditWhatsapp(true);

  return (
    <>
      <DialogContainer
        className="p-0 max-w-[912px] rounded-[22px]"
        wrapperClassName="p-0 md:p-4 py-10"
        isOpen={step === DowngradeSteps.downgrade}
        handleClose={() => setStep(DowngradeSteps.none)}
        afterLeave={() => {
          if (step === DowngradeSteps.none) {
            onClose();
          }
        }}
      >
        <HelpToImprove
          onDowngradeDone={() => {
            refetch();
            creditRefetch();
            setStep(DowngradeSteps.done);
          }}
          onClose={() => setStep(DowngradeSteps.none)}
          storeId={storeId}
        />
      </DialogContainer>

      <DialogContainer
        isOpen={step === DowngradeSteps.done}
        handleClose={() => setStep(DowngradeSteps.none)}
        afterLeave={() => {
          if (step === DowngradeSteps.none) {
            onClose();
          }
        }}
      >
        <ConfirmationWindow
          title={<FormattedMessage id="desk-app.whatsapp.downgrade.success.title" />}
          cancelButtonTitle={<FormattedMessage id="general.close" />}
          theme="green"
          onCancel={() => setStep(DowngradeSteps.none)}
          headingIcon={<CheckSquare className="text-2xl" />}
          content={
            <Copy className="text-center">
              <FormattedMessage id="desk-app.whatsapp.downgrade.success.description" />
            </Copy>
          }
        />
      </DialogContainer>
    </>
  );
};

export default WhatsAppForDeskDowngrade;
