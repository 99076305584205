import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';

import { ChromePicker, ColorResult } from 'react-color';

const COLOR_SELECTOR_WIDTH = '40px';
const COLOR_SELECTOR_HEIGHT = '40px';

interface UseColorSelector {
  startColor?: string;
  extOnChange?(colorResult: ColorResult, e: ChangeEvent<HTMLInputElement>): void;
  extOnSubmit?(colorResult: ColorResult, e: ChangeEvent<HTMLInputElement>): void;
  ref: React.RefObject<HTMLDivElement>;
}

interface ColorSelector {
  onChange?(colorResult: ColorResult, e: ChangeEvent<HTMLInputElement>): void;
  onSubmit?(colorResult: ColorResult, e: ChangeEvent<HTMLInputElement>): void;
  color?: string;
  style?: React.CSSProperties;
  className?: string;
}

const useColorSelector = (props: UseColorSelector) => {
  const { startColor, extOnChange, extOnSubmit, ref } = props;
  const [color, setColor] = useState(startColor || '#FFF');
  const [isSelectorVisible, setIsSelectorVisible] = useState(false);
  const toggleIsSelectorVisible = (value: boolean) => setIsSelectorVisible(value);
  const handleChange = (colorResult: ColorResult, e: ChangeEvent<HTMLInputElement>) => {
    const { hex } = colorResult;
    if (extOnChange) extOnChange(colorResult, e);
    setColor(hex);
  };
  const handleChangeComplete = (colorResult: ColorResult, e: ChangeEvent<HTMLInputElement>) => {
    const { hex } = colorResult;
    if (extOnSubmit) extOnSubmit(colorResult, e);
    setColor(hex);
  };
  const onClickOutside = () => toggleIsSelectorVisible(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClickOutside();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickOutside]);

  return {
    color,
    handleChange,
    handleChangeComplete,
    isSelectorVisible,
    toggleIsSelectorVisible,
  };
};

const ColorSelector: FC<ColorSelector> = (props) => {
  const {
    onChange: extOnChange,
    onSubmit: extOnSubmit,
    color: startColor,
    style: overrideStyle,
    className: overrideClassName,
  } = props;
  const ref = useRef<HTMLDivElement>(null);
  const { color, handleChange, handleChangeComplete, isSelectorVisible, toggleIsSelectorVisible } =
    useColorSelector({
      startColor,
      extOnSubmit,
      extOnChange,
      ref,
    });

  return (
    <div className={overrideClassName || 'relative'} style={overrideStyle} ref={ref}>
      <button
        aria-label="Select color"
        type="button"
        className="border border-grayTP-200 rounded"
        style={{
          backgroundColor: color,
          width: COLOR_SELECTOR_WIDTH,
          height: COLOR_SELECTOR_HEIGHT,
        }}
        onClick={() => toggleIsSelectorVisible(!isSelectorVisible)}
      />
      <ChromePicker
        color={color}
        onChangeComplete={(colorResult, e) => handleChangeComplete(colorResult, e)}
        onChange={(colorResult, e) => handleChange(colorResult, e)}
        className={`${isSelectorVisible ? '' : 'hidden'} absolute top-10 z-50`}
        disableAlpha
      />
    </div>
  );
};

export default ColorSelector;
