import React, { FC, useContext, useMemo } from 'react';

import TrackingPageContext from '../../../Context/TrackingPageContext';
import { BannerModes, IBanner } from '../../../Interfaces';
import Card from '../../Common/Card';
import Image from '../../Common/Image';

export const BANNER_VERTICAL_WIDTH = '268px';
export const BANNER_Z_INDEX = '40';

interface UseBanner {
  mobile?: IBanner;
  desktop?: IBanner;
  componentMode: BannerModes;
}

interface Banner {
  componentMode: BannerModes;
}

const useBanner = (props: UseBanner) => {
  const { mobile, desktop, componentMode } = props;
  const styles: { bannerStyle: string; inlineStyle: React.CSSProperties } = useMemo(() => {
    let bannerStyle = '';
    const inlineStyle: React.CSSProperties = {};
    switch (componentMode) {
      case BannerModes.HORIZONTAL:
        bannerStyle = 'hidden w-full static md:block';
        break;

      case BannerModes.MOBILE:
        bannerStyle = 'w-full block static bottom-0 md:hidden';

        break;
      default:
        break;
    }

    return { bannerStyle, inlineStyle };
  }, [componentMode]);
  const { bannerStyle, inlineStyle } = styles;

  return {
    bannerStyle,
    inlineStyle,
    banner: componentMode === BannerModes.MOBILE ? mobile : desktop,
  };
};

const Banner: FC<Banner> = (props) => {
  const {
    portalSettings: {
      regionalSettings: { banners },
    },
    isError,
  } = useContext(TrackingPageContext);

  const desktop = banners?.desktop;
  const url = banners?.url;
  const mobile = banners?.mobile;

  const { componentMode } = props;
  const { bannerStyle, inlineStyle, banner } = useBanner({ desktop, mobile, componentMode });

  if (isError || !banner) return null;

  const { imgSrc } = banner;

  function renderBanner() {
    return (
      <div className={bannerStyle} style={inlineStyle}>
        <Card className="relative overflow-hidden" hasBottomBorder={false}>
          <a
            href={url}
            className={bannerStyle}
            style={inlineStyle}
            target="_blank"
            rel="noreferrer"
          >
            <Image src={imgSrc} alt="Banner" customStyle="p-0" width="100%" height="100%" />
          </a>
        </Card>
      </div>
    );
  }

  return bannerStyle ? renderBanner() : null;
};

export default Banner;
