import React, { useMemo } from 'react';

import Select, { NamedProps, components } from 'react-select';

import { Theme, useMediaQuery } from '@material-ui/core';
import ArrowDownIcon from '@material-ui/icons/ArrowDropDownRounded';

import {
  mobileNormalSizeErrorStyles,
  mobileNormalSizeStyles,
  normalSizeErrorStyles,
  normalSizeStyles,
} from './customStyles';

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowDownIcon color="inherit" fontSize="inherit" />
    </components.DropdownIndicator>
  );
};

interface IOVSelect extends NamedProps {
  error?: boolean;
  providedStyles?: any;
}

const OVSelect = ({
  options,
  hideSelectedOptions = false,
  isMulti = false,
  isSearchable = false,
  isClearable = false,
  closeMenuOnSelect = true,
  providedStyles,
  error = false,
  components,
  ...rest
}: IOVSelect) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const styles = useMemo(() => {
    if (providedStyles) {
      return providedStyles;
    }

    if (isMobile && error) {
      return mobileNormalSizeErrorStyles;
    } else if (isMobile && !error) {
      return mobileNormalSizeStyles;
    } else if (!isMobile && error) {
      return normalSizeErrorStyles;
    } else if (!isMobile && !error) {
      return normalSizeStyles;
    }
  }, [providedStyles, isMobile, error]);

  const selectComponents = useMemo(() => ({ DropdownIndicator, ...components }), [components]);

  return (
    <Select
      options={options}
      styles={styles}
      hideSelectedOptions={hideSelectedOptions}
      components={selectComponents}
      isMulti={isMulti}
      isSearchable={isSearchable}
      isClearable={isClearable}
      closeMenuOnSelect={closeMenuOnSelect}
      menuPlacement="auto"
      menuPortalTarget={document.body}
      classNamePrefix="ov-react-select"
      {...rest}
    />
  );
};

export default OVSelect;
