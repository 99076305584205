import { useMutation } from '@tanstack/react-query';

import { deleteCustomDomainByStore } from '../mutations';

export type DeleteCustomDomainByStoreProps = {
  storeBrandingId: string;
  customDomainId: string;
};

export function useDeleteCustomDomainByStore() {
  return useMutation(({ storeBrandingId, customDomainId }: DeleteCustomDomainByStoreProps) =>
    deleteCustomDomainByStore(storeBrandingId, customDomainId),
  );
}
