import { useState } from 'react';

import { useGetWhatsappListInfo } from 'data-layer';
import { Close } from 'icons';

import { DialogContainer } from '../../atoms';
import { TOption } from '../../molecules/Dropdown/Dropdown';
import RequestFeatureConfirmInfoModal from '../RequestNewFeature/RequestFeatureConfirmInfoModal';
import SetupWhatsappModalContent from '../WhatsAppModal/SetupWhatsappModalContent';
import WhatsAppIntroStep from './steps/WhatsAppIntroStep';
import WhatsAppPaymentDoneStep from './steps/WhatsAppPaymentDoneStep';
import WhatsAppPaymentStep from './steps/WhatsAppPaymentStep';
import WhatsAppRequestSpecificPriceStep from './steps/WhatsAppRequestSpecificPriceStep';

type WhatsAppForDeskPaymentProps = {
  onClose: () => void;
  storeId?: string;
  availableBrandsToConnect: TOption[];
  alreadyHasWhatsApp: boolean;
  updateData: () => void;
};

const WhatsAppForDeskPayment = ({
  onClose,
  storeId,
  availableBrandsToConnect,
  alreadyHasWhatsApp,
  updateData,
}: WhatsAppForDeskPaymentProps) => {
  const [step, setStep] = useState<WhatsapPaymentSteps>(
    alreadyHasWhatsApp ? WhatsapPaymentSteps.paymentForm : WhatsapPaymentSteps.intro,
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { refetch } = useGetWhatsappListInfo(storeId);

  return (
    <>
      <DialogContainer
        className="p-0 rounded-[22px] max-w-[796px]"
        wrapperClassName="p-0 md:p-4 py-10"
        disableMaxWidth
        isOpen={step === WhatsapPaymentSteps.intro}
        handleClose={() => setStep(WhatsapPaymentSteps.none)}
        afterLeave={() => {
          if (step === WhatsapPaymentSteps.none) {
            onClose();
          }
        }}
      >
        <WhatsAppIntroStep
          onNexStep={() =>
            setStep(
              availableBrandsToConnect.length
                ? WhatsapPaymentSteps.onboarding
                : WhatsapPaymentSteps.paymentForm,
            )
          }
        />
      </DialogContainer>

      <DialogContainer
        className="max-w-[580px]"
        disableMaxWidth
        isOpen={step === WhatsapPaymentSteps.onboarding}
        handleClose={() => setStep(WhatsapPaymentSteps.none)}
        afterLeave={() => {
          if (step === WhatsapPaymentSteps.none) {
            onClose();
          }
        }}
      >
        <Close
          className="absolute top-8 right-8 hover:cursor-pointer text-v2blueGray-550"
          onClick={() => setStep(WhatsapPaymentSteps.none)}
          width={26}
          height={26}
        />

        <SetupWhatsappModalContent
          availableBrands={availableBrandsToConnect}
          onClose={() => setStep(WhatsapPaymentSteps.none)}
          onNextStep={() => setStep(WhatsapPaymentSteps.paymentForm)}
          updateData={refetch}
        />
      </DialogContainer>

      <DialogContainer
        className="p-0 rounded-[22px] max-w-[912px]"
        wrapperClassName="p-0 md:p-4 py-10"
        disableMaxWidth
        isOpen={step === WhatsapPaymentSteps.paymentForm}
        handleClose={() => {
          isSubmitting || !alreadyHasWhatsApp ? null : setStep(WhatsapPaymentSteps.none);
        }}
        afterLeave={() => {
          if (step === WhatsapPaymentSteps.none) {
            onClose();
          }

          setIsSubmitting(false);
        }}
      >
        <WhatsAppPaymentStep
          onRequestSpecificPrice={() => setStep(WhatsapPaymentSteps.requestPrice)}
          onPaymentDone={() => {
            updateData();
            setStep(WhatsapPaymentSteps.paymentDone);
          }}
          isSubmitting={isSubmitting}
          handleSubmitChange={setIsSubmitting}
          storeId={storeId}
        />
      </DialogContainer>

      <DialogContainer
        className="p-0 rounded-[22px] max-w-[796px]"
        wrapperClassName="p-0 md:p-4 py-10"
        disableMaxWidth
        isOpen={step === WhatsapPaymentSteps.paymentDone}
        handleClose={() => null}
        afterLeave={() => {
          if (step === WhatsapPaymentSteps.none) {
            onClose();
          }
        }}
      >
        <WhatsAppPaymentDoneStep onClose={() => setStep(WhatsapPaymentSteps.none)} />
      </DialogContainer>

      <WhatsAppRequestSpecificPriceStep
        isOpen={step === WhatsapPaymentSteps.requestPrice}
        onCancel={() => setStep(WhatsapPaymentSteps.paymentForm)}
        onConfirm={() => setStep(WhatsapPaymentSteps.requestPriceDone)}
        storeId={storeId}
      />

      <RequestFeatureConfirmInfoModal
        isOpen={step === WhatsapPaymentSteps.requestPriceDone}
        onClose={() => setStep(WhatsapPaymentSteps.none)}
        afterLeave={onClose}
      />
    </>
  );
};

export default WhatsAppForDeskPayment;

enum WhatsapPaymentSteps {
  none,
  intro,
  onboarding,
  paymentForm,
  paymentDone,
  requestPrice,
  requestPriceDone,
}
