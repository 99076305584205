import { CourierDetailResponse } from 'app/src/modules/CmsModule/components/Desk/ts/courier';

import { ICourier } from './couriers';
import { PostOfficeAddress } from './interfaces';

export enum WeightMetricSystems {
  KG = 'kg',
  G = 'g',
  LB = 'lb',
  OZ = 'oz',
}

export enum LengthMetricSystems {
  CM = 'cm',
  M = 'm',
  IN = 'in',
  FT = 'ft',
}

export interface User {
  _id: string;
}

export interface ShipmentPackage<ProductType = string> {
  _id: string;
  otn: string;
  isShipped: boolean;
  isDelivered: boolean;
  inTransit: boolean;
  isReturned: boolean;
  isArchived: boolean;
  weight: number;
  products: ProductType[];
  package: string; // id of used packaging
  warehouse: string;
  packaging: {
    name: string;
    length: number;
    width: number;
    height: number;
  };
}

export interface Shipment<D = string, PackageType = string> {
  createdAt: string;
  _id: string;
  otn: string;
  cost: number;
  customCourierCost?: number;
  CODCost: number;
  finalCost?: number;
  finalVatCost?: number;
  vatCost?: number;
  insuranceCost: number;
  refund: number;
  courier: string;
  courier_id: number;
  method: string;
  weight: number;
  trackingNumber: string;
  secondaryTrackingNumber?: string;
  secondaryCourier?: string;
  secondaryCourierId?: number;
  isShipped: boolean;
  return?: {
    value: number;
    client: number;
    refund: number;
    shipping: number;
  };
  isReturn: boolean;
  isCompared: boolean;
  isAdjusted: boolean;
  order: string;
  packages: PackageType[];
  label?: string;
  delivery: D;
  pickup: D;
  deliveryPostOfficeLocation?: string | PostOfficeAddress;
  isPickUpDone: boolean;
  skipPickup: boolean;
  isPickUpFailed: boolean;
  trackingOnly: boolean;
  pickupDate?: string;
  deliverDate?: string;
  returnDeliverDate?: string;
  estimatedDeliverDate?: string;
  processDate?: string;
  returnStatus?: string;
  returnedAfter?: number;
  reimbursement: 'SAME_AS_PAYMENT' | 'CREDIT_IN_STORE';
  currentLocation?: CurrentLocation;
}

export interface CurrentLocation {
  code: string;
  coordinates: string;
  courier: string;
  courierCode: string;
  date: string;
  description: string;
  status: string;
  substatus: string;
}

export interface Product {
  _id: string;
  name: string;
  price: number;
  discountPrice?: number;
  invoicePrice?: number;
  vat: number;
  quantity: number;
  barcode?: string;
  sku?: string;
  variant?: string;
  weight?: number;
  pictureUrl?: string;
  description?: string;
  hsCode: string;
  packaged: boolean;
  locationInWarehouse?: string;
  returned: Returned;
  returnable: boolean;
  notifyStore: boolean;
  notifyStoreValue?: string;
  notifyStoreValueAdded?: boolean;
  stock?: number;
  isOutOfStock?: boolean;
  [key: string]: any;
}

export interface Returned {
  client: {
    reason?: string;
    images: string[];
    comment?: string;
  };
  store: {
    accepted?: null | boolean;
    reason?: string;
    images: string[];
    comment?: string;
  };
  date?: null | string;
}

export interface OrderWarning {
  item: string;
  text: string;
  code: number;
  msg: string;
  blocking: boolean;
}

export interface Order {
  // Base data
  createdAt: string;
  discountApplied?: number;
  _id: string;
  otn: string;
  id: string;
  originalId?: string;
  dateTime: string;
  total: number;
  tax: number;
  currency: string;
  invoicingCompany: string;
  invoicingCompanyTaxId: string;
  payment: {
    status: string;
    method: string;
    original: string;
  };
  source: string;
  // Dates
  processDate: string;
  shipmentDescription?: string;
  deliverDate: string;
  returnProcessDate: string;
  returnDeliverDate: string;
  // Refs
  user: string | User;
  customer: string;
  // Flags
  isInPickingQueue: boolean;
  isInShippingQueue: boolean;
  inTransit: boolean;
  isPartiallyDelivered: boolean;
  isArchived: boolean;
  isDeleted: boolean;
  isRefunded: boolean;
  isDirty: boolean;
  isClosed: boolean;
  hasClosedReturn: boolean;
  isRecipientChecked: boolean;
  isProcessing: boolean;
  isReverseOrder: boolean;
  returnStatus?: string;
  // End customer
  client: {
    invoicing: {
      name: string;
      email: string;
      phone: string;
      postcode: string;
      country: string;
      countryCode: string;
      state: string;
      city: string;
      address: string;
      dni: string;
    };
    delivery: {
      name: string;
      email: string;
      phone: string;
      postcode: string;
      country: string;
      countryCode: string;
      state: string;
      city: string;
      address: string;
      comment: string;
      dni: string;
    };
  };
  // Products
  products: Product[];
  // Shipping
  warehouse: string;
  shipping: {
    price: number;
    courier: string;
    method: string;
    originalMethod?: string;
    matched_methods?: { courier_id: string; method_id: string }[];
    CODCost: number;
    possibleInsurance: number;
    isInsured: boolean;
    withCustomCourier: boolean;
    cost: {
      shipping: number;
      return: number;
    };
    packages: {
      shipping: ShipmentPackage[];
      return: ShipmentPackage[];
    };
    shipments: {
      shipping: Shipment[];
      return: Shipment[];
    };
  };
  // Other
  warnings: OrderWarning[];
  returnTotal?: number;
  refund?: number;
  gift?: {
    wrapAsGift?: boolean;
    price?: number;
    recyclable?: boolean;
    comment?: string;
  };
  threePl: any;
  inTransitStatus: string;
  currentStatus?: string;
  previousStatus?: string;
  returnOrderRefs?: string[];
  returnId?: string;
  returnData?: {
    hasExchange?: boolean;
    hasReturn?: boolean;
    creditAmount?: number;
    couponTrigger?: string;
    clientPays?: number;
    storeReturns?: number;
    paymentCompleted?: boolean;
  };
  formattedTimes?: {
    processDate?: string;
    dateTime?: string;
    pickingDate?: string;
  };
  lengthUnit?: LengthMetricSystems;
  weightUnit?: WeightMetricSystems;
}

export interface ReturnOrder extends Order {
  return?: {
    value?: number;
    shipping?: number;
    client?: number;
    refund?: number;
    hasProcessedProduct?: boolean;
  };
}

export interface OrderSearchResult extends Order {
  return: Shipment[];
  shipment: Shipment[];
}

export interface IResponseOrder {
  success: boolean;
  data: Order;
}

export interface IResponseCourierIcons {
  success: boolean;
  data: ICourier[];
}

export interface IResponseDeskSidebarOrder {
  success: boolean;
  orders: OrdersInfoByCustomer;
}

export interface IResponseCourierDetail {
  success: boolean;
  data: CourierDetailResponse;
}

export interface DeskSidebarOrder {
  id: string;
  updatedAt: string;
  createdAt: string;
  currency: string;
  total: number;
  otn: string;
  processDate: string;
  originalId: string;
}

export interface OrdersInfoByCustomer {
  ltv: number;
  orderCount: number;
  orders: DeskSidebarOrder[];
}

export interface IResponseDeskSidebarOrder {
  success: boolean;
  orders: OrdersInfoByCustomer;
}

export interface DeskSidebarOrder {
  id: string;
  updatedAt: string;
  createdAt: string;
  currency: string;
  total: number;
  otn: string;
  processDate: string;
  originalId: string;
}

export interface OrdersInfoByCustomer {
  ltv: number;
  orderCount: number;
  orders: DeskSidebarOrder[];
}

export interface IResponseCustomerStats {
  success: boolean;
  data: {
    orderCount: number;
    ltv: number;
  };
}

export interface IResponseCourierStats {
  success: boolean;
  data: {
    name: string;
    alias: any;
    integration: string;
    phone: any;
    stats: number;
  };
}
