import React, { FC, useContext, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { useGetPaletteColors } from 'return-portal-ui';
import { Copy } from 'ui/atoms';

import TrackingPageContext from '../../../../Context/TrackingPageContext';
import { IOrderProduct } from '../../../../Interfaces';
import Product from './Product';

const Products: FC = () => {
  const { appSettings } = useContext(TrackingPageContext);
  const { primaryText } = useGetPaletteColors();

  const { t, i18n } = useTranslation('trackingPage', { keyPrefix: 'general.splitOrder' });
  const { trackingData, isSplitOrder, othersProducts } = useContext(TrackingPageContext);

  useEffect(() => {
    if (appSettings?.lang && !appSettings?.isEdit) {
      i18n.changeLanguage(appSettings.lang || 'en');
    }
  }, [appSettings?.lang, appSettings?.isEdit]);

  if (!trackingData) return null;

  const {
    orderInfo: { products },
  } = trackingData;

  const renderProduct = (product: IOrderProduct, index: number) => {
    const { name } = product;
    return <Product key={`product-${name}-${index}`} product={product} />;
  };

  if (isSplitOrder && othersProducts?.length) {
    return (
      <div className="flex flex-col w-full md:mr-5">
        <Copy as="div" type="copy3" style={{ color: primaryText }} className="opacity-60 px-2 pb-4">
          {t('productInThisShipment', { fallbackLng: 'en' })}
        </Copy>

        <div className="flex flex-col gap-4">{products?.map?.(renderProduct)}</div>
        <Copy
          as="div"
          type="copy3"
          style={{ color: primaryText }}
          className="opacity-60 pb-4 px-2 mt-6"
        >
          {t('productShippedSeparately', { fallbackLng: 'en' })}
        </Copy>

        <div className="flex flex-col gap-4">
          {othersProducts
            ?.map?.((product) => ({
              name: product.name || 'Product',
              amount: product.quantity || 0,
              price: `${product.price}` || '',
              imgSrc: product.pictureUrl || '',
              variant: product.variant || '',
              discountPrice: product.discountPrice || '',
            }))
            ?.map?.(renderProduct)}
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full md:pr-8 pr-6 gap-4">{products.map(renderProduct)}</div>
  );
};

export default Products;
