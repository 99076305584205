import { Languages } from '../desk';
import { Language, StoreSettingsPopulated } from './interfaces';

export enum UserRole {
  user = 'user',
  customer = 'customer',
  owner = 'owner',
  admin = 'admin',
  administrator = 'administrator',
  manager = 'manager',
  warehouse = 'warehouse',
  customerSupport = 'customer_support',
  superAdmin = 'super-admin',
}

export enum AgentStatuses {
  Away = 'away',
  Offline = 'offline',
  Online = 'online',
  Reassign = 'reassign',
}

interface UserFragment {
  _id: string;
  status: AgentStatuses;
  signature?: string;
  signatures: { language: Languages; signature: string }[];
  emailOnlySignature: boolean;
  email: string;
  secondaryEmails: string[];
  url: string;
  firstname: string;
  lastname: string;
  position?: string;
  language: Language;
  roles: UserRole[];
  isDebugging: boolean;
  hasPendingValidation: boolean;
  deleted: boolean;
  orderStaleAfter: number;
  timeZone: string;
  allowedWarehouses: string[];
  acceptedPrintTerms: boolean;
  active: boolean;
  communication: {
    alert: { stale: boolean };
    emailNotification: { tracking: boolean };
    return: { created: boolean; refund: boolean };
  };
  notificationFilter: {
    exceptionStatus: string;
    exceptionType: string[];
    shipmentStatus: string;
    source: string[];
  };
  permissions?: AdminPermissions[];
  printerType?: string;
  documentPrintingType?: string;
  priorityLabelType?: string;
  isDeskAgent: boolean;
}

export interface User extends UserFragment {
  storeSettings: string;
}

export interface UserPopulated extends UserFragment {
  storeSettings: StoreSettingsPopulated;
}

export enum AdminPermissions {
  IMPERSONATE_USER = 'IMPERSONATE_USER',
  SEND_TO_ACTIVE_CAMPAIGN = 'SEND_TO_ACTIVE_CAMPAIGN',
  DELETE_ACCOUNT = 'DELETE_ACCOUNT',
  ADD_CHARGES = 'ADD_CHARGES',
  CHANGE_SLUG = 'CHANGE_SLUG',
  ADD_CREDIT = 'ADD_CREDIT',
  ACTIVATE_ACCOUNT = 'ACTIVATE_ACCOUNT',
  CHANGE_PLAN_PRICE = 'CHANGE_PLAN_PRICE',
  CHANGE_SHIPPING_RATES = 'CHANGE_SHIPPING_RATES',
  UPDATE_CREDIT_SCORE = 'UPDATE_CREDIT_SCORE',
  UPDATE_CUSTOM_LABEL_FEE = 'UPDATE_CUSTOM_LABEL_FEE',
  EDIT_PICKUP_TIME = 'EDIT_PICKUP_TIME',
  CLIENTS_PAGE = 'CLIENTS_PAGE',
  SHIPMENTS_PAGE = 'SHIPMENTS_PAGE',
  ANALYTICS_PAGE = 'ANALYTICS_PAGE',
  HEALTH_CHECK_PAGE = 'HEALTH_CHECK_PAGE',
  SETTINGS_PAGE = 'SETTINGS_PAGE',
  COURIER_SUPPORT_PAGE = 'COURIER_SUPPORT_PAGE',
  TICKETS_PAGE = 'TICKETS_PAGE',
  KPI_PAGE = 'KPI_PAGE',
  RESET_CARD_VALIDATIONS = 'RESET_CARD_VALIDATIONS',
  CHANGE_3PL = 'CHANGE_3PL',
  PARTNERSHIP_PAGE = 'PARTNERSHIP_PAGE',
  ELASTICSEARCH_USAGE = 'ELASTICSEARCH_USAGE',
  POPUP_NOTIFICATION_PANEL = 'POPUP_NOTIFICATION_PANEL',
}
