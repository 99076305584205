import React from 'react';

const OriginIcon = ({ width = 41.25, color = '#4bb4b3' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} viewBox="0 0 41.25 48.125">
    <path
      d="M36.667 11.458h-4.584a11.459 11.459 0 0 0-22.917 0H4.583A4.6 4.6 0 0 0 0 16.042v27.5a4.6 4.6 0 0 0 4.583 4.583h32.084a4.6 4.6 0 0 0 4.583-4.583v-27.5a4.6 4.6 0 0 0-4.583-4.584zM20.625 4.583a6.866 6.866 0 0 1 6.875 6.875H13.75a6.866 6.866 0 0 1 6.875-6.875zm0 22.917a11.437 11.437 0 0 1-11.092-8.594 2.294 2.294 0 0 1 2.223-2.865A2.385 2.385 0 0 1 14 17.875a6.871 6.871 0 0 0 13.246 0 2.385 2.385 0 0 1 2.246-1.833 2.3 2.3 0 0 1 2.223 2.865 11.437 11.437 0 0 1-11.09 8.593z"
      fill={color}
    />
  </svg>
);

export default OriginIcon;
