import { CustomDomain } from '../constants';
import request from '../utils/request';

export type DeleteCustomDomainsResponseData = Pick<CustomDomain, 'domain' | 'nameServers'>;

type DeleteCustomDomainsResponse = {
  success: boolean;
};

export const deleteCustomDomainByStore = (
  storeBrandingId: string,
  customDomainId: string,
): Promise<boolean> =>
  request<DeleteCustomDomainsResponse | undefined>(
    `${process.env.OUTVIO_API_URL}/portal/${storeBrandingId}/custom-domain/${customDomainId}`,
    {
      method: 'DELETE',
      secure: true,
    },
  ).then((response) => {
    const { success } = response ?? {};
    return success ?? false;
  });
