import { Query } from 'app-types/returns';

import { RETURNS_CALCULATE_REVENUE_ANALYTICS } from '../../../gql/returns/queries';
import { useQueryReturns } from '../useQueryReturns';

type CalculateRevenueAnalytics = {
  from: string;
  to: string;
};

export function useGetCalculateRevenueAnalytics(variables: CalculateRevenueAnalytics) {
  return useQueryReturns<Query>(RETURNS_CALCULATE_REVENUE_ANALYTICS, {
    variables,
  });
}

export function useGetTotalRevenue(variables: CalculateRevenueAnalytics) {
  const result = useGetCalculateRevenueAnalytics(variables);

  return {
    data: result.data?.Returns_CalculateRevenueAnalytics.totalRevenue || 0,
    isLoading: result.loading,
    error: result.error,
    isFetching: result.loading,
  };
}

export function useGetAdditionalRevenue(variables: CalculateRevenueAnalytics) {
  const result = useGetCalculateRevenueAnalytics(variables);

  return {
    data: result.data?.Returns_CalculateRevenueAnalytics.additionalRevenue || 0,
    isLoading: result.loading,
    error: result.error,
    isFetching: result.loading,
  };
}

export function useGetRetainedRevenue(variables: CalculateRevenueAnalytics) {
  const result = useGetCalculateRevenueAnalytics(variables);

  return {
    data: result.data?.Returns_CalculateRevenueAnalytics.retainedRevenue || 0,
    isLoading: result.loading,
    error: result.error,
    isFetching: result.loading,
  };
}

export function useGetStoreCredits(variables: CalculateRevenueAnalytics) {
  const result = useGetCalculateRevenueAnalytics(variables);

  return {
    data: result.data?.Returns_CalculateRevenueAnalytics.storeCredits || 0,
    isLoading: result.loading,
    error: result.error,
    isFetching: result.loading,
  };
}
