import { Fragment, ReactNode, useEffect } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';

export interface PortalDialogProps {
  show: boolean;
  close: () => void;
  children: ReactNode;
  className?: string;
  classNameModal?: string;
}

const html = document.documentElement;
const PortalDialog = ({ show, close, children, className, classNameModal }: PortalDialogProps) => {
  useEffect(() => {
    html.style.removeProperty(`overflow`);
    html.style.removeProperty(`padding-right`);
  }, [open]);
  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog
        as="div"
        className={twMerge('fixed inset-0 z-10 flex justify-center items-center', className)}
        onClose={close}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-portal-black/50" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div
            className={twMerge(
              'transform bg-white rounded-lg border border-portal-black/10 shadow-[0_4px_8px_rgba(var(--portal-brand-color),0.2)]',
              classNameModal,
            )}
          >
            {children}
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};

export { PortalDialog };
