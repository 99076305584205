import { HTMLProps } from 'react';

export function GraphQLError({
  error,
  className = '',
}: { error: any } & HTMLProps<HTMLDivElement>) {
  if (!error) return null;

  return (
    <div className={`text-red ${className}`}>
      {error?.graphQLErrors?.[0]?.data
        ? Object.values(error.graphQLErrors[0].data).map((err: any, key) => (
            <span key={key}>
              {err.errors[0]}
              <br />
            </span>
          ))
        : error.message}
    </div>
  );
}
