import { Query } from 'app-types/desk-analytics';

import { DAILY_TICKETS_COUNT_TICKETS_PER_CHANNELS } from '../../../gql/desk-analytics/queries';
import { useQueryDeskAnalytics } from '../useQueryDeskAnalytics';

export function useGetDailyTicketPerChannels({ from, to }: { from?: string; to?: string }) {
  const { data, loading, error } = useQueryDeskAnalytics<Query>(
    DAILY_TICKETS_COUNT_TICKETS_PER_CHANNELS,
    {
      skip: !from || !to,
      variables: { from, to },
    },
  );
  return {
    data: data?.DailyTickets_countTicketsPerChannels,
    loading,
    error,
  };
}
