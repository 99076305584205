import { DefaultLanguage } from '../../types';

export const getReturnPortalLanguage = async (
  locale: DefaultLanguage | string,
): Promise<{ default: Record<string, string> }> => {
  switch (locale) {
    case DefaultLanguage.en:
      return import('./en-EN');
    case DefaultLanguage.es:
      return import('./es-ES');
    case DefaultLanguage.pt:
      return import('./pt-PT');
    case DefaultLanguage.de:
      return import('./de-DE');
    case DefaultLanguage.pl:
      return import('./pl-PL');
    default:
      return { default: {} };
  }
};
