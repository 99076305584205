import { ReactNode } from 'react';

import { useFormContext } from 'react-hook-form';

import { PortalCheckboxIcon } from '../../UI/PortalCheckbox/PortalCheckboxIcon';

// import './PortalCheckboxRHF.css';

const PortalCheckboxRHF = ({
  name,
  label,
  disabled,
}: {
  name: string;
  label?: ReactNode;
  disabled?: boolean;
}) => {
  const { register } = useFormContext();
  return (
    <label className="rhf-checkbox-container">
      <input disabled={disabled} value="" className="hidden" type="checkbox" {...register(name)} />
      <span className="rhf-checkbox-checkmark">
        <PortalCheckboxIcon />
      </span>
      {label}
    </label>
  );
};

export { PortalCheckboxRHF };
