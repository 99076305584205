import React, { FC } from 'react';

import useCourierIcon from '../../../../Hooks/useCourierIcons';

interface CourierIconProps {
  courier: string;
  className?: string;
}

const CourierIcon: FC<CourierIconProps> = ({ courier = '', className = '' }) => {
  const { data = [] } = useCourierIcon();
  const courierObj = data.find(
    (item) => item?.integration?.toLowerCase() === courier?.toLowerCase(),
  );

  return (
    <div className={className}>
      <img className="h-full" src={courierObj?.smallLogo} alt={courierObj?.name} />
    </div>
  );
};

export default CourierIcon;
