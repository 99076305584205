export enum LanguageInputTypes {
  TEXT,
  CHECKBOX,
  RADIO,
  TEXTAREA,
  RICH_TEXT,
}

export interface ILanguageInputInfo {
  title: string;
  objectKey: string;
  type: LanguageInputTypes;
}
