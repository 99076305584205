import React, { FC, InputHTMLAttributes } from 'react';

import { DropEvent, FileError, FileRejection, useDropzone } from 'react-dropzone';

import UploadRoundedIcon from '@mui/icons-material/UploadRounded';

interface FileUpload {
  html?: React.DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
  text?: string;
  description?: string;
  handleUpload<T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent,
  ): void;

  /**
   * Accepted files, example:
   *
   * accept: {
   *   'image/png': ['.png'],
   *   'text/html': ['.html', '.htm'],
   * }
   */
  accept?: any;
  validator?: <T extends File>(file: T) => FileError | FileError[] | null;
  errors: FileError[];
}

const FileUpload: FC<FileUpload> = (props) => {
  const { text, description, handleUpload, html, accept, validator, errors } = props;
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleUpload,
    maxFiles: 1,
    accept,
    validator,
  });

  /**
   * No workaround props spreading here.
   */
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <>
      <div
        {...getRootProps({ className: 'dropzone' })}
        className={`flex items-center justify-center cursor-pointer border-dashed border-2 rounded-[14px] ${
          errors.length ? 'border-redTP-200' : 'border-slate-200'
        } py-6 rounded px-8`}
        style={html}
      >
        <input {...getInputProps()} />
        <div className="flex items-center">
          <UploadRoundedIcon htmlColor={errors.length ? '#ee8481' : '#D7E2E6'} />
          <div
            className={`flex flex-col ${
              errors.length ? 'text-redTP-400' : 'text-slate-400'
            } ml-3 text-sm`}
          >
            {text && <span>{text}</span>}
            {description && (
              <span className="font-light text-xs text-opacity-80">{description}</span>
            )}
          </div>
        </div>
      </div>
      {Boolean(errors.length) && (
        <div className="flex flex-col space-y-1 text-redTP-400 my-4 font-bold text-xs">
          {errors.map(({ message, code }) => (
            <span key={code}>{message}</span>
          ))}
        </div>
      )}
    </>
  );
  /* eslint-enable react/jsx-props-no-spreading */
};

export default FileUpload;
