import React, { FC, useEffect, useMemo, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import isEqual from 'lodash-es/isEqual';

import { CustomDomainType } from 'data-layer';
import { FormikErrors, FormikValues } from 'formik';

import { IPortalSettings } from '../../Interfaces';
import { ButtonTypes } from '../../Interfaces/IButton';
import Button from '../Common/Button';
import Loader from '../Common/Loader';
import Banner from './Modules/Banner';
import ColorPalette from './Modules/ColorPalette';
import LoginPage from './Modules/LoginPage';
import Logo from './Modules/Logo';
import OrderStatus from './Modules/OrderStatus';
import PageTitleAndFavicon from './Modules/PageTitleAndFavicon/PageTitleAndFavicon';
import PortalDomainsSection from './Modules/PortalDomainsSection';
import Questions from './Modules/Questions';
import Rating from './Modules/Rating';
import SuggestedProducts from './Modules/SuggestedProducts';
import Tracking from './Modules/Tracking';

const removeValues = (data: any, values: Array<string>) => {
  const _data = { ...data };
  values.forEach((value) => {
    delete _data[value];
  });
  return _data;
};
interface ModulesContainer {
  values: IPortalSettings;
  initialValues: IPortalSettings;
  modulesRef: React.RefObject<HTMLDivElement>;
  handleScroll(): void;
  isSending: boolean;
  errors: FormikErrors<FormikValues>;
  storeBrandingId: string;
}

const ModulesContainer: FC<ModulesContainer> = (props) => {
  const { modulesRef, handleScroll, isSending, errors, storeBrandingId, values, initialValues } =
    props;
  const { t } = useTranslation('trackingPage', { keyPrefix: 'editMode' });
  const [isWithErrors, setIsWithErrors] = useState(false);
  const isMountedRef = useRef<boolean>(false);

  const FORM_CHANGED = useMemo(() => {
    const filteredValues = removeValues(values, ['trackingCustomDomain']);
    const filteredInitialValues = removeValues(initialValues, ['trackingCustomDomain']);
    return !isEqual(filteredValues, filteredInitialValues);
  }, [values, initialValues]);

  useEffect(() => {
    setIsWithErrors(Object.keys(errors).length !== 0);
  }, [errors]);

  return (
    <>
      <div
        className="flex flex-col space-y-12 p-0 md:pl-8 overflow-y-scroll divide-y divide-slate-200 pb-[100px] pr-4 w-full min-w-full max-w-full md:w-[650px] md:min-w-[200px] md:max-w-[650px]"
        ref={modulesRef}
        onScroll={() => {
          if (isMountedRef.current) {
            handleScroll();
          } else {
            isMountedRef.current = true;

            if (modulesRef.current) {
              modulesRef.current.scrollTop = 0;
            }
          }
        }}
      >
        <PortalDomainsSection
          customDomainType={CustomDomainType.Tracking}
          storeBrandingId={storeBrandingId}
        />
        <PageTitleAndFavicon />
        <Logo />
        <ColorPalette />
        <LoginPage />
        <Tracking />
        <OrderStatus />
        <SuggestedProducts />
        <Banner />
        <Questions />
        <Rating />
      </div>
      <div className="absolute flex justify-end bottom-0 right-0 left-0 bg-white pb-10 py-3 px-11">
        {isSending && <Loader className="w-40" />}
        {!isSending && (
          <Button
            isSubmit
            type={ButtonTypes.OV_PRIMARY}
            isDisabled={!FORM_CHANGED || isSending || isWithErrors}
            content={t('save')}
          />
        )}
      </div>
    </>
  );
};

export default ModulesContainer;
