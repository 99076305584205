import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { Integration } from 'app-types';

import { hourCache } from '../../../constants';
import { getAllIntegrations } from '../../../queries/index';

export function useIntegrations<T = { data: Integration[] } | undefined>(
  options?: UseQueryOptions<{ data: Integration[] } | undefined, unknown, T, [string]>,
) {
  return useQuery(['getAllIntegrations'], () => getAllIntegrations(), {
    staleTime: hourCache,
    cacheTime: hourCache,
    ...options,
  });
}
