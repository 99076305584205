import React, { FC } from 'react';

import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { useGetPaletteColors } from 'return-portal-ui';

import { DisplayStep, StepStatus } from '../../../../../Interfaces/ITrackingData';

const SubStepAlert: FC<{ step: DisplayStep }> = ({ step }) => {
  const { isSubStep, status } = step;
  const { primaryText, alertColor } = useGetPaletteColors();

  if (
    !isSubStep ||
    ![StepStatus.INCIDENT, StepStatus.BACKTRACK_INCIDENT, StepStatus.PAST_INCIDENT].includes(status)
  )
    return null;
  return (
    <WarningRoundedIcon
      style={{
        color: status === StepStatus.INCIDENT ? alertColor : primaryText,
      }}
      sx={{ fontSize: '25px' }}
    />
  );
};

export default SubStepAlert;
