export function SwapHoriz({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 96 960 960"
      fill="currentColor"
      {...props}
    >
      <path d="M658.924 620.461q-8.693-8.692-8.693-21.076 0-12.385 8.693-21.077l92.309-92.309H480q-12.769 0-21.384-8.615T450.001 456q0-12.769 8.615-21.384T480 426.001h271.233l-92.309-92.309q-8.693-8.692-8.693-21.384t8.693-21.384q8.692-8.693 21.076-8.693t21.076 8.693l139.769 139.769q5.616 5.615 7.923 11.846 2.308 6.23 2.308 13.461t-2.308 13.461q-2.307 6.231-7.923 11.846L701.076 621.076q-8.692 8.693-21.076 8.385-12.384-.308-21.076-9Zm-400 240.615L119.155 721.307q-5.616-5.615-7.923-11.846-2.308-6.23-2.308-13.461t2.308-13.461q2.307-6.231 7.923-11.846l139.769-139.769q8.692-8.693 21.076-8.693t21.076 8.693q8.693 8.692 8.693 21.384t-8.693 21.384l-92.309 92.309H480q12.769 0 21.384 8.615T509.999 696q0 12.769-8.615 21.384T480 725.999H208.767l92.309 92.309q8.693 8.692 8.693 21.077 0 12.384-8.693 21.076-8.692 8.692-21.076 9-12.384.308-21.076-8.385Z" />
    </svg>
  );
}
