export function Edit({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="currentColor"
      transform="rotate(180)"
      {...props}
    >
      <g transform="translate(24 24) rotate(180)">
        <path
          d="M349.727,323.842l-2.9-2.9m-8.33,11.227,2.451-.272a3.034,3.034,0,0,0,.59-.1,1.426,1.426,0,0,0,.351-.169,3.051,3.051,0,0,0,.443-.4l9.565-9.565a2.049,2.049,0,0,0-2.9-2.9l-9.565,9.565a3.05,3.05,0,0,0-.4.443,1.455,1.455,0,0,0-.169.351,3.109,3.109,0,0,0-.095.59Z"
          transform="translate(-333.5 -313.171)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
}
