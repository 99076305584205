import { useEffect, useMemo, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { DAY_CACHE } from 'ui/constants';

import { CustomDomain, CustomDomainStatus } from '../constants';
import { getCustomDomainsByStore } from '../queries';

export function useGetCustomDomainsByStore(storeBrandingId: string) {
  const [refetchInterval, setRefetchInterval] = useState<number | false>(false);

  const { data, refetch, isLoading } = useQuery(
    [storeBrandingId, 'getCustomDomainsByStore'],
    () => getCustomDomainsByStore(storeBrandingId),
    {
      cacheTime: DAY_CACHE,
      staleTime: DAY_CACHE,
      enabled: storeBrandingId.length > 0,
      refetchIntervalInBackground: true,
      refetchInterval,
    },
  );

  useEffect(() => {
    const isPending = data?.some(
      ({ status }: CustomDomain) => status === CustomDomainStatus.Pending,
    );
    setRefetchInterval(isPending ? 30000 : false);
  }, [data]);

  return useMemo(
    () => ({
      data,
      refetch,
      isLoading,
    }),
    [data],
  );
}
