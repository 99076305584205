import { QueryHookOptions } from '@apollo/client/react/types/types';
import { AgentStatistics, QueryDailyAgents_AgentsStatisticsArgs } from 'app-types/desk-analytics';

import { DAILY_AGENTS_AGENTS_STATISTICS } from '../../../gql/desk-analytics/queries';
import { useQueryDeskAnalytics } from '../useQueryDeskAnalytics';

export function useGetDailyAgentsStatistics(
  { from, to, limit, sortOrder, sortKey }: Partial<QueryDailyAgents_AgentsStatisticsArgs>,
  options?: QueryHookOptions,
) {
  const { data, loading, error } = useQueryDeskAnalytics<{
    DailyAgents_agentsStatistics: AgentStatistics[];
  }>(DAILY_AGENTS_AGENTS_STATISTICS, {
    skip: !from || !to,
    variables: { from, to, limit, sortOrder, sortKey },
    ...options,
  });
  return {
    data: data?.DailyAgents_agentsStatistics,
    loading,
    error,
  };
}
