import React from 'react';

import noop from 'lodash-es/noop';

import { useGetRegionalTranslationsV2 } from '../hooks';
import OVCButton from './OVCButton';
import { IOVCButton } from './OVCButton';

interface IOVCAuthTrackOrderCTA extends IOVCButton {
  useNoop?: boolean;
}

const OVCAuthTrackOrderCTA = ({ useNoop = false, ...props }: IOVCAuthTrackOrderCTA) => {
  const regionalTranslations = useGetRegionalTranslationsV2();

  return (
    <OVCButton {...props} onClick={useNoop ? noop : props.onClick}>
      {regionalTranslations?.loginPageInfo?.buttonTrackOrder}
    </OVCButton>
  );
};

export default OVCAuthTrackOrderCTA;
